import React from "react";

import { useGetDisplayDashboard } from "../../hooks";
import { HubTableTemplate } from "../../components";
import { condition } from "./helper";
import DisplayTableContainer from "./DisplayTableContainer";
import { StyledTwoStack } from "./styles";

const sortable = true;
const items = [
  {
    id: "startDate",
    label: "Start Date",
    sortable,
  },
  {
    id: "part",
    label: "Part",
  },
  {
    id: "workOrder",
    label: "WO #",
    sortable,
  },
  {
    id: "lotNumber",
    label: "Lot",
  },
  {
    id: "available",
    label: "Available",
  },
  {
    id: "remaining",
    label: "Remaining",
  },
  {
    id: "startTime",
    label: "Start Time",
  },
  {
    id: "dip",
    label: "Dip",
  },
];

const keys = [
  {
    value: "startDate",
    isDate: true,
    sx: {
      width: "100px"
    }
  },
  {
    variant: "blueBold",
    value: "partName",
    navigate: true,
    route: "parts",
    id: "partId",
  },
  {
    value: "workOrder",
    variant: "blueBold",
  },
  {
    value: "lotNumber",
  },
  {
    value: "available",
    defaultValue: 0,
  },
  {
    value: "remaining",
    defaultValue: 0,
  },
  {
    value: "actualStartDate",
    isDateTime: true,
  },
  {
    value: "departmentOrder",
    defaultValue: 1,
  },
];

const DipTable = ({ setWorkOrder = () => {}, defaultLocation }) => {
  const { data } = useGetDisplayDashboard({ labor: false, defaultLocation });

  const onClick = (item) => {
    setWorkOrder(item?.workOrder);
  };

  const modifiedKeys = keys.map((key) =>
    key.value === "workOrder" ? { ...key, onClick } : key
  );
  const headerProps = { items, includeCheckbox: false, includeEnd: false };

  return (
    <StyledTwoStack>
      {data?.rows?.map((station) => {
        const props = {
          headerProps,
          bodyProps: { keys: modifiedKeys, condition, items: station?.rows },
        };
        return (
          <DisplayTableContainer title={station?.title} count={station?.count}>
            <HubTableTemplate id="DisplayTable" {...props} />
          </DisplayTableContainer>
        );
      })}
    </StyledTwoStack>
  );
};

export default DipTable;

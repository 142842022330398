export const INDUSTRIES = [
  "Construction",
  "Mass Transit",
  "Firearms",
  "Pumps/Valves",
  "Energy",
  "Automotive",
  "Oil",
  "Food Processing",
  "Cutlery",
  "Entertainment",
  "Aerospace",
  "Pool Covers",
  "Dental",
  "Military",
  "Mining",
  "Equipment",
  "Structural",
  "Medical",
  "Marine",
  "Recreation",
];

export const FOUNDRIES = [
  { id: 1, label: "IPC - Utah" },
  { id: 2, label: "IPC - Marble Fall" },
];

export const FOUNDRY_NAMES = FOUNDRIES.flatMap((f) => f.label);

import { styled } from "@mui/material/styles";
import { Stack } from "@mui/material";

export const StyledFinishStack = styled(Stack)({
  gridColumn: "span 2",
  display: "grid",
  gridTemplateRows: "repeat(5, 18px)",
  gridAutoFlow: "column",
  gridAutoColumns: "auto",
  rowGap: "2px",
  columnGap: "10px"
});

export const StyledBaseBox = styled(Stack, {
  shouldForwardProp: (prop) =>
    !["columns", "disableBorder", "rows"].includes(prop),
})(({ columns = 3, disableBorder = false, rows }) => ({
  display: "grid",
  gridTemplateColumns: `repeat(${columns}, 1fr)`,
  columnGap: "10px",
  rowGap: "3px",
  padding: "10px",
  ...(!disableBorder ? { borderBottom: "1px dashed" } : {}),
  ...(rows ? { gridTemplateRows: `repeat(${rows}), 1fr` } : {}),
  // alignItems: "end",
}));

export const StyledWaxBox = styled(Stack, {
  shouldForwardProp: (prop) =>
    !["columns", "disableBorder", "disablePadding"].includes(prop),
})(({ columns = 3, disableBorder = false, disablePadding }) => ({
  display: "grid",
  gridTemplateColumns: `repeat(${columns}, 1fr)`,
  gap: "25px",
  ...(!disableBorder ? { borderBottom: "1px dashed" } : {}),
  ...(!disablePadding ? { padding: "10px" } : {}),
}));

export const StyledMeltStack = styled(Stack)({
  padding: "10px",
  borderBottom: "1px dashed",
  gap: "15px",
});

export const StyledMeltBox = styled(Stack)({
  display: "grid",
  gridTemplateRows: "repeat(3, 1fr)",
  gridTemplateColumns: "repeat(4, 1fr)",
  gap: "15px",
  rowGap: "5px",
});

export const StyledWaxStack = styled(Stack)({
  gap: "10px",
});

export const StyledLabelStack = styled(Stack, {
  shouldForwardProp: (prop) => !["c1"].includes(prop),
})(({ c1 = "100px" }) => ({
  display: "grid",
  gridTemplateColumns: `${c1} 1fr`,
  gap: "5px",
}));

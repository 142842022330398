import React from "react";

import { useGetOrderLineItems } from "../../hooks";

import { StyledHubTableTemplate } from "./styles";
import LineItemsTable from "./CustomerOrderLineWorksTable";

const items = [
  {
    id: "line",
    label: "Line",
  },
  {
    id: "part",
    label: "Part",
  },
  {
    id: "quantity",
    label: "Quantity",
  },
  {
    id: "price",
    label: "Price",
  },
  {
    id: "lineTotal",
    label: "Line Total",
  },
  {
    id: "margin",
    label: "Margin",
  },
  {
    id: "dueDate",
    label: "Due Date",
  },
];

const keys = [
  {
    value: "line",
  },
  {
    variant: "blueBold",
    value: "partName",
    navigate: true,
    route: "parts",
    id: "partId",
  },
  {
    value: "quantity",
  },
  {
    value: "price",
    defaultValue: 0,
    isCurrency: true,
  },
  {
    value: "total",
    defaultValue: 0,
    isCurrency: true,
  },
  {
    value: "margin",
  },
  {
    value: "dueDate",
    isDate: true,
  },
];

export const CustomerOrderLineItemsTable = ({ id }) => {
  const { data } = useGetOrderLineItems(id);

  const headerProps = { items, includeCheckbox: true };
  const bodyProps = {
    items: data,
    keys,
    RenderElement: LineItemsTable,
    includeChevron: true,
  };
  const props = { headerProps, bodyProps };

  return <StyledHubTableTemplate id="LineItemsTable" {...props} />;
};

export default CustomerOrderLineItemsTable;

import React, { useEffect } from "react";

import { usePostOrderData } from "../../hooks";
import {
  HubDialogContent,
  HubHookFormWrapper,
  HubToolbar,
} from "../../components";
import { useDialogContext } from "../HubDialogWrapper";

import { formOrderMapper } from "./helper";
import PoDetails from "./PoDetails";
import { useNavigate } from "react-router-dom";

const CreatePurchaseOrderContent = ({
  customer,
  _flushPurchaseOrder = () => {},
}) => {
  const navigate = useNavigate();
  const {
    mutate: postOrderData,
    isLoading,
    isSuccess,
    data,
  } = usePostOrderData();
  const { onClose = () => {} } = useDialogContext();

  const onSubmit = (fd) => {
    const payload = formOrderMapper(fd, customer);
    postOrderData(payload);
  };

  const handleClose = () => {
    onClose();
    _flushPurchaseOrder();
  };

  useEffect(() => {
    if (isSuccess && data?.id) {
      navigate(`/orders/${data?.id}`);
    }
  }, [isSuccess, data]);

  return (
    <>
      <HubToolbar
        label="Create Purchase Order"
        variant="transparent"
        onClose={handleClose}
        buttonProps={{ icon: "X" }}
        sx={{
          padding: "20px !important",
          margin: 0,
        }}
      />
      <HubDialogContent largePadding>
        <HubHookFormWrapper onSubmit={onSubmit} sx={{ height: "100%" }}>
          <PoDetails isLoading={isLoading} />
        </HubHookFormWrapper>
      </HubDialogContent>
    </>
  );
};

export default CreatePurchaseOrderContent;

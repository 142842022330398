import React from "react";
import { Stack, Typography } from "@mui/material";

import { HubIcon } from "../../components";
import WorkOrderLabel from "./WorkOrderLabel";
import { StyledBaseBox, StyledFinishStack } from "./styles";
import { Checkbox } from "../../svgs";

const WorkOrderFinish = ({ data }) => {
  let post = data?.part?.postFoundryInstructions;
  let routes = post?.postFoundryRouting?.map((p) => ({
    isOutside: p?.isOutside,
    notes: p?.notes,
    process: p?.process?.name,
    partner: p?.partner?.name,
  }));

  return (
    <StyledBaseBox columns={2} disableBorder={true}>
      <StyledFinishStack>
        <Typography variant="boldCaptionMain">Finish Department</Typography>
        {routes?.map((r, i) => (
          <Stack direction="row" gap="5px" alignItems="center">
            <Checkbox color="#C0C0C0" size={14} />
            <WorkOrderLabel
              label={`${i + 1}. ${r?.process}`}
              value={r?.notes}
              c1="auto"
              valueBold
              {...(r.isOutside
                ? { backgroundColor: "hubColors.mainFocusLighter" }
                : {})}
            />
          </Stack>
        ))}
      </StyledFinishStack>
    </StyledBaseBox>
  );
};

export default WorkOrderFinish;

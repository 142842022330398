export const tabs = [
  {
    id: 1,
    label: "Settings",
    icon: "Settings",
    // TabElement: WorkOrderProd,
  },
  {
    id: 2,
    label: "Users",
    icon: "User",
    // TabElement: WorkOrderProd,
  },
  {
    id: 3,
    label: "Stations",
    icon: "Archive",
    // TabElement: WorkOrder,
  },
];

import React, { useEffect, useState } from "react";
import { Stack, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom"; // Add useNavigate
import {
  HubCardStack,
  HubHookFormInput,
  HubHookFormSelect,
  HubHookFormWrapper,
  HubIcon,
  PageContent,
} from "../../components";
import { DEPARTMENTS } from "../../constants";

import Empty from "./Empty";

import { useSearchWorkOrders } from "../../hooks/react-query/useWorkOrderData";
import { LaborWorkOrdersTable } from "../../tables";
import { setIsOpenDialog } from "../../redux";
import { connect } from "react-redux";
import { CreateLaborDialog } from "../../dialogs";

const mapStateToProps = () => ({});
const mapDispatchToProps = {
  _setIsOpenDialog: setIsOpenDialog,
};

export const LaborPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(({ _setIsOpenDialog = () => {} }) => {
  const [workOrder, setWorkOrder] = useState();

  const location = useLocation();
  const navigate = useNavigate();

  const query = new URLSearchParams(location.search);
  const work = query.get("work");
  const dep = query.get("department");

  const { data } = useSearchWorkOrders();
  const hasResults = data?.meta?.count;

  const onChange = (value, name) => {
    const searchParams = new URLSearchParams(location.search);
    const pathname = location.pathname;

    value ? searchParams.set(name, value) : searchParams.delete(name);

    const params = searchParams.toString();
    navigate(`${pathname}?${params}`, { replace: true });
  };

  const handleWorkChange = (e) => {
    const value = e.target.value;
    const newValue = `W${value}`;
    const sendValue = value ? newValue : value;
    onChange(sendValue, "work");
  };

  const handleSetOpen = () => {
    _setIsOpenDialog("create-labor-ticket", true);
  };

  useEffect(() => {
    workOrder && handleSetOpen(true);
  }, [workOrder]);

  const tableProps = { data, setWorkOrder };

  return (
    <PageContent>
      <HubCardStack
        maxWidth="750px"
        width="100%"
        mx="auto"
        gap="25px"
        overflow="hidden"
      >
        <Stack gap="5px">
          <Typography variant="boldCaption">Labor Ticket</Typography>
          <Typography variant="caption">
            Find work order by searching with department or work order to start
            a labor ticket
          </Typography>
        </Stack>
        <HubHookFormWrapper>
          <Stack direction="row" gap="15px">
            <HubHookFormInput
              name="work"
              label="Work Order #"
              defaultValue={work}
              startAdornment="W-"
              endAdornment={<HubIcon icon="Search" />}
              required
              isNumerical
              onBlur={(e) => handleWorkChange(e)}
            />
            <HubHookFormSelect
              name="department"
              label="Department"
              defaultValue={dep}
              options={DEPARTMENTS}
              allowNull
              onChange={(e) => onChange(e.target.value, "department")}
            />
          </Stack>
        </HubHookFormWrapper>
        {hasResults ? <LaborWorkOrdersTable {...tableProps} /> : <Empty />}
      </HubCardStack>
      <CreateLaborDialog workOrder={workOrder} />
    </PageContent>
  );
});

export default LaborPage;

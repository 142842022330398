import React from "react";
import { Stack } from "@mui/material";
import HubChartLabel from "./HubChartLabel";

export const HubChartLabelGroup = ({
  labels = [],
  stackProps = {},
  ...rest
}) => {
  const sorted = labels?.sort((a, b) => b?.count - a?.count);

  return (
    <Stack gap="5px" {...stackProps}>
      {sorted?.map(({ color, label }) => (
        <HubChartLabel color={color} label={label} {...rest} />
      ))}
    </Stack>
  );
};

export default HubChartLabelGroup;

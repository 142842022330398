import React from "react";
import { Chip } from "@mui/material";

import { WORK_STATUS } from "../../constants";

const options = ["grey", "blue", "yellow", "red"];

const WorkOrderStatusChip = ({ status }) => {
  const label = WORK_STATUS.find(({ id }) => id === status)?.label;
  const flow = options[status] || "red";
  const props = { label, flow };

  return (
    <Chip
      variant="colorTag"
      sx={{
        "& .MuiChip-label": {
          fontSize: 10,
        },
      }}
      {...props}
    />
  );
};

export default WorkOrderStatusChip;

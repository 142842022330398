import React, { useState } from "react";
import { Stack, Typography } from "@mui/material";
import dayjs from "dayjs";

import { StyledHubHookFormDatePicker, StyledHubHookFormSelect } from "./styles";
import { HubHookFormSwitch } from "../../components";

const FOLLOW_UP_OPTIONS = [
  { id: 1, label: "Daily" },
  { id: 2, label: "Weekly" },
  { id: 3, label: "Monthly" },
  { id: 4, label: "Custom" },
];

const AddWorkOrderRepeat = ({ disabled }) => {
  const defaultDate = dayjs().format("MM/DD/YYYY");

  const [followUp, setFollowUp] = useState(4);
  const [date, setDate] = useState(defaultDate);

  const handleFollowUpChange = (e) => {
    const selected = e?.target?.value;
    const option = FOLLOW_UP_OPTIONS.find((f) => f.id === selected);

    setFollowUp(option?.id || 2);
  };

  const handleDateChage = (value) => setDate(value);

  return (
    <Stack direction="row" gap="10px" alignItems="center">
      <HubHookFormSwitch
        name="isRepeat"
        label="Repeat Batches"
        labelPlacement="end"
        defaultValue={true}
        disableHelperText
        fullWidth={false}
        isCheckbox
        disabled
        formControlProps={{
          sx: { minWidth: "auto" },
        }}
        labelProps={{
          sx: { textWrap: "nowrap" },
        }}
      />
      <StyledHubHookFormSelect
        name="repeatOption"
        defaultValue={followUp}
        options={FOLLOW_UP_OPTIONS}
        onChange={handleFollowUpChange}
        fullWidth={false}
        disabled={disabled}
      />
      <Stack direction="row" gap="10px" alignItems="center">
        <Typography variant="inputLabelSmall" sx={{ mb: 0 }}>
          start
        </Typography>
        <StyledHubHookFormDatePicker
          name="repeatStartDate"
          placeholder="MM/DD/YYYY"
          defaultValue={date}
          disabled={disabled}
          onChange={handleDateChage}
        />
      </Stack>
    </Stack>
  );
};

export default AddWorkOrderRepeat;

import React, { useEffect } from "react";
import { Stack } from "@mui/material";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";

import { setParts } from "../../redux/partsTable";
import { useGetAllParts } from "../../hooks";
import { HubCardStack, PageContent } from "../../components";
import { PartsTable } from "../../tables";

import PartsBar from "./PartsBar";
import { CreateCustomerPartDialog } from "../../dialogs";

const Parts = ({ _setParts = () => {} }) => {
  const { search } = useLocation();
  const { data, isFetching, isSuccess } = useGetAllParts();

  useEffect(() => {
    isSuccess && _setParts(data);
  }, [data, isSuccess, search?.toString()]);

  return (
    <PageContent gap="15px" isLoading={isFetching} white smallPadding>
      <Stack gap="15px" height="100%">
        <PartsBar />
        <PartsTable data={data} />
      </Stack>
      <CreateCustomerPartDialog requestCustomer />
    </PageContent>
  );
};

const mapStateToProps = () => ({});
const mapDispatchToProps = {
  _setParts: setParts,
};

export const PartsPage = connect(mapStateToProps, mapDispatchToProps)(Parts);

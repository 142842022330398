import { styled } from "@mui/material/styles";
import { Stack } from "@mui/material";
import { HubHookFormInput } from "../../components";

export const StyledGridStack = styled(Stack)({
  display: "grid",
  gridTemplateColumns: "repeat(4, 1fr)",
  gap: "15px",
});

export const StyledHubHookFormInput = styled(HubHookFormInput)({
  flexDirection: "row",
  "&.MuiFormControl-root": {
    flexDirection: "row",
  },
});

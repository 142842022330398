import React from "react";
import { Stack, Typography } from "@mui/material";
import { connect } from "react-redux";

import { getPartRoutingCreatedAt } from "../../redux/routing";
import { formatDateMmDdYyyy } from "../../helpers/dateHelper";

import { tabs } from "./helper";
import { SideNavBar } from "../../components";

export const PartDetailsMenu = ({
  value = "ROUTING",
  setValue = () => {},
  createdAt = "",
  lastUpdated = "",
}) => {
  const EndComponent = (
    <Stack gap="0px" p="0 15px">
      <Typography
        variant="caption"
        sx={{
          fontSize: 11,
          color: "hubColors.grey",
        }}
      >
        Updated: {formatDateMmDdYyyy(createdAt)}
      </Typography>
      <Typography
        variant="caption"
        sx={{
          fontSize: 11,
          color: "hubColors.grey",
        }}
      >
        Created: {formatDateMmDdYyyy(createdAt)}
      </Typography>
    </Stack>
  );
  const props = { tabs, EndComponent, value, setValue };
  return <SideNavBar {...props} />;
};

const mapStateToProps = (state) => ({
  createdAt: getPartRoutingCreatedAt(state),
});

export default connect(mapStateToProps)(PartDetailsMenu);

import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";

import { HubHookFormInput } from "../../components";

const CreateLaborContentEmployeeId = () => {
  const { setValue, watch } = useFormContext();
  const [scanned, setScanned] = useState(false);

  const employeeId = watch("employeeId");

  useEffect(() => {
    if (employeeId && scanned) {
      const submitButton = document.getElementById("ModalDisplaySubmit");
      if (submitButton) {
        submitButton.click();
      }
    }
  }, [employeeId, scanned]);

  useEffect(() => {
    let barcode = "";

    const handleKeyDown = (event) => {
      // Handle scan only if there is no work order
      if (["Enter", "Tab"].includes(event.key)) {
        setValue("employeeId", barcode);
        setScanned(true);
        barcode = "";
      } else if (/^[a-zA-Z0-9]$/.test(event.key)) {
        // Only add to barcode if the key is alphanumeric
        barcode += event.key;
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <HubHookFormInput
      name="employeeId"
      label="Employee Id"
      required
      onChange={() => setScanned(false)}
    />
  );
};

export default CreateLaborContentEmployeeId;

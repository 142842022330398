import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import { EmptyOrders } from "../../svgs";
import { DialogButton } from "../../components";

const CompaniesEmpty = () => {
  return (
    <Stack m="auto" gap="15px" alignItems="center" textAlign="center" p="50px">
      <Box component="img" src={EmptyOrders} width="150px" height="150px" />
      <Stack gap="5px">
        <Typography variant="boldCaption">No Customers Found</Typography>
        <Typography variant="caption" sx={{ lineHeight: 1.25 }}>
          Your filters do not show any customers, or there are none <br />
          listed for your organization. Please filter for something else or
          create a new customer
        </Typography>
      </Stack>
      <DialogButton
        name="create-company"
        label="Create Customer"
        size="xsmall"
        variant="contained"
      />
    </Stack>
  );
};

export default CompaniesEmpty;

import React from "react";

import { HubContextMenu } from "../../components";
import { useHubSnackBar } from "../../hooks";

const MENU_ITEMS = [
  {
    id: "delete",
    label: "Delete",
    icon: "Trash2",
    color: "redError",
  },
];

export const LineItemContextMenu = ({
  id,
  _removeLineItem = () => {},
  removeItem,
  disabled,
  ...rest
}) => {
  const { addSnackbarError } = useHubSnackBar();

  const onSelect = (item) => {
    switch (item?.id) {
      case "delete":
        if (disabled) {
          addSnackbarError({
            message: "This work order cannot be deleted as it's in production",
          });
        } else if (removeItem) {
          removeItem(id);
        } else {
          _removeLineItem(id);
        }
        break;
      default:
        break;
    }
  };

  return (
    <HubContextMenu
      id={"contacts"}
      loading={false}
      menuItems={MENU_ITEMS}
      onSelect={onSelect}
      IconButtonProps={{ sx: { padding: "5px" } }}
      HubIconProps={{ size: 14 }}
      {...rest}
    />
  );
};

export default LineItemContextMenu;

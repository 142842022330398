import React from "react";
import { Chip, Stack, Typography } from "@mui/material";

import { HubBackButton, HubButton } from "../../components";

import { StyledTitleStack } from "../PartDetails/styles";
import { JOB_STATUSES, WORK_STATUS } from "../../constants";
import { StyledSelect } from "./styles";
import { useFormContext } from "react-hook-form";
import { handleDownloadPdf } from "../../helpers";

export const StatusChip = ({ status }) => {
  const chipProps = { variant: "colorTag", sx: { fontSize: 10 } };

  let props = { label: "Not Started", flow: "grey", ...chipProps };
  switch (status) {
    case JOB_STATUSES.IN_PROGRESS:
      props = { label: "In Progress", flow: "green", ...chipProps };
      break;
    case JOB_STATUSES.COMPLETE:
      props = { label: "Completed", flow: "blue", ...chipProps };
    default:
      break;
  }
  return <Chip {...props} />;
};

const WorkOrderBar = ({
  workOrder,
  status,
  state = 1,
  onCloseWorkOrder = () => {},
}) => {
  const { formState } = useFormContext();
  const isDirty = formState?.isDirty;

  const handleDownload = async () => {
    await handleDownloadPdf({ id: "traverler-sheet", name: workOrder });
  };

  return (
    <StyledTitleStack>
      <Stack direction="row" gap="10px" alignItems="center">
        <HubBackButton />
        <Typography variant="caption">{workOrder}</Typography>
        <StatusChip status={status} />
      </Stack>
      <Stack direction="row" gap="10px" alignItems="center">
        <StyledSelect
          name="status"
          options={WORK_STATUS}
          defaultValue={state}
          fullWidth={false}
          required
        />
        <HubButton
          variant="outlined"
          size="xsmall"
          color="neutral"
          label="Close Work Order"
          onClick={onCloseWorkOrder}
        />
        <HubButton
          variant="contained"
          size="xsmall"
          label="Save Changes"
          type="submit"
          disabled={!isDirty}
        />
        <HubButton
          variant="contained"
          size="xsmall"
          color="neutral"
          icon="Download"
          label="Download"
          iconProps={{
            size: 14,
          }}
          onClick={handleDownload}
        />
      </Stack>
    </StyledTitleStack>
  );
};

export default WorkOrderBar;

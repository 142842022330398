import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";

import {
  useGetOrderEditData,
  usePutOrderData,
  useGetWorkOrderByOrderId,
} from "../../hooks";
import { HubHookFormWrapper, PageContent } from "../../components";
import { formOrderMapper, newItemsMapper, updatedMapper, updateWorkOrderMapper } from "./helper";

import OrderDetails from "./OrderDetails";
import OrderList from "./OrderList";
import { StyledPageStack } from "./styles";
import OrdersBar from "./OrdersBar";
import { setIsOpenDialog } from "../../redux";
import { AddWorkOrderDialog } from "../../dialogs";

const OrderPage = ({ _setIsOpenDialog = () => {} }) => {
  const { id } = useParams();
  const { data, isLoading, refetch } = useGetOrderEditData(id);

  const [line, setLine] = useState();

  const {
    mutate: putOrderData,
    isLoading: isUpdateLoading,
    isSuccess,
  } = usePutOrderData();

  useEffect(() => {
    refetch();
  }, [id]);

  useEffect(() => {
    isSuccess && window.location.reload();
  }, [isSuccess]);

  useEffect(() => {
    line && _setIsOpenDialog("add-work-order", true);
  }, [line]);

  const onSubmit = (formData) => {
    const orderId = parseInt(id);
    const payload = {
      id: orderId,
      customerId: data?.customer?.id,
      poNumber: formData.poNumber,
      overShipping: formData.overShipping,
      shipVia: formData.shipVia,
      shipTo: formData.shipTo,
      freight: formData.freight,
      location: formData.location,

      deletedLineItems: formData?.deletedLineItems || [],
      newLineItems: newItemsMapper(formData?.newLineItems || []),
      updatedLineItems: updatedMapper(formData?.updatedLineItems || []),

      deletedWorkOrders: formData.deletedWorkOrders || [],
      updatedWorkOrders: updateWorkOrderMapper(formData.updatedWorkOrders || []) 
    };
    // console.log(payload);
    putOrderData(payload);
  };

  return (
    <PageContent isLoading={isLoading} disablePadding hideOverflow>
      <OrdersBar />
      <HubHookFormWrapper onSubmit={onSubmit} sx={{ overflow: "hidden" }}>
        <StyledPageStack>
          <OrderList setLine={setLine} />
          <OrderDetails
            isLoading={isLoading}
            data={data}
            isUpdateLoading={isUpdateLoading}
          />
        </StyledPageStack>
      </HubHookFormWrapper>
      <AddWorkOrderDialog lineId={line} onClose={() => setLine(null)} />
    </PageContent>
  );
};

const mapStateToProps = () => ({});
const mapDispatchToProps = {
  _setIsOpenDialog: setIsOpenDialog,
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderPage);

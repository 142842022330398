import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

export const padTwo = (num) => {
  return num.toString().padStart(2, "0");
};

export const formatDateMmDdYyyy = (date) => {
  if (!date) {
    return "";
  }
  const d = new Date(date);

  return [padTwo(d.getMonth() + 1), padTwo(d.getDate()), d.getFullYear()].join(
    "/"
  );
};

// const convertToLocalDateTime = (utcDateTime) => {
//   const utcDate = new Date(utcDateTime + "Z");
//   const localDateTime = new Intl.DateTimeFormat("en-US", {
//     year: "numeric",
//     month: "2-digit",
//     day: "2-digit",
//     hour: "2-digit",
//     minute: "2-digit",
//     second: "2-digit",
//     fractionalSecondDigits: 3,
//     hour12: false,
//   }).format(utcDate);

//   return localDateTime;
// };

export const getDateData = ({
  startDate = null,
  inDays = 0,
  rawData = false,
  includeMonth = true,
  includeDate = true,
  includeWeekDay = false,
  includeYear = false,
  includeTime = false,
}) => {
  const dateData = dayjs(startDate).add(inDays, "days");
  const dateString = dateData.format("ddd MMM D YYYY").split(" ");
  const timeString = dateData.format("h:mm A");

  const week = `${dateString[0]} `;
  const month = `${dateString[1]} `;
  const date = `${dateString[2]} `;
  const year = `${dateString[3]}`;
  const time = ` ${timeString}`;

  const formatedDate = `${includeWeekDay ? week : ""} ${
    includeMonth ? month : ""
  } ${includeDate ? date : ""} ${includeYear ? year : ""} ${
    includeTime ? time : ""
  }`;

  return rawData ? dateData : formatedDate;
};

export const formatAMPM = (date) => {
  // Extract the hours and minutes from the date
  let hours = date.getHours();
  let minutes = date.getMinutes();

  // Determine the period (AM/PM) based on the hours
  const ampm = hours >= 12 ? "PM" : "AM";

  // Convert hours from 24-hour format to 12-hour format by finding the remainder when divided by 12
  hours = hours % 12;

  // Convert hour '0' to '12'
  hours = hours ? hours : 12;

  // Pads the minutes with a leading zero if necessary (i.e., 5 becomes 05)
  minutes = minutes < 10 ? "0" + minutes : minutes;

  // Construct and return the formatted time string
  return hours + ":" + minutes + " " + ampm;
};

export const dateTimePattern =
  /^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/(19|20)\d\d ([01]?[0-9]|2[0-3]):[0-5][0-9] (AM|PM|am|pm)$/;

import React from "react";
import { Stack } from "@mui/material";

import { DialogButton, HubHookFormWrapper } from "../../components";
import ContactsLocationFilter from "./ContactsLocationFilter";
import ContactsActiveFilter from "./ContactsActiveFilter";

export const CompaniesBar = ({ disabled = false }) => {
  return (
    <HubHookFormWrapper onSubmit={() => {}} sx={{ width: "100%" }}>
      <Stack justifyContent="space-between" direction="row" width="100%">
        <Stack direction="row" gap="10px">
          <ContactsLocationFilter />
          <ContactsActiveFilter />
        </Stack>
        <Stack direction="row" gap="10px">
          <DialogButton
            name="create-company"
            label="Create Customer"
            variant="contained"
            size="xsmall"
            disabled={disabled}
          />
        </Stack>
      </Stack>
    </HubHookFormWrapper>
  );
};

export default CompaniesBar;

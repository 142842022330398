import React from "react";
import { connect } from "react-redux";

import { getUserLocation } from "../../redux/auth";
import { HubFilterMenu } from "../../components";

const locations = [
  {
    id: "1",
    label: "IPC - Utah",
  },
  {
    id: "2",
    label: "IPC - Marbel Falls",
  },
];

const ContactsLocationFilter = ({ userLocation, ...rest }) => {
  return (
    <HubFilterMenu
      id="location"
      options={locations}
      menuLabel="Filter Contacts By Their Location"
      label="Location"
      defaultValue={[`${userLocation}`]}
      {...rest}
      // allowAll={false}
    />
  );
};

const mapStateToProps = (state) => ({
  userLocation: getUserLocation(state),
});

export default connect(mapStateToProps)(ContactsLocationFilter);

export const JOB_STATUSES = {
  NOT_STARTED: "Not Started",
  IN_PROGRESS: "In Progress",
  COMPLETE: "Completed",
};

export const WORK_STATUS = ["Verified", "Unverified", "Blocked", "Cancelled"]?.map(
  (label, i) => ({
    id: i + 1,
    label,
  })
);

import React from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";

export const PageTabTitle = ({
  title = "Foundry Group",
  useTitleId = false,
}) => {
  const params = useParams();

  return (
    <Helmet>
      <title>{useTitleId && params?.id ? params.id : title}</title>
    </Helmet>
  );
};

export default PageTabTitle;

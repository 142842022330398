import React from "react";

import { HubDialogWrapper } from "../HubDialogWrapper";
import AddWorkOrderContent from "./AddWorkOrderContent";

export const AddWorkOrderDialog = ({ lineId, onClose = () => {} }) => {
  return (
    <HubDialogWrapper
      maxWidth="xs"
      name="add-work-order"
      customOnClose={onClose}
    >
      <AddWorkOrderContent lineId={lineId} />
    </HubDialogWrapper>
  );
};

export default AddWorkOrderDialog;

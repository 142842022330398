import React from "react";
import { useFormContext, useController } from "react-hook-form";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import FormGroup from "@mui/material/FormGroup";
import PropTypes from "prop-types";

import { StyledFormControl, StyledFormButton } from "./styles";
import { InputLabel } from "../InputLabel";

export const HubHookFormButtonGroup = ({
  name,
  options,
  rules = {},
  defaultValue = [],
  required = false,
  helperText = "",
  label = "",
  smallLabel = true,
  multiselect = true,
  isRadio = false,
  ...rest
}) => {
  const id = `hub-hook-input-${name}`;

  const { control, register } = useFormContext();

  const {
    field: { value, name: inputName, ref, onChange: onControllerChange },
    fieldState: { error },
  } = useController({
    name,
    control,
    rules: { required, ...rules },
    defaultValue,
  });

  const handleChange = (newValue) => {
    let newSelected;
    const isChecked = value.includes(newValue);

    if (multiselect) {
      if (!isChecked) newSelected = [...value, newValue];
      else newSelected = value?.filter((item) => item !== newValue);
    } else newSelected = isChecked ? [] : [newValue];

    onControllerChange(newSelected);
  };

  return (
    <StyledFormControl required={required} component="fieldset" {...rest}>
      <InputLabel
        label={label}
        required={required}
        id={id}
        smallLabel={smallLabel}
      />
      <FormGroup
        id={id}
        ref={ref}
        name={inputName}
        sx={{ display: "flex", flexDirection: "row", gap: "0px" }}
      >
        {options.map((option, i) => (
          <FormControlLabel
            key={`${option}-${i}`}
            control={
              <CustomRadio
                checked={value?.includes(option.id)}
                onSelect={handleChange}
                option={option}
              />
            }
            label=""
          />
        ))}
      </FormGroup>
      {(helperText || error) && (
        <FormHelperText error={!!error}>
          {error ? error.message : helperText}
        </FormHelperText>
      )}
      <input
        {...register(name)}
        type="input"
        style={{ display: "none" }}
        {...(defaultValue ? { defaultValue } : {})}
      />
    </StyledFormControl>
  );
};

const CustomRadio = ({
  option,
  disabled,
  shape = "round",
  checked,
  onSelect = () => {},
}) => {
  const props = {
    label: option.label,
    variant: "contained",
    size: "small",
    shape,
    disabled,
    selected: checked,
    ...(checked ? {} : { color: "grey" }),
    onClick: () => onSelect(option.id),
  };

  return <StyledFormButton {...props} />;
};

HubHookFormButtonGroup.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
      }),
      PropTypes.string,
    ])
  ).isRequired,
  rules: PropTypes.object,
  defaultValue: PropTypes.string,
  required: PropTypes.bool,
  helperText: PropTypes.string,
};

export default HubHookFormButtonGroup;

import React from "react";
import { Box, Stack, Typography } from "@mui/material";

import { EmptySearch } from "../../../svgs";

const SearchResultsEmpty = () => {
  return (
    <Stack m="auto" gap="15px" alignItems="center" textAlign="center" p="50px">
      <Box component="img" src={EmptySearch} width="150px" height="150px" />
      <Stack gap="5px">
        <Typography variant="boldCaption">No Results Found</Typography>
        <Typography variant="caption" sx={{ lineHeight: 1.25 }}>
          Your search query returned no results, <br />
          please search for something else
        </Typography>
      </Stack>
    </Stack>
  );
};

export default SearchResultsEmpty;

import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Navigate } from "react-router-dom";

import { getUserMenuItems, ROLES } from "../../constants";
import { useGetAccountDetails } from "../../hooks";
import { setUser } from "../../redux/auth";
import { useGetMaterialData } from "../../hooks/react-query/useMaterialData";
import { setMaterials } from "../../redux/part";
import { HubCircularProgress } from "../HubCircularProgress";

const AuthenticatedUserComponent = ({
  location,
  children,
  _setUser = () => {},
  _setMaterials = () => {},
}) => {
  const { data, isSuccess, isError } = useGetAccountDetails();
  const { data: mData } = useGetMaterialData();

  useEffect(() => {
    data?.id && _setUser(data);
  }, [data]);

  useEffect(() => {
    mData?.materials?.length && _setMaterials(mData);
  }, [mData]);

  const state = { from: location };
  const NavOut = <Navigate to={{ pathname: "/signin", state }} />;

  if (isSuccess) {
    const origin = `/${location?.pathname?.split("/")[1]}`;
    const items = getUserMenuItems(data.role) || [];
    const allowedPaths = items?.map((i) => i?.path);

    if (!allowedPaths?.length) return NavOut;

    const NavNext = <Navigate to={{ pathname: allowedPaths[0], state }} />;
    return allowedPaths.includes(origin) ? children : NavNext;
  }
  if (isError) return NavOut;

  return <HubCircularProgress centerScreen />;
};

const mapStateToProps = () => ({});
const mapDispatchToProps = {
  _setUser: setUser,
  _setMaterials: setMaterials,
};

export const AuthenticatedUser = connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(AuthenticatedUserComponent));

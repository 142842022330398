import React from "react";
import { Stack, Switch, Typography } from "@mui/material";

import { HubBackButton, HubHookFormWrapper } from "../../components";
import { FOUNDRIES } from "../../constants";

import { StyledSelect, StyledTitleStack } from "./styles";
import { useLocation, useNavigate } from "react-router-dom";

export const DisplayBar = ({
  setNav = () => {},
  nav = false,
  defaultLocation = 1,
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const query = new URLSearchParams(location.search);
  const property = query.get("location") || defaultLocation;

  const setValue = (e) => {
    const value = e?.target?.value;
    if (value) {
      query.set("location", value);

      navigate({
        pathname: location.pathname,
        search: query.toString(),
      });
    }
  };

  const handleSwitch = () => setNav(!nav);

  return (
    <StyledTitleStack>
      <Stack direction="row" gap="10px" alignItems="center">
        <HubBackButton />
        <Stack direction="row" gap="10px" alignItems="center">
          <HubHookFormWrapper>
            <StyledSelect
              name="foundry"
              required
              options={FOUNDRIES}
              defaultValue={property}
              onChange={setValue}
            />
          </HubHookFormWrapper>
        </Stack>
      </Stack>

      <Stack direction="row" gap="10px" p="0 15px">
        <Typography variant="caption">Worker View:</Typography>
        <Switch checked={!nav} onChange={handleSwitch} />
      </Stack>
    </StyledTitleStack>
  );
};

export default DisplayBar;

import React, { useEffect } from "react";
import { Stack } from "@mui/material";

import { HubCardStack, HubCircularProgress } from "../../components";
import { useGetWorkOrderData } from "../../hooks";

import WorkOrderWax from "./WorkOrderWax";
import WorkOrderDetails from "./WorkOrderDetails";
import WorkOrderDip from "./WorkOrderDip";
import WorkOrderFoundry from "./WorkOrderFoundry";
import WorkOrderFinish from "./WorkOrderFinish";
import { WorkOrderOutsideAndShipping } from "./WorkOrderOutsideAndShipping";
import WorkOrderShipping from "./WorkOrderShipping";

const Fields = [
  WorkOrderDetails,
  WorkOrderWax,
  WorkOrderDip,
  WorkOrderFoundry,
  WorkOrderFinish,
  WorkOrderOutsideAndShipping,
  WorkOrderShipping,
];

export const WorkOrder = ({ id, sx = {} }) => {
  const { data, isLoading, isError, refetch } = useGetWorkOrderData(id);

  useEffect(() => {
    refetch();
  }, [id]);

  if (isError) return <></>;

  return (
    <Stack
      padding="25px"
      sx={{
        overflowX: "hidden",
        overflowY: "auto",
        "::-webkit-scrollbar": {
          display: "none",
        },
        ...sx,
      }}
    >
      {isLoading ? (
        <HubCircularProgress centerScreen />
      ) : (
        <HubCardStack
          className="print-only"
          id="traverler-sheet"
          width="8.5in"
          height="11in"
          borderRadius={0}
          m="auto"
          p="0.125in"
        >
          <Stack
            border="1px solid"
            width="calc(8.5in - 0.25in - 2px)"
            height="calc(11in - 0.25in - 2px)"
          >
            {Fields.map((Field) => (
              <Field data={data} />
            ))}
          </Stack>
        </HubCardStack>
      )}
    </Stack>
  );
};

export default WorkOrder;

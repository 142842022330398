import React from "react";
import { Typography } from "@mui/material";

import { StyledLabelStack } from "./styles";

export const WorkOrderLabel = ({
  label = "",
  value = "",
  valueBold = false,
  underlined = false,
  c1 = "100px",
  ...rest
}) => {
  const valueSx = underlined ? { borderBottom: "1px solid" } : {};
  return (
    <StyledLabelStack c1={underlined ? "auto" : c1} {...rest}>
      <Typography variant="boldCaption" fontWeight={400} lineHeight="18px">
        {label}:
      </Typography>
      <Typography
        variant={valueBold ? "boldCaption" : "caption"}
        lineHeight="18px"
        sx={valueSx}
        noWrap
      >
        {value}
      </Typography>
    </StyledLabelStack>
  );
};

export default WorkOrderLabel;

import React from "react";
import { Stack, Typography } from "@mui/material";

import WorkOrderLabel from "./WorkOrderLabel";
import { StyledBaseBox } from "./styles";
import { formatDateMmDdYyyy } from "../../helpers/dateHelper";

const WorkOrderDetails = ({ data }) => {
  return (
    <StyledBaseBox>
      <Stack
        alignItems="center"
        justifySelf="start"
        gridColumn="span 2"
        gridRow="span 3"
      >
        <Typography variant="barcode">*{data?.workOrder}*</Typography>
        <Typography variant="caption">{data?.workOrder}</Typography>
      </Stack>
      <WorkOrderLabel label="PO #" value={data?.order?.poNumber} />
      <WorkOrderLabel label="Company" value={data?.customer?.name} />
      <WorkOrderLabel label="SCORE" value={data?.lineItem?.price} />

      <WorkOrderLabel
        label="SALES ORDER #"
        value={data?.lineItem?.lineNumber}
        mt="10px"
      />
      <WorkOrderLabel
        label="TOTAL SO QTY"
        value={data?.lineItem?.quantity}
        mt="10px"
      />
      <WorkOrderLabel
        label="MOLD #"
        value={data?.part?.waxInstructions?.moldNumber}
        mt="10px"
      />

      <WorkOrderLabel
        label="REC DATE"
        value={formatDateMmDdYyyy(data?.createdAt)}
      />
      <WorkOrderLabel label="QUANTITY REQ" value={data?.quantityReq} />
      <WorkOrderLabel label="PART NO." value={data?.part?.partNumber} />

      <WorkOrderLabel
        label="DUE DATE"
        value={formatDateMmDdYyyy(data?.dueDate)}
      />
      <WorkOrderLabel label="PAT. REQ" value={data?.quantity} />
      <WorkOrderLabel label="PART NAME" value={data?.part?.partName} />
    </StyledBaseBox>
  );
};

export default WorkOrderDetails;

import React from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";

import { getUserRole } from "../../../redux";
import { getUserMenuItems } from "../../../constants/menu";
import { FoundrySoloLogo } from "../../../svgs";

import SearchNavBar from "./SearchNavBar";
import UserProfileContextMenu from "../../ContextMenus/UserProfileContextMenu/UserProfileContextMenu";
import { StyledTopNavBar, ParentStack, StyledHubButton } from "./styles";
import { ROLES } from "../../../constants";

const NavBar = ({ role }) => {
  const navigate = useNavigate();
  const NAV_ITEMS = getUserMenuItems(role, false);

  return (
    <StyledTopNavBar className="hub-nav-bar">
      <ParentStack gap="30px">
        <FoundrySoloLogo size={30} fill="#252525" />
        <ParentStack gap="10px">
          {NAV_ITEMS?.map(({ label, path }) => (
            <StyledHubButton
              key={path}
              onClick={() => navigate(path)}
              label={label}
              {...(window.location.pathname?.includes(path) && {
                variant: "contained",
                color: "info",
              })}
              {...(!window.location.pathname?.includes(path) && {
                color: "neutral",
              })}
            />
          ))}
        </ParentStack>
      </ParentStack>
      {role !== ROLES.Generic && <SearchNavBar />}
      <UserProfileContextMenu />
    </StyledTopNavBar>
  );
};

const mapStateToProps = (state) => ({
  role: getUserRole(state),
});
export default connect(mapStateToProps)(NavBar);

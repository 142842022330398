import React from "react";
import { Stack } from "@mui/material";
import DipsTableHeader from "./DipsTableHeader";
import DipsTableBody from "./DipsTableBody";

export const DipsTable = ({ ...rest }) => {
  return (
    <Stack id="DipsTable" border="1px solid" borderColor="hubColors.greyLight">
      <DipsTableHeader />
      <DipsTableBody {...rest} />
    </Stack>
  );
};

export default DipsTable;

import React from "react";
import { Stack } from "@mui/material";

import { HubTableTemplate } from "../../components";
import { useGetAllEmployeeData } from "../../hooks";
import EmployeeStatusChip from "./EmployeeStatusChip";

const sortable = true;
const items = [
  {
    id: "employeeId",
    label: "Employee ID",
    sortable,
  },
  {
    id: "name",
    label: "Name",
    sortable,
  },
  {
    id: "locationId",
    label: "Location",
    sortable,
  },
  {
    id: "status",
    label: "Status",
    sortable,
  },
];

const keys = [
  {
    value: "employeeId",
  },
  {
    value: "employee",
    isUser: true,
  },
  {
    value: "location.name",
    isChip: true,
  },
  {
    value: "status",
    CustomComponent: EmployeeStatusChip,
  },
];

export const EmployeesTable = ({ defaultLocation = 1 }) => {
  const { data, isLoading } = useGetAllEmployeeData({ defaultLocation });

  const headerProps = { items };
  const bodyProps = { items: data?.rows, keys };
  const props = { headerProps, bodyProps, meta: data?.meta, isLoading };

  return (
    <Stack gap="10px" overflow="hidden">
      <HubTableTemplate id="EmployeeTable" {...props} />
    </Stack>
  );
};

export default EmployeesTable;

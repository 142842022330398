import { useEffect } from "react";
import { useQuery, useQueryClient } from "react-query";
import qs from "qs-lite";

import api from "../../utils/api";

const SEARCH = "/search";

const getSearchData = ({ queryKey }) => {
  const queryString = qs.stringify(queryKey[1]);
  return api.get(`${SEARCH}?${queryString}`);
};
export const useGetSearchData = (query) => {
  const queryClient = useQueryClient();
  useEffect(() => {
    queryClient.cancelQueries({
      predicate: (query) => query.queryKey[0] === SEARCH,
      exact: false,
    });
  }, [query, queryClient]);

  return useQuery([SEARCH, { query }], getSearchData, {
    retry: false,
    enabled: !!query,
  });
};

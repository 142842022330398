import React from "react";

import HubTableSubRow from "../../components/HubTableTemplate/HubTableSubRow";
// import LineItemsTable from "../CustomerOrdersTable/CustomerOrderLineWorksTable";
import OrderLineItemsTable from "../OrderLineItemsTable/OrderLineItemsTable";

export const OrderLineItemWorkRow = ({ item, open }) => {
  return (
    <HubTableSubRow item={item} isOpen={open} RenderElement={OrderLineItemsTable} length={10}/>
  );
};

export default OrderLineItemWorkRow;

import React from "react";

import { HubContextMenu } from "../../components";
import { useHubSnackBar } from "../../hooks";
import { removePoLineItem } from "../../redux/purchaseOrder";
import { connect } from "react-redux";
import { setIsOpenDialog } from "../../redux";

const MENU_ITEMS = [
  {
    id: "delete",
    label: "Delete",
    icon: "Trash2",
    color: "redError",
  },
];

const name = "repeat-work-order";

export const LineItemContextMenu = ({
  id,
  _removeLineItem = () => {},
  _setIsOpenDialog = () => {},
  disabled,
  ...rest
}) => {
  const { addSnackbarError } = useHubSnackBar();

  const onSelect = (item) => {
    switch (item?.id) {
      case "specs":
        // Specs
        break;
      case "delete":
        if (disabled) {
          addSnackbarError({
            message: "This work order cannot be deleted as it's in production",
          });
        } else {
          _removeLineItem(id);
        }
        break;
      case "repeat":
        _setIsOpenDialog(name, true);
        break;
      default:
        break;
    }
  };

  return (
    <HubContextMenu
      id={"contacts"}
      loading={false}
      menuItems={MENU_ITEMS}
      onSelect={onSelect}
      IconButtonProps={{ sx: { padding: "5px 0px" } }}
      HubIconProps={{ size: 14 }}
      {...rest}
    />
  );
};

const mapStateToProps = () => ({});
const mapDispatchToProps = {
  _removeLineItem: removePoLineItem,
  _setIsOpenDialog: setIsOpenDialog,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LineItemContextMenu);

import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Stack, Typography } from "@mui/material";
import { connect } from "react-redux";

import {
  HubCardStack,
  HubCircularProgress,
  HubHookFormSelect,
  HubHookFormWrapper,
  HubTableTemplate,
  PageContent,
} from "../../components";
import { getUserLocation } from "../../redux";
import { useGetAllLaborData } from "../../hooks";
import { DEPARTMENTS } from "../../constants";

const sortable = true;
const items = [
  {
    id: "workOrder",
    label: "Work Order",
    sortable,
  },
  {
    id: "employee",
    label: "Employee",
    sortable,
  },
  {
    id: "completed",
    label: "Completed",
  },
  {
    id: "scrapped",
    label: "Scrapped",
  },
  {
    id: "startDate",
    label: "Start Date",
  },
];

const keys = [
  {
    value: "workOrder",
    variant: "blueBold",
    navigate: true,
    route: "works",
    id: "workOrder",
  },
  {
    value: "employee",
  },
  {
    value: "completed",
    defaultValue: 0,
  },
  {
    value: "scrapped",
    defaultValue: 0,
  },
  {
    value: "startDate",
    isDateTime: true,
  },
];

const Tickets = ({ defaultLocation = 1 }) => {
  const { data, isLoading, refetch } = useGetAllLaborData({ defaultLocation });

  const navigate = useNavigate();
  const location = useLocation();

  // Refetch on URL search change
  useEffect(() => {
    refetch();
  }, [location?.search?.toString()]);

  // Timered refetch every 5 minutes (300000ms)
  useEffect(() => {
    const intervalId = setInterval(() => {
      refetch();
    }, 60000); // 5 minutes in milliseconds

    return () => clearInterval(intervalId); // Cleanup interval on unmount
  }, [refetch]);

  const onRowClick = (e, item) => {
    if (item?.id) {
      e.preventDefault();
      e.stopPropagation();
      navigate(`/labor/${item.id}`);
    }
  };

  const headerProps = { items };
  const bodyProps = { items: data?.rows, keys, onRowClick };
  const props = { headerProps, bodyProps, meta: data?.meta };

  const query = new URLSearchParams(location.search);
  const dep = query.get("department");

  const onChange = (value, name) => {
    const searchParams = new URLSearchParams(location.search);
    const pathname = location.pathname;

    value ? searchParams.set(name, value) : searchParams.delete(name);

    const params = searchParams.toString();
    navigate(`${pathname}?${params}`, { replace: true });
  };

  return (
    <PageContent>
      {isLoading ? (
        <HubCircularProgress centerScreen />
      ) : (
        <HubCardStack gap="15px" maxWidth="1000px" width="100%" mx="auto">
          <HubHookFormWrapper>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="boldCaption">Open Labor Tickets</Typography>
              <HubHookFormSelect
                name="department"
                defaultValue={dep || 1}
                options={DEPARTMENTS}
                onChange={(e) => onChange(e.target.value, "department")}
                sx={{ width: "200px" }}
                fullWidth={false}
              />
            </Stack>
          </HubHookFormWrapper>
          <HubTableTemplate id="DisplayTable" {...props} />
        </HubCardStack>
      )}
    </PageContent>
  );
};

const mapStateToProps = (state) => ({
  defaultLocation: getUserLocation(state),
});
const mapDispatchToProps = {};

export const TicketsPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(Tickets);
export default TicketsPage;

import React, { createContext, useContext } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Dialog from "@mui/material/Dialog";

import { getIsDialogOpen, setIsOpenDialog } from "../../redux/dialogs";

// Create a Context for the Dialog
const DialogContext = createContext();

// Custom hook to use the DialogContext
const useDialogContext = () => useContext(DialogContext);

const DialogWrapper = ({
  name,
  _setIsOpenDialog = () => {},
  open = false,
  children,
  customOnClose = () => {},
  ...rest
}) => {
  const onClose = () => {
    _setIsOpenDialog(name, false);
    customOnClose();
  };

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      onClose={onClose}
      open={open}
      data-testid={`${name}-test-id`}
      {...rest}
    >
      <DialogContext.Provider value={{ onClose }}>
        {children}
      </DialogContext.Provider>
    </Dialog>
  );
};

DialogWrapper.propTypes = {
  name: PropTypes.string.isRequired,
  _setIsOpenDialog: PropTypes.func,
  open: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

const mapStateToProps = (state, ownProps) => ({
  open: getIsDialogOpen(state, ownProps.name),
});

const mapDispatchToProps = {
  _setIsOpenDialog: setIsOpenDialog,
};

export const HubDialogWrapper = connect(
  mapStateToProps,
  mapDispatchToProps
)(DialogWrapper);

export { useDialogContext };

import React, { useEffect } from "react";
import PropTypes from "prop-types";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Switch from "@mui/material/Switch";
import Checkbox from "@mui/material/Checkbox";
import { useController, useFormContext } from "react-hook-form";

import { InputLabel } from "../InputLabel";
import { StyledFormControlLabel } from "./styles";

export const HubHookFormSwitch = ({
  name = "",
  defaultValue = false,
  required = false,
  rules = {},
  disabled = false,
  onChange = null,
  label = "",
  helperText = "",
  disableHelperText = true,
  labelPlacement = "start",
  includeDisplayInput = false,
  smallLabel = true,
  isCheckbox = false,
  fullWidth = true,
  labelProps = {},
  formControlProps={},
  ...rest
}) => {
  const id = `hub-hook-switch-${name}`;
  const displayInputName = `${name}-display`;

  // prevents issues where defaultValue is null
  const defaultInputValue = defaultValue || false;
  const { setValue, register } = useFormContext();
  const {
    field: {
      onChange: onControllerChange,
      onBlur,
      value,
      name: inputName,
      ref,
    },
    fieldState: { error },
  } = useController({
    name: includeDisplayInput ? displayInputName : name,
    rules: {
      ...rules,
      validate: {
        required: (value) =>
          required ? value === true || "This field is required." : true,
      },
    },
    defaultValue: defaultInputValue,
    shouldUnregister: true,
  });

  useEffect(() => {
    if (includeDisplayInput) {
      setValue(name, value);
    }
  }, [value]);

  useEffect(() => {
    if (includeDisplayInput) {
      setValue(displayInputName, defaultInputValue);
    }
    setValue(inputName, defaultInputValue);
  }, [defaultInputValue]);

  // helper text spacing will always be there so we don't "push down" elements
  const errorMessage = error?.message;
  const defaultHelperText = disableHelperText ? "" : <span>&nbsp;</span>;
  const helperTextMessage = errorMessage || helperText || defaultHelperText;

  const onSelectChange = (e) => {
    onControllerChange(e);
    onChange && onChange(e?.target?.checked);
  };

  const ElementType = isCheckbox ? Checkbox : Switch;

  return (
    <FormControl
      fullWidth={fullWidth}
      variant="outlined"
      onClick={() => {}}
      {...formControlProps}
    >
      <StyledFormControlLabel
        control={
          <ElementType
            inputRef={ref}
            id={id}
            onChange={onSelectChange}
            onBlur={onBlur}
            checked={value}
            name={inputName}
            disabled={disabled}
            {...rest}
          />
        }
        labelPlacement={labelPlacement}
        label={
          <InputLabel
            label={label}
            required={required}
            id={id}
            smallLabel={smallLabel}
            {...labelProps}
          />
        }
      />
      {!disableHelperText && (
        <FormHelperText error={!!error} id={`${id}-helper-text`}>
          {helperTextMessage}
        </FormHelperText>
      )}
      {/* Disabling the switch makes the react-hook-form value undefined, 
      so creating hidden input with real value for that case */}
      <input
        {...register(name)}
        type="checkbox"
        style={{ display: "none" }}
        defaultChecked={defaultValue}
      />
    </FormControl>
  );
};

HubHookFormSwitch.propTypes = {
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.bool,
  required: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  rules: PropTypes.object,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  helperText: PropTypes.string,
  disableHelperText: PropTypes.bool,
  labelPlacement: PropTypes.string,
  includeDisplayInput: PropTypes.bool,
};

export default HubHookFormSwitch;

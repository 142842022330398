import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Stack } from "@mui/material";
import { connect } from "react-redux";

import { setCustomerData, clearCustomerData } from "../../redux/customerData";
import { useGetCustomerDetailsById } from "../../hooks";
import { PageContent } from "../../components";

import ContactSideBar from "./ContactSideBar";
import ContactContent from "./ContactContent";

export const ContactPageUnwrapped = ({
  _setCustomerData = () => {},
  _clearCustomerData = () => {},
}) => {
  const [hasLoaded, setHasLoaded] = useState(false);

  const { id } = useParams();
  const { data, isLoading, isSuccess, refetch } = useGetCustomerDetailsById({
    id,
  });

  useEffect(() => {
    setHasLoaded(false)
    refetch();
  }, [id]);

  useEffect(() => {
    if (data && isSuccess) {
      _setCustomerData(data);
      setHasLoaded(true);
    }
  }, [data, isSuccess, isLoading]);

  useEffect(() => _clearCustomerData, []);

  return (
    <PageContent isLoading={!hasLoaded && isLoading} disablePadding>
      <Stack direction="row" height="100%" overflow="hidden">
        <ContactSideBar />
        <ContactContent />
      </Stack>
    </PageContent>
  );
};

const mapStateToProps = () => ({});
const mapDispatchToProps = {
  _setCustomerData: setCustomerData,
  _clearCustomerData: clearCustomerData,
};

export const ContactPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(ContactPageUnwrapped);

import React from "react";
import { TableBody } from "@mui/material";
import { connect } from "react-redux";

import {
  addPoLineItem,
  getPurchaseOrderItems,
} from "../../redux/purchaseOrder";

import OrderLineItemRow from "./OrderLineItemRow";

const OrderLineItemsBody = ({ items = [] }) => {
  return (
    <TableBody>
      {items?.map((item, idx) => (
        <OrderLineItemRow item={item} key={JSON.stringify(item)} idx={idx} />
      ))}
    </TableBody>
  );
};

const mapStateToProps = (state) => ({
  items: getPurchaseOrderItems(state),
});

const mapDispatchToProps = {
  _addLineItem: addPoLineItem,
};
export default connect(mapStateToProps, mapDispatchToProps)(OrderLineItemsBody);

import { createTheme } from "@mui/material/styles";
import { HubIcon } from "./components";
import { Checkbox, CheckboxChecked, CustomRadio } from "./svgs";

const mainColor = "223, 158, 68";
const yellowMain = "255, 183, 0";
const redMain = "239, 55, 55";
const greenMain = "0, 193, 45";
const blueMain = "0, 117, 255";

const transRate = 0.1;

const colorBase = {
  mainFocusLightest: `rgba(${mainColor}, 0.08)`,
  mainFocusLighter2: `rgba(${mainColor}, 0.15)`,
  mainFocusLighter: `rgba(${mainColor}, 0.25)`,
  mainFocusLight: `rgba(${mainColor}, 0.4)`,

  mainFocus: `rgb(${mainColor})`,
  mainFocusDark: "rgb(210, 140, 42)",

  redError: "#F81414",
  greenDark: "#1BAD00",

  greenMain: `rgba(${greenMain}, 1)`,
  greenMainTransparent: `rgba(${greenMain}, ${transRate})`,

  blueMain: `rgba(${blueMain}, 1)`,
  blueMainTransparent: `rgba(${blueMain}, ${transRate})`,

  redMain: `rgba(${redMain}, 1)`,
  redMainTransparent: `rgba(${redMain}, ${transRate})`,

  yellowMain: `rgba(${yellowMain}, 1)`,
  yellowMainTransparent: `rgba(${yellowMain}, ${transRate})`,

  star: "#FFCB46",
  fontWhite: "#FFFFFF",
};

const lightColors = {
  ...colorBase,
  white: "#FFFFFF",
  greyLightest: "#F7F7F7",
  greyLighter: "#F4F4F4",
  greyLight: "#E5E5E5",
  greyLightTransparent: "rgba(229, 229, 229, 0.5)",
  greyMidTransparent: "rgba(155, 155, 155, 0.2)",
  greyMidDarkTransparent: "rgba(0, 0, 0, 0.5)",
  greyDarkTransparent: "rgba(0, 0, 0, 0.06)",
  greyActive: "#CECECE",
  greyMid: "#C0C0C0",
  grey: "#AEAEAE",
  greyShade: "#696969",
  greyDark: "#525252",
  greyDark2: "#252525",
  black: "#000000",
};

const darkColors = {
  ...colorBase,
  white: "#0B0B0B",
  greyLightest: "#1A1A1A",
  greyLighter: "#2A2A2A",
  greyLight: "#252525",
  greyLightTransparent: "rgba(26, 26, 26, 0.5)",
  greyMidTransparent: "rgba(155, 155, 155, 0.2)",
  greyMidDarkTransparent: "rgba(155, 155, 155, 0.2)",
  greyDarkTransparent: "rgba(255, 255, 255, 0.2)",
  greyActive: "#313131",
  greyMid: "#3F3F3F",
  grey: "#515151",
  greyShade: "#969696",
  greyDark: "#ADADAD",
  greyDark2: "#DADADA",
  black: "#FFFFFF",
  // white: "#000000",
  // greyLightest: "#080808",
  // greyLighter: "#0B0B0B",
  // greyLight: "#1A1A1A",
  // greyLightTransparent: "rgba(26, 26, 26, 0.5)",
  // greyMidTransparent: "rgba(155, 155, 155, 0.2)",
  // greyMidDarkTransparent: "rgba(155, 155, 155, 0.2)",
  // greyDarkTransparent: "rgba(255, 255, 255, 0.2)",
  // greyActive: "#313131",
  // greyMid: "#3F3F3F",
  // grey: "#515151",
  // greyShade: "#969696",
  // greyDark: "#ADADAD",
  // greyDark2: "#DADADA",
  // black: "#FFFFFF",
};

const hubFonts = {
  roboto: '"Roboto", "Helvetica", "Arial", sans-serif !important',
  inter: '"Inter", sans-serif !important',
};

const getButtonSizeProps = (ownerState) => ({
  ...(ownerState.shape === "round" && {
    borderRadius: "50px",
  }),
  ...(ownerState.size === "xsmall" && {
    padding: "5px 12px",
    fontSize: "10px",
  }),
  ...(ownerState.size === "small" && {
    padding: "6px 16px",
    fontSize: "12px",
  }),
  ...(ownerState.size === "medium" && {
    padding: "12px 18px",
    fontSize: "14px",
  }),
  ...(ownerState.size === "large" && {
    padding: "15px 20px",
    fontSize: "16px",
  }),
});

export const theme = (mode = false) => {
  const hubColors = mode ? darkColors : lightColors;
  return createTheme({
    spacing: 5,
    hubValues: {
      headerHeight: 62,
      appBarHeight: 52,
    },
    palette: {
      primary: {
        light: hubColors.mainFocusLighter,
        main: hubColors.mainFocus,
        dark: hubColors.mainFocusDark,
      },
      error: {
        main: hubColors.redMain,
      },
      neutral: {
        main: hubColors.greyLighter,
      },
      text: {
        primary: hubColors.greyDark,
      },
      success: {
        main: hubColors.greenMain,
      },
      warning: {
        main: hubColors.yellowMain,
      },
      // add all the custom hub colors/fonts to the mui theme for reference when styling
      hubColors,
      hubFonts,
    },
    typography: {
      fontFamily: hubFonts.roboto,
      barcode: {
        fontFamily: "'Barcode39', sans-serif !important",
        fontSize: 50,
        lineHeight: "40px",
      },
      h3: {
        fontWeight: 800,
        fontSize: 24,
      },
      h5: {
        color: hubColors.greyDark,
      },
      h6: {
        fontSize: 18,
        color: hubColors.black,
        fontWeight: 500,
      },
      body1: {
        color: hubColors.greyDark,
        fontSize: "16px",
      },
      body2: {
        color: hubColors.greyDark,
        fontSize: "14px",
      },
      caption: {
        fontSize: "12px",
        color: hubColors.greyDark,
      },
      caption11: {
        fontSize: "11px",
        color: hubColors.greyDark,
      },
      dropDownText: {
        fontSize: "12px",
        color: hubColors.greyDark,
        maxWidth: "150px",
      },
      boldCaption: {
        fontWeight: 700,
        fontSize: "0.75em",
        color: hubColors.greyDark,
        textTransform: "uppercase",
      },
      boldCaptionMain: {
        fontWeight: 700,
        fontSize: "0.75em",
        color: hubColors.mainFocus,
        textTransform: "uppercase",
      },
      subtitle2: {
        color: hubColors.grey,
      },
      inputLabel: {
        color: hubColors.black,
        fontWeight: 500,
        fontSize: "16px",
        fontFamily: hubFonts.roboto,
        marginBottom: "5px",
        "& svg": {
          position: "relative",
          top: 2,
          left: 2,
          width: 16,
          height: 16,
        },
      },
      inputLabelSmall: {
        color: hubColors.greyDark,
        fontSize: "11px",
        marginBottom: "7px",
        fontWeight: 500,
      },
      checkboxLabel: {
        color: hubColors.greyDark,
        fontSize: "14px",
        fontWeight: 400,
      },
      switchLabel: {
        color: hubColors.greyDark,
        fontSize: "12px",
        fontWeight: 500,
      },
      formSectionTitle: {
        fontSize: "18px",
        color: hubColors.black,
        fontWeight: 900,
        fontFamily: hubFonts.inter,
      },
      inputAdornment: {
        color: hubColors.grey,
        fontSize: "11px",
      },
      darkBold14: {
        fontSize: "14px",
        color: hubColors.black,
        fontWeight: 500,
        fontFamily: hubFonts.roboto,
      },
      darkBold12: {
        fontSize: "12px",
        color: hubColors.black,
        fontWeight: 500,
        fontFamily: hubFonts.roboto,
      },
      greyDark12Bold: {
        fontSize: "12px",
        color: hubColors.greyDark,
        fontWeight: 500,
        fontFamily: hubFonts.roboto,
      },
      greyDark12: {
        fontSize: "12px",
        color: hubColors.greyDark,
        fontFamily: hubFonts.roboto,
      },
      pageTitle: {
        fontSize: "18px",
        color: hubColors.black,
        fontWeight: 500,
        lineHeight: "16px",
      },
      pageSubTitle: {
        fontSize: "14px",
        color: hubColors.greyDark,
        lineHeight: "16px",
      },
      dialogBody2: {
        color: hubColors.greyDark,
        fontSize: "14px",
        padding: "0 60px",
        textAlign: "center",
      },
      pluginTypeLabel: {
        fontWeight: 500,
        fontSize: "14px",
        fontFamily: hubFonts.inter,
        color: hubColors.greyDark,
      },
      error: {
        color: hubColors.redError,
        fontSize: "14px",
      },
    },
    components: {
      MuiAccordion: {
        styleOverrides: {
          root: {
            boxShadow: "none",
            "&:before": {
              display: "none",
            },
            "& .MuiAccordionSummary-gutters": {
              margin: 0,
              padding: 0,
            },
            "& .MuiAccordionSummary-content": {
              margin: 0,
              padding: 0,
            },
          },
        },
      },
      MuiButtonGroup: {
        defaultProps: {
          disableRipple: true,
        },
      },
      MuiButton: {
        defaultProps: {
          disableRipple: true,
          disableElevation: true,
        },
        styleOverrides: {
          root: ({ ownerState }) => ({
            textTransform: "none",
            ...getButtonSizeProps(ownerState),
            ...(ownerState.inline && {
              height: "auto",
              padding: 0,
            }),
            ...(ownerState.disableBackground && {
              backgroundColor: "transparent",
              "&:hover": {
                backgroundColor: "transparent",
              },
            }),
            ...(ownerState.underline && {
              textDecoration: "underline",
              ":hover": {
                textDecoration: "underline",
              },
            }),
          }),
          contained: ({ ownerState }) => ({
            color: hubColors.fontWhite,
            borderRadius: "5px",
            padding: "15px 20px",
            fontWeight: "500 !important",
            fontSize: "12px",
            ":hover": {
              backgroundColor: hubColors.mainFocusDark,
            },
            ":disabled": {
              color: hubColors.greyDark,
              backgroundColor: hubColors.greyLight,
              ".MuiButton-startIcon": {
                "& span": {
                  color: hubColors.greyDark,
                },
              },
            },
            ...getButtonSizeProps(ownerState),
            ...(ownerState.color === "grey" && {
              color: hubColors.greyDark,
              backgroundColor: hubColors.greyLight,
              ":hover": {
                backgroundColor: hubColors.greyLight,
              },
              ":active": {
                backgroundColor: hubColors.greyActive,
              },
              ":disabled": {
                color: hubColors.grey,
                ".MuiButton-startIcon": {
                  "& span": {
                    color: hubColors.grey,
                  },
                },
              },
              ".MuiButton-startIcon": {
                "& span": {
                  color: hubColors.greyDark,
                },
              },
            }),
            ...(ownerState.color === "neutral" && {
              backgroundColor: hubColors.greyDark2,
              ":hover": {
                backgroundColor: hubColors.greyDark,
              },
              ":active": {
                backgroundColor: hubColors.greyDark2,
              },
              ":disabled": {
                color: hubColors.greyDark,
                ".MuiButton-startIcon": {
                  "& span": {
                    color: hubColors.greyDark,
                  },
                },
              },
              ".MuiButton-startIcon": {
                "& span": {
                  color: hubColors.fontWhite,
                },
              },
            }),
            ...(ownerState.flow === "white" && {
              color: hubColors.mainFocus,
              backgroundColor: hubColors.white,
              ":hover": {
                backgroundColor: hubColors.mainFocusLighter,
              },
              ":active": {
                backgroundColor: hubColors.white,
              },
              ":disabled": {
                color: hubColors.grey,
              },
            }),
            ...(ownerState.color === "info" && {
              color: hubColors.mainFocus,
              backgroundColor: hubColors.mainFocusLighter2,
              ":hover": {
                backgroundColor: hubColors.mainFocusLighter,
              },
              ":active": {
                backgroundColor: hubColors.mainFocusLighter2,
              },
              ":disabled": {
                color: hubColors.grey,
              },
            }),
          }),
          outlined: ({ ownerState }) => ({
            borderRadius: "5px",
            padding: "15px 20px",
            fontWeight: "500 !important",
            fontSize: "12px",
            ...getButtonSizeProps(ownerState),
            ...(ownerState.color === "neutral" && {
              color: hubColors.greyDark,
              borderColor: hubColors.greyLight,
              ":hover": {
                borderColor: hubColors.greyActive,
              },
              ":active": {
                borderColor: hubColors.grey,
              },
            }),
          }),
          text: ({ ownerState }) => ({
            cursor: "pointer",
            minWidth: 0,
            padding: 0,
            width: "fit-content",
            ":hover": {
              backgroundColor: "transparent !important",
            },
            ":active": {
              backgroundColor: "transparent !important",
            },
            ":disabled": {
              color: hubColors.grey,
            },
            ...(ownerState.color === "neutral" && {
              color: hubColors.greyDark,
              ":disabled": {
                color: hubColors.grey,
              },
            }),
            ...(ownerState.flow === "white" && {
              color: hubColors.fontWhite,
              ":disabled": {
                color: hubColors.fontWhite,
              },
            }),
          }),
          tinyButton: ({ ownerState }) => ({
            fontSize: 10,
            fontWeight: 500,
            borderRadius: "10px",
            padding: "5px 12px",
            minWidth: "auto",
            border: "1px solid",
            borderColor: hubColors.mainFocus,
            color: hubColors.mainFocus,
            "& .MuiStack-root": {
              gap: "5px",
            },
            ":hover": {
              borderColor: hubColors.mainFocusDark,
            },
            ":active": {
              borderColor: hubColors.mainFocus,
            },
            ...(ownerState.color === "neutral" && {
              color: hubColors.greyDark,
              borderColor: hubColors.greyLight,
              ":hover": {
                borderColor: hubColors.greyActive,
              },
              ":active": {
                borderColor: hubColors.grey,
              },
              ":disabled": {
                backgroundColor: hubColors.greyLightest,
              },
            }),
          }),
          textInter: ({ ownerState }) => ({
            fontFamily: hubFonts.inter,
            ...(ownerState.color === "neutral" && {
              color: hubColors.greyDark,
              ":hover": {
                backgroundColor: hubColors.greyLighter,
              },
              ":active": {
                backgroundColor: hubColors.greyLight,
              },
              ":disabled": {
                color: hubColors.grey,
              },
            }),
          }),
          navitem: ({ ownerState }) => ({
            fontSize: "14px",
            fontWeight: "500 !important",
            padding: "10px 20px",
            borderRadius: "50px",
            ...(ownerState.current && {
              backgroundColor: `${hubColors.mainFocusLight}`,
              color: `${hubColors.mainFocus}`,
              fontWeight: "500 !important",
              ":hover": {
                backgroundColor: hubColors.mainFocusLighter2,
                color: `${hubColors.mainFocus} !important`,
              },
              ":active": {
                backgroundColor: hubColors.mainFocusLighter2,
                color: `${hubColors.mainFocus} !important`,
              },
              ":disabled": {
                color: hubColors.grey,
              },
            }),
            ...(!ownerState.current && {
              backgroundColor: hubColors.white,
              color: hubColors.greyDark,
              ":hover": {
                backgroundColor: hubColors.greyLighter,
                color: `${hubColors.greyDark} !important`,
              },
              ":active": {
                backgroundColor: hubColors.greyLighter,
                color: `${hubColors.greyDark} !important`,
              },
              ":disabled": {
                color: hubColors.grey,
              },
            }),
          }),
          icon: {
            padding: "0 !important",
            backgroundColor: "transparent",
            color: hubColors.greyDark,
            width: "min-content",
            minWidth: "min-content",
            ":hover": {
              backgroundColor: "transparent",
              color: hubColors.greyDark2,
            },
            ":active": {
              backgroundColor: "transparent",
              color: hubColors.greyDark2,
            },
            ":disabled": {
              color: hubColors.greyLight,
            },
          },
          redButton: {
            fontSize: "14px",
            padding: "6px 16px",
            backgroundColor: "red",
            color: hubColors.white,
            ":hover": {
              backgroundColor: `red`,
              color: `${hubColors.white} !important`,
            },
            ":active": {
              backgroundColor: `red`,
              color: `${hubColors.white} !important`,
            },
            ":disabled": {
              color: hubColors.grey,
            },
          },
        },
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            marginLeft: "0px",
            fontSize: "12px",
          },
        },
      },
      MuiFormControl: {
        defaultProps: {
          onClick: (e) => {
            e.preventDefault();
            e.stopPropagation();
          },
        },
      },
      MuiCheckbox: {
        defaultProps: {
          disableRipple: true,
          icon: <Checkbox color={hubColors.greyLight} />,
          checkedIcon: <CheckboxChecked color={hubColors.mainFocus} />,
        },
        styleOverrides: {
          root: {
            color: hubColors.grey,
          },
        },
      },
      MuiRadio: {
        defaultProps: {
          disableRipple: true,
          icon: <CustomRadio />,
          checkedIcon: <CustomRadio checked />,
        },
        styleOverrides: {
          root: {
            color: hubColors.grey,
          },
        },
      },
      MuiDivider: {
        styleOverrides: {
          root: {
            borderColor: hubColors.greyLight,
          },
        },
      },
      MuiDrawer: {
        styleOverrides: {
          root: {
            ".MuiPaper-root": {
              backgroundColor: hubColors.white,
            },
          },
        },
      },
      MuiDialog: {
        styleOverrides: {
          root: {
            "& .MuiDialog-paper": {
              backgroundColor: hubColors.white,
              borderRadius: `15px`,
            },
            "& .MuiBackdrop-root": {
              backgroundColor: hubColors.greyMidDarkTransparent,
            },
          },
        },
      },
      MuiDialogTitle: {
        styleOverrides: {
          root: {
            borderBottom: `1px solid ${hubColors.greyLight}`,
          },
        },
      },
      MuiDialogContent: {
        styleOverrides: {
          root: {
            padding: `20px !important`,
          },
        },
      },
      MuiBackdrop: {
        styleOverrides: {
          root: {
            // backgroundColor: hubColors.greyMidDarkTransparent,
          },
        },
      },
      MuiToolbar: {
        styleOverrides: {
          root: {
            padding: `15px`,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          },
          contained: {
            backgroundColor: hubColors.mainFocus,
            "& .MuiTypography-root, .MuiButtonBase-root": {
              color: hubColors.fontWhite,
            },
          },
          transparent: {
            margin: "0px 15px",
            padding: `15px 0px !important`,
            backgroundColor: "transparent",
            borderBottom: "1px solid",
            borderColor: hubColors.greyMidTransparent,
          },
        },
      },
      MuiAlert: {
        styleOverrides: {
          standardInfo: {
            color: hubColors.mainFocus,
            backgroundColor: hubColors.mainFocusLighter2,
          },
        },
      },
      MuiList: {
        styleOverrides: {
          root: {
            "& .Mui-selected": {
              backgroundColor: "transparent !important",
              color: hubColors.mainFocus,
            },
          },
        },
      },
      MuiSkeleton: {
        defaultProps: {
          animation: "wave",
        },
        styleOverrides: {
          root: {
            backgroundColor: hubColors.greyLightTransparent,
          },
        },
      },
      MuiChartsSurface: {
        styleOverrides: {
          root: {
            ".MuiChartsGrid-line": {
              stroke: `${hubColors.greyLight} !important`,
            },
            ".MuiLineElement-root": {
              strokeWidth: 1.7,
            },
            ".MuiChartsAxis-directionY": {
              ".MuiChartsAxis-line": {
                display: "none",
              },
            },
            ".MuiChartsAxisHighlight-root": {
              stroke: hubColors.grey,
            },
            ".MuiChartsAxis-directionX": {
              ".MuiChartsAxis-line": {
                stroke: hubColors.grey,
              },
            },
            ".MuiChartsReferenceLine-root": {
              ".MuiChartsReferenceLine-line": {
                stroke: hubColors.grey,
                zIndex: 1,
              },
            },
            ".MuiBarElement": {
              zIndex: 2,
            },
            ".MuiChartsAxis-tick": {
              display: "none",
            },
            ".MuiMarkElement-root": {
              strokeWidth: 2,
              animation: "pulse 2s infinite",
            },

            "@keyframes pulse": {
              "0%": {
                strokeWidth: 2,
                strokeOpacity: 1,
              },
              "50%": {
                strokeWidth: 15,
                strokeOpacity: 0,
              },
              "50.5%": {
                strokeWidth: 0,
                strokeOpacity: 0,
              },
            },
          },
        },
      },
      MuiChip: {
        defaultProps: {
          size: "small",
          deleteIcon: <HubIcon icon="X" />,
        },
        styleOverrides: {
          root: ({ ownerState }) => ({
            borderRadius: "5px",
            // margin: "9px 0px",
            fontSize: "12px",
            backgroundColor: hubColors.greyDarkTransparent, //hubColors.greyLighter
            ...(ownerState.color === "success" && {
              color: hubColors.white,
              backgroundColor: hubColors.greenMain,
            }),
            ...(ownerState.color === "warning" && {
              color: hubColors.white,
              backgroundColor: hubColors.yellowMain,
            }),
            ...(ownerState.color === "info" && {
              color: hubColors.white,
              backgroundColor: hubColors.mainFocus,
            }),
            ...(ownerState.removeMargin === "true" && {
              margin: "0px",
            }),
          }),
          deleteIcon: {
            color: "inherit",
          },
          colorTag: ({ ownerState }) => ({
            color: hubColors.mainFocus,
            backgroundColor: hubColors.mainFocusLighter2,
            fontSize: "12px",
            fontWeight: 400,
            // margin: "0",
            ...(ownerState.flow === "grey" && {
              backgroundColor: hubColors.greyDarkTransparent,
              color: hubColors.greyDark,
            }),
            ...(ownerState.flow === "green" && {
              backgroundColor: hubColors.greenMainTransparent,
              color: hubColors.greenMain,
            }),
            ...(ownerState.flow === "yellow" && {
              backgroundColor: hubColors.yellowMainTransparent,
              color: hubColors.yellowMain,
            }),
            ...(ownerState.flow === "red" && {
              backgroundColor: hubColors.redMainTransparent,
              color: hubColors.redMain,
            }),
            ...(ownerState.flow === "blue" && {
              backgroundColor: hubColors.blueMainTransparent,
              color: hubColors.blueMain,
            }),
            ...(ownerState.flow === "main" && {
              backgroundColor: hubColors.mainFocusLighter2,
              color: hubColors.mainFocus,
            }),
            ...(ownerState.flow === "mainFilled" && {
              color: hubColors.fontWhite,
              backgroundColor: hubColors.mainFocus,
            }),
          }),
          outlinedTag: ({ ownerState }) => ({
            backgroundColor: "transparent",
            border: "1px solid",
            borderColor: hubColors.mainFocus,
            color: hubColors.mainFocus,
            fontSize: "12px",
            fontWeight: 500,
            margin: "0",
            ...(ownerState.flow === "grey" && {
              borderColor: hubColors.grey,
              color: hubColors.grey,
            }),
            ...(ownerState.flow === "green" && {
              borderColor: hubColors.greenMain,
              color: hubColors.greenMain,
            }),
            ...(ownerState.flow === "yellow" && {
              borderColor: hubColors.yellowMain,
              color: hubColors.yellowMain,
            }),
            ...(ownerState.flow === "red" && {
              borderColor: hubColors.redMain,
              color: hubColors.redMain,
            }),
          }),
          primaryButton: {
            backgroundColor: hubColors.mainFocus,
            color: hubColors.white,
            margin: 0,
            fontSize: "12px",
            padding: "5px 10px",
            height: "30px",
            fontFamily: hubFonts.inter,
            fontWeight: 500,
            "& .MuiChip-icon": {
              color: hubColors.white,
              marginRight: 0,
            },
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            input: {
              backgroundColor: hubColors.white,
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            borderRadius: "10px",
            backgroundColor: hubColors.white,
            fontSize: "11px",
            padding: "10px 15px",
            fieldset: {
              fontSize: "12px",
              borderColor: hubColors.greyLight,
              ":hover": {
                borderColor: `${hubColors.grey} !important`,
              },
            },
            "&.Mui-disabled": {
              backgroundColor: hubColors.greyLighter,
              "& textarea": {
                WebkitTextFillColor: hubColors.grey,
              },
              "& input": {
                WebkitTextFillColor: hubColors.grey,
              },
              "&.MuiInputBase-root": {
                "& fieldset": {
                  borderColor: hubColors.greyLight,
                },
              },
            },
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          input: {
            fontFamily: hubFonts.roboto,
            padding: "0 !important",
            "&::placeholder": {
              color: hubColors.grey,
              opacity: 1,
            },
          },
        },
      },
      MuiInputAdornment: {
        styleOverrides: {
          root: {
            "& svg": {
              width: "16px",
              height: "16px",
              color: hubColors.grey,
            },
          },
        },
      },
      MuiTableContainer: {
        styleOverrides: {
          root: {
            height: "100%",
            position: "relative",
            display: "flex",
            flexDirection: "column",
            gap: "50px",
            overflow: "auto",
            border: "1px solid",
            borderColor: `${hubColors.greyLight} !important`,
            "::-webkit-scrollbar": { display: "none" },

            "& .MuiTableCell-root": {
              padding: "8px 12px",
              borderRight: "1px solid",
              borderColor: `${hubColors.greyLight} !important`,
              textWrap: "nowrap",
              fontSize: 12,
            },
            // "& .MuiTableCell-root:last-of-type": {
            //   borderRight: "0px solid",
            // },
          },
        },
      },
      MuiTable: {
        styleOverrides: {
          root: {
            height: "fit-content",
            position: "relative",
            backgroundColor: hubColors.white,
          },
        },
      },
      MuiTableHead: {
        styleOverrides: {
          root: {
            top: 0,
            zIndex: 1,
            position: "sticky",
            borderBottom: "1px solid",
            borderColor: `${hubColors.greyLight} !important`,
            "& .MuiTableCell-root": {
              padding: "4px 12px",
              fontSize: 12,
            },
          },
        },
      },
      MuiTableRow: {
        styleOverrides: {
          root: ({ ownerState }) => ({
            zIndex: 0,
            borderBottom: "1px solid",
            borderColor: `${hubColors.greyLight} !important`,
            ":hover": {
              cursor: "pointer",
              backgroundColor: `${hubColors.greyLightest} !important`,
            },
            ...(ownerState?.disabled && {
              ":hover": {},
            }),
            ...(ownerState?.bgDisabled && {
              ":hover": {
                cursor: "pointer",
              },
            }),
          }),
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            borderBottom: "0px",
          },
          small: {
            minWidth: "0px !important",
            width: "0px !important",
            padding: "8px !important",
            svg: {
              verticalAlign: "middle",
            },
          },
          blueBold: {
            color: hubColors.mainFocusDark,
            fontWeight: 400,
            ":hover": {
              textDecoration: "underline",
            },
          },
          mainBold: {
            color: hubColors.mainFocusDark,
            fontWeight: 600,
          },
        },
      },
      MuiTooltip: {
        styleOverrides: {
          popper: {
            "& .MuiTooltip-tooltip": {
              margin: "8px !important",
              fontSize: "0.85em",
            },
          },
        },
      },
      MuiPagination: {
        styleOverrides: {
          ul: {
            "& .MuiPaginationItem-root.Mui-selected": {
              backgroundColor: hubColors.mainFocusLighter,
              color: hubColors.mainFocus,
              "&:hover": {
                backgroundColor: hubColors.mainFocusLighter,
              },
            },
          },
        },
      },
      MuiCircularProgress: {
        styleOverrides: {
          root: {
            color: hubColors.greyDark,
          },
        },
      },
      MuiIconButton: {
        defaultProps: {
          disableRipple: true,
        },
        styleOverrides: {
          root: ({ ownerState }) => ({
            color: hubColors.greyDark,
            ...(ownerState.color === "neutral" && {
              backgroundColor: hubColors.greyLighter,
            }),
          }),
        },
      },
      MuiTab: {
        defaultProps: {
          disableRipple: true,
        },
      },
      MuiTableBody: {
        styleOverrides: {
          root: {
            backgroundColor: hubColors.white,
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            backgroundColor: hubColors.white,
            "& .MuiDateCalendar-root": {
              "& .MuiDayCalendar-header": {
                span: {
                  color: hubColors.mainFocus,
                  fontWeight: 500,
                },
              },
              "& .MuiPickersDay-today": {
                border: "none",
                borderRadius: "25px !important",
                backgroundColor: hubColors.mainFocusLighter,
                color: hubColors.mainFocus,
                fontWeight: 500,
              },
              "& .Mui-selected": {
                border: "none",
                backgroundColor: hubColors.mainFocus,
                color: hubColors.fontWhite,
                borderRadius: "25px !important",
              },
            },
          },
        },
      },
      MuiMenu: {
        styleOverrides: {
          root: {
            "& .MuiPaper-root": {
              marginTop: "10px !important",
              border: `1px solid ${hubColors.greyLight} !important`,
              boxShadow: "0px 2px 5px 0px rgba(0, 0, 0, 0.2) !important",
              backgroundColor: hubColors.white,
              "& .MuiList-root": {
                "& .MuiMenuItem-root": {
                  padding: "8px 14px",
                  "& .MuiTypography-root": {
                    fontSize: "14px",
                  },
                  "& .MuiSvgIcon-root": {
                    width: "16px",
                    height: "16px",
                  },
                },
              },
            },
          },
        },
      },
      MuiPickersLayout: {
        styleOverrides: {
          root: {
            "& .MuiMultiSectionDigitalClock-root": {
              padding: "5px",
              boxShadow: "border-box",
              "& .MuiList-root, & .MuiMultiSectionDigitalClockSection": {
                width: "fit-content",
              },
            },
            "& .MuiButtonBase-root, & .MuiMenuItem-root, & .MuiMultiSectionDigitalClockSection-item":
              {
                borderRadius: "5px !important",
              },
            "& .MuiDialogActions-root": {
              display: "none",
            },
            "*::-webkit-scrollbar": {
              display: "none",
            },
          },
        },
      },
      MuiMenuItem: {
        defaultProps: {
          disableRipple: true,
        },
      },
      MuiSwitch: {
        styleOverrides: {
          root: ({ theme }) => ({
            width: 34,
            height: 20,
            padding: 0,
            display: "flex",
            "&:active": {
              "& .MuiSwitch-thumb": {
                width: 15,
              },
              "& .MuiSwitch-switchBase.Mui-checked": {
                transform: "translateX(10px)",
              },
            },
            "& .MuiSwitch-switchBase": {
              padding: 3,
              "&.Mui-disabled": {
                color: hubColors.white,
                "& + .MuiSwitch-track": {
                  opacity: 1,
                },
              },
              "&.Mui-checked": {
                transform: "translateX(14px)",
                color: hubColors.white,
                "& + .MuiSwitch-track": {
                  opacity: 1,
                  backgroundColor: hubColors.mainFocus,
                },
                "&.Mui-disabled": {
                  color: hubColors.white,
                  "& + .MuiSwitch-track": {
                    opacity: 1,
                    backgroundColor: hubColors.mainFocusLighter2,
                  },
                },
              },
            },
            "& .MuiSwitch-thumb": {
              boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
              width: 14,
              height: 14,
              borderRadius: 100,
              transition: theme.transitions.create(["width"], {
                duration: 200,
              }),
            },
            "& .MuiSwitch-track": {
              borderRadius: 100,
              opacity: 1,
              backgroundColor: hubColors.greyLight,
              boxSizing: "border-box",
            },
          }),
        },
      },
    },
  });
};

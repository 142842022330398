import React from "react";
import { Chip } from "@mui/material";

import { JOB_STATUSES } from "../../constants";

const flow = {
  A: { label: "Active", color: "green" },
  T: { label: "Terminated", color: "red" },
  O: { label: "Other", color: "grey" },
  L: { label: "Leave of Absence", color: "yellow" },
};
const EmployeeStatusChip = ({ item }) => {
  const label = item?.status || "A";
  const final = flow[label];

  return (
    <Chip
      label={final.label}
      variant="colorTag"
      flow={final.color}
      sx={{
        margin: "6px",
        "& .MuiChip-label": {
          fontSize: 10,
        },
      }}
    />
  );
};

export default EmployeeStatusChip;

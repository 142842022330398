import { styled } from "@mui/material/styles";
import { Stack, TableRow } from "@mui/material";
import { HubHookFormDatePicker } from "../../components";

export const StyledChildStack = styled(Stack)(({ theme }) => ({
  padding: "10px",
  backgroundColor: theme.palette.hubColors.greyLightest,
}));

export const StyledStack = styled(Stack)(({ theme }) => ({
  justifyContent: "center",
  alignItems: "start",
  gap: "10px",
  borderRadius: "0px",
}));

export const StyledSubStack = styled(Stack)(({ theme }) => ({
  padding: "10px 15px",
  flexDirection: "row",
  justifyContent: "space-between",
}));

export const StyledTableRow = styled(TableRow, {
  shouldForwardProp: (prop) => !["main"].includes(prop),
})(({ theme, main }) => ({
  "& .MuiTableCell-root": {
    // height: "52px",
    // padding: "4px 5px !important",
    ...(main
      ? { backgroundColor: theme.palette.hubColors.mainFocusLightest }
      : {}),
    padding: "0 !important",
    ">.MuiTypography-root": {
      marginLeft: "13px",
    },
    ".MuiInputBase-root, input": {
      borderRadius: 0,
      ...(main ? { backgroundColor: "transparent" } : {}),
    },
    fieldset: {
      borderWidth: "0px",
    },
    "&[disabled]": {
      backgroundColor: theme.palette.hubColors.greyLightest,
    },
  },
}));

export const StyledHubHookFormDatePicker = styled(HubHookFormDatePicker)({
  input: {
    width: 60,
  },
  "& .MuiIconButton-root": {
    paddingLeft: 0,
  },
});

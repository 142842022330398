import React from "react";
import { Stack } from "@mui/material";

import { DialogButton, HubButton, HubHookFormWrapper } from "../../components";

import ContactsLocationFilter from "../../pages/Companies/ContactsLocationFilter";
import EmployeeStatusFilter from "./EmployeeStatusFilter";

const EmployeesBar = ({ disabled }) => {
  return (
    <HubHookFormWrapper onSubmit={() => {}} sx={{ width: "100%" }}>
      <Stack justifyContent="space-between" direction="row" width="100%">
        <Stack direction="row" gap="10px">
          <ContactsLocationFilter />
          <EmployeeStatusFilter />
        </Stack>
        <Stack direction="row" gap="10px">
          <HubButton
            label="Download Employee Labels"
            size="xsmall"
            variant="contained"
            color="grey"
            icon="Share"
            iconProps={{ size: 12 }}
            onClick={() => window.print()}
          />
          <DialogButton
            name="create-employee"
            label="Create Employee"
            variant="contained"
            size="xsmall"
            disabled={disabled}
          />
        </Stack>
      </Stack>
    </HubHookFormWrapper>
  );
};

export default EmployeesBar;

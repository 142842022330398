import React from "react";
import { SvgIcon } from "@mui/material";
import {
  CheckCircle,
  Calendar,
  Bell,
  Zap,
  Home,
  Layers,
  Grid,
  Video,
  Layout,
  Bookmark,
  Copy,
  Command,
  Info,
  Share,
  ChevronsRight,
  ChevronsLeft,
  ChevronRight,
  ChevronLeft,
  ChevronDown,
  AlertTriangle,
  Plus,
  Star,
  MoreVertical,
  BarChart2,
  User,
  X,
  Mail,
  Search,
  MessageSquare,
  UploadCloud,
  ChevronUp,
  Target,
  Settings,
  Codesandbox,
  Loader,
  Sidebar,
  Download,
  Trash2,
  Trash,
  Filter,
  ToggleLeft,
  HelpCircle,
  Paperclip,
  MoreHorizontal,
  CreditCard,
  FileText,
  Share2,
  Archive,
  Columns,
  Trello,
  Table,
  Activity,
  Server,
  List,
  Bold,
  Italic,
  Lock,
  Type,
  MousePointer,
  ArrowUp,
  ArrowDown,
  Eye,
  EyeOff,
  Menu,
  Square,
  ArrowUpLeft,
  ArrowLeft,
  PlayCircle,
  Image,
  Mic,
  File,
  Folder,
  Hash,
  DollarSign,
  GitCommit,
  Clock,
  Navigation2,
  Disc,
  Globe,
  Smartphone,
  Phone,
  MapPin,
  TrendingUp,
  TrendingDown,
  AlertCircle,
  RefreshCw,
  XCircle,
  ExternalLink,
  ArrowRight,
  Maximize,
} from "react-feather";

import { Star as HubStar } from "../../svgs/Star";
import { Play2 } from "./CustomIcons";

import {
  Text as HubText,
  Email as HubEmail,
  Number as HubNumber,
  Currency,
  Short,
  Long,
  Name,
  StarRating,
  Likert,
  Calendar as HubCalendar,
  Time,
  RadioGroup,
  Checkbox,
  Dropdown,
} from "./element";

const IconMap = {
  Mail,
  CheckCircle,
  Calendar,
  Bell,
  Zap,
  Home,
  Layers,
  Grid,
  Video,
  Layout,
  Bookmark,
  Copy,
  Command,
  Info,
  Share,
  ChevronsRight,
  ChevronsLeft,
  ChevronRight,
  ChevronLeft,
  ChevronDown,
  AlertTriangle,
  Plus,
  Star,
  MoreVertical,
  BarChart2,
  User,
  X,
  Search,
  MessageSquare,
  UploadCloud,
  ChevronUp,
  Target,
  Settings,
  Codesandbox,
  Loader,
  Sidebar,
  Download,
  Trash2,
  Trash,
  Filter,
  ToggleLeft,
  HelpCircle,
  Paperclip,
  MoreHorizontal,
  CreditCard,
  FileText,
  Share2,
  Archive,
  Columns,
  Trello,
  Table,
  Activity,
  Server,
  List,
  Bold,
  Italic,
  Lock,
  Play2,
  Type,
  MousePointer,
  ArrowUp,
  ArrowDown,
  Eye,
  EyeOff,
  Menu,
  Square,
  ArrowUpLeft,
  ArrowLeft,
  HubStar,
  HubText,
  HubEmail,
  HubNumber,
  Currency,
  Short,
  Long,
  Name,
  StarRating,
  Likert,
  HubCalendar,
  Time,
  RadioGroup,
  Checkbox,
  Dropdown,
  PlayCircle,
  Image,
  Mic,
  File,
  Folder,
  Hash,
  DollarSign,
  GitCommit,
  Clock,
  Navigation2,
  Disc,
  Globe,
  Smartphone,
  Phone,
  MapPin,
  TrendingUp,
  TrendingDown,
  AlertCircle,
  RefreshCw,
  XCircle,
  ExternalLink,
  ArrowRight,
  Maximize,
};

export const HubIcon = ({
  icon = "",
  size = "18px",
  sx = {},
  children,
  ...rest
}) => {
  const style = {
    ...sx,
    width: size,
    height: size,
  };

  if (icon) {
    const SelectedIcon = IconMap[icon];
    return (
      <SvgIcon sx={style} {...rest}>
        <SelectedIcon />
      </SvgIcon>
    );
  }
  return (
    <SvgIcon sx={style} {...rest}>
      {children}
    </SvgIcon>
  );
};

export default HubIcon;

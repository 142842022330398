import React from "react";
import { Typography } from "@mui/material";

import WorkOrderLabel from "./WorkOrderLabel";
import { StyledMeltBox, StyledMeltStack } from "./styles";

const WorkOrderFoundry = ({ data }) => {
  let foundry = data?.part?.foundryInstructions;

  return (
    <StyledMeltStack>
      <Typography variant="boldCaptionMain">Melt Department</Typography>
      <StyledMeltBox>
        <WorkOrderLabel label="HEAT NUMBER" underlined />
        <WorkOrderLabel label="WRAP TREE" underlined c1="auto" />
        <WorkOrderLabel label="METAL WT" value={data?.part?.material?.weight} />
        <WorkOrderLabel label="OVEN #" underlined c1="auto" />

        <WorkOrderLabel
          label="ALLOY"
          value={data?.part?.material?.name}
          valueBold
          c1="auto"
        />
        <WorkOrderLabel label="SHELL TEMP" value={foundry?.shellTemp} />
        <WorkOrderLabel label="METAL TEMP" value={foundry?.metalTemp} />
        <div />

        <WorkOrderLabel label="ALLOY ADDS" />
        <WorkOrderLabel label="Fe Si" underlined c1="auto" />
        <WorkOrderLabel label="Fe Mn" underlined c1="auto" />
        <WorkOrderLabel label="Fe C" underlined c1="auto" />

        <div />
        <WorkOrderLabel label="Fe Ti" underlined c1="auto" />
        <WorkOrderLabel label="Alum" underlined c1="auto" />
        <WorkOrderLabel label="ALL ADD" underlined c1="auto" />
      </StyledMeltBox>
      <WorkOrderLabel
        label="SPEC INST."
        value={foundry?.notes}
        valueBold
        c1="auto"
      />
    </StyledMeltStack>
  );
};

export default WorkOrderFoundry;

import React from "react";
import { Stack, Typography } from "@mui/material";
import { connect } from "react-redux";

import { getUserLocation } from "../../redux/auth";
import { INDUSTRIES, FOUNDRIES } from "../../constants/industry";

import {
  HubHookFormInput,
  HubHookFormSelect,
  HubHookFormTagInput,
} from "../../components";

const CreateCompanyDetails = ({ disabled }) => {
  return (
    <Stack gap="15px" width="100%">
      <Typography variant="boldCaptionMain">Customer Details</Typography>
      <Stack
        direction="row"
        gap="10px"
        alignItems="center"
        justifyContent="center"
      >
        <HubHookFormInput
          name="customerName"
          label="Customer"
          placeholder="IPC Foundry Group"
          disableHelperText
          disabled={disabled}
          required
        />
        <HubHookFormSelect
          name="industry"
          label="Industry"
          placeholder="ex. Oil"
          options={INDUSTRIES}
          sx={{ flexGrow: "1" }}
          disabled={disabled}
          disableHelperText
          required
        />
      </Stack>
      <HubHookFormTagInput
        name="locations"
        label="Foundries"
        options={FOUNDRIES}
        disabled={disabled}
        disableHelperText
        required
      />
    </Stack>
  );
};

const mapStateToProps = (state) => ({
  userLocation: getUserLocation(state),
});
export default connect(mapStateToProps)(CreateCompanyDetails);

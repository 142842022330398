import React from "react";
import { HubFilterMenu } from "../../components";

const types = [
  {
    id: "A",
    label: "Active",
  },
  {
    id: "T",
    label: "Terminated",
  },
  {
    id: "O",
    label: "Other",
  },
  {
    id: "L",
    label: "Leave of Absence",
  },
];

const EmployeeStatusFilter = ({ ...rest }) => {
  return (
    <HubFilterMenu
      id="status"
      options={types}
      menuLabel="Filter Employees by Status"
      label="Employee Status"
      useDefault={false}
      {...rest}
    />
  );
};

export default EmployeeStatusFilter;

import React from "react";

import { HubTableHeader, InputLabel } from "../../components";
import { StyledStack } from "./styles";

const required = true;

const headCells = [
  {
    id: "di-prewet",
    label: "Prewet",
  },
  {
    id: "di-dip",
    label: "Dip",
    required,
  },
  {
    id: "di-cycle",
    label: "Cycle Time",
    required,
  },
  {
    id: "di-lead",
    label: "Lead Time",
    required,
  },
  {
    id: "di-station",
    label: "Station",
  },
];

export const DipsTableHeader = () => {
  const ITEMS = headCells.map((cell) => ({
    id: cell.id,
    label: (
      <InputLabel
        label={cell.label}
        required={cell.required}
        marginLeft="5px"
        marginBottom="0px"
      />
    ),
  }));

  return (
    <StyledStack sx={{ backgroundColor: "hubColors.greyLighter" }}>
      {ITEMS?.map((i) => i.label)}
    </StyledStack>
  );
};

export default DipsTableHeader;

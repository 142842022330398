import { styled } from "@mui/material/styles";
import { Stack } from "@mui/material";

import { HubButton } from "../inputs";

export const StyledHubButton = styled(HubButton)({
  minWidth: "10px",
  height: "34.81px",
  borderRadius: "10px",
});

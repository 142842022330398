import React from "react";
import { Stack } from "@mui/material";

import { HubButton } from "../../components";

const LaborButtonBar = ({ disabled, isLoading }) => {
  return (
    <Stack direction="row" justifyContent="center" gap="10px">
      <HubButton
        id="save"
        variant="outlined"
        type="submit"
        size="small"
        label="Save Ticket"
        disabled={disabled}
        isLoading={isLoading}
      />
      <HubButton
        id="submit"
        variant="contained"
        type="submit"
        size="small"
        label="Close Ticket"
        disabled={disabled}
        isLoading={isLoading}
      />
    </Stack>
  );
};

export default LaborButtonBar;

import React from "react";

import { HubIcon } from "../HubIcon";
import { StyledPercentStack, StyledPercentText } from "./styles";

const getFlow = (rate) =>
  rate < 0 ? "redMain" : rate > 10 ? "greenMain" : "yellowMain";
const getIcon = (rate) =>
  rate < 0 ? "TrendingDown" : rate > 0 ? "TrendingUp" : false;

export const DataRateChange = ({
  rate,
  label = "Since Last Month",
  hideLabel = false,
}) => {
  return !isNaN(rate) ? (
    <StyledPercentStack color={getFlow(rate)}>
      {getIcon(rate) && <HubIcon icon={getIcon(rate)} size={12} />}
      <StyledPercentText color={getFlow(rate)} heavy>
        {rate} %
      </StyledPercentText>
      {!hideLabel && <StyledPercentText>{label}</StyledPercentText>}
    </StyledPercentStack>
  ) : (
    <StyledPercentText>No percent changes</StyledPercentText>
  );
};

export default DataRateChange;

import { styled } from "@mui/material/styles";
import FormControl from "@mui/material/FormControl";
import { HubButton } from "../inputs";

export const StyledFormControl = styled(FormControl)(({ hidden }) => ({
  ...(hidden && {
    display: "none",
  }),
  "& .MuiFormGroup-root": {
    gap: "3px",
  },
  "& .MuiFormControlLabel-root": {
    margin: 0,
    gap: "7px",
  },
  "& .MuiCheckbox-root, & .MuiRadio-root": {
    padding: 0,
  },
  "& .MuiSvgIcon-root": {
    width: "22px",
    height: "22px",
  },
}));

export const StyledFormButton = styled(HubButton, {
  shouldForwardProp: (prop) => prop !== "selected",
})(({ theme, selected }) => ({
  minWidth: "33px",
  padding: "6px",
  transition: "transform 0.3s ease-in-out",
  "&:hover": {
    transform: "scale(1.075)",
  },
  ...(!selected && {
    backgroundColor: theme.palette.hubColors.greyLight,
  }),
}));

import { styled } from "@mui/material/styles";
import { Stack } from "@mui/material";
import { HubButton } from "../../inputs";

export const StyledNavStack = styled(Stack)(({ theme }) => ({
  justifyContent: "space-between",
  gap: "15px",
  padding: "10px",
  borderRight: "1px solid",
  borderColor: theme.palette.hubColors.greyLight,
  backgroundColor: theme.palette.hubColors.white,
}));

export const StyledNavButton = styled(HubButton, {
  shouldForwardProp: (prop) => !["active"].includes(prop),
})(({ active, theme }) => ({
  fontSize: 12,
  textWrap: "nowrap",
  justifyContent: "start",
  padding: "5px 15px",
  width: "100%",
  "& .MuiStack-root": {
    gap: "10px",
  },
  borderRadius: "5px",
  color: theme.palette.hubColors.greyShade,
  ":hover": {
    backgroundColor: `${theme.palette.hubColors.greyLighter} !important`,
  },
  ...(active && {
    backgroundColor: theme.palette.hubColors.mainFocus,
    color: theme.palette.hubColors.fontWhite,
    ":hover": {
      backgroundColor: `${theme.palette.hubColors.mainFocusDark} !important`,
    },
  }),
}));

import React from "react";

import { HubTableHeader } from "../../components";

const sortable = true;

const headCells = [
  {
    id: "part",
    label: "Part",
  },
  {
    id: "status",
    label: "Status",
  },
  {
    id: "treeName",
    label: "Tree Name",
    sortable,
  },
  // {
  //   id: "lbsp",
  //   label: "lbs / Part",
  // },
  // {
  //   id: "ppt",
  //   label: "Parts / Tree",
  // },
  {
    id: "alloy",
    label: "Alloy",
  },
  {
    id: "lastSentPrice",
    label: "Last Quoted Price",
    sortable,
  },
];

export const CustomerPartsHeader = () => {
  return (
    <HubTableHeader
      items={headCells}
      includeCheckbox={false}
      sx={{ backgroundColor: "hubColors.greyLighter" }}
    />
  );
};

export default CustomerPartsHeader;

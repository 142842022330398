import React from "react";
import { Typography } from "@mui/material";

import WorkOrderLabel from "./WorkOrderLabel";
import { StyledBaseBox } from "./styles";
import { ROUTING_OPTIONS } from "../../constants";

export const WorkOrderOutsideAndShipping = ({ data }) => {
  let post = data?.part?.postFoundryInstructions;
  let outside = post?.postFoundryRouting
    ?.filter((o) => o.isOutside)
    ?.map((p) => ({
      id: p?.process?.id,
      name: p?.process?.name,
      notes: p?.notes,
      partner: p?.partner?.name,
    }));

  const OPTIONS = ROUTING_OPTIONS?.filter((p) => p.id > 7 && p.isOutside);

  return (
    <StyledBaseBox
      columns={5}
      disableBorder={true}
      paddingTop="0px !important"
      mt="auto"
    >
      <Typography variant="boldCaptionMain" gridColumn="span 3">
        Outside Processing
      </Typography>

      <WorkOrderLabel label="Test Bars Req" />
      <WorkOrderLabel label="Certs Req" />

      {outside?.map(({ name, id, notes }) => {
        const op = OPTIONS?.find((o) => o.id === id);
        const poNoRec = op?.reqPO && !op?.reqRec;
        const noElse = !op?.reqPO && !op?.reqRec;
        const noPO = !op?.reqPO && op?.reqRec;

        return (
          <>
            <WorkOrderLabel
              label={name}
              value={notes}
              valueBold
              c1="auto"
              gridColumn="span 2"
              {...(poNoRec || noPO ? { gridColumn: "span 3" } : {})}
              {...(noElse ? { gridColumn: "span 4" } : {})}
            />
            {op?.reqPO ? (
              <WorkOrderLabel
                label="PO #"
                underlined
                {...(!op?.reqRec ? { gridColumn: "span 2" } : {})}
              />
            ) : (
              <></>
            )}
            {op?.reqRec ? (
              <>
                <WorkOrderLabel label="Qty Sent" underlined />
                <WorkOrderLabel label="Qty Rec." underlined />
              </>
            ) : (
              <></>
            )}
          </>
        );
      })}

      {/* <WorkOrderLabel label="Inst." gridColumn="span 2" />
      <WorkOrderLabel
        label="Process"
        value="Stress Relieve"
        gridColumn="span 2"
      />

      <WorkOrderLabel label="Vendor" gridColumn="span 2" />

      <WorkOrderLabel label="Mech Testing" />
      <WorkOrderLabel label="PO #" underlined />
      <WorkOrderLabel label="Machining PO #" underlined gridColumn="span 2" />

      <WorkOrderLabel label="Chem Testing" />
      <WorkOrderLabel label="PO #" underlined />
      <WorkOrderLabel label="Qty Sent" underlined />
      <WorkOrderLabel label="Qty Rec." underlined />

      <WorkOrderLabel label="Heat PO #" underlined />
      <WorkOrderLabel label="Date" underlined />
      <div style={{ gridColumn: "span 2", height: "10px" }} />

      <WorkOrderLabel label="Qty Sent" underlined />
      <WorkOrderLabel label="Qty Rec." underlined /> */}
    </StyledBaseBox>
  );
};

import React, { useEffect } from "react";
import { Box, Divider, Stack, Typography } from "@mui/material";
import { connect } from "react-redux";

import {
  usePostSignIn,
  usePostSignUp,
} from "../../hooks/react-query/useAccountData";
import { setUser } from "../../redux/auth";

import {
  HubButton,
  HubIcon,
  HubHookFormInput,
  HubHookFormWrapper,
  UserEmailInput,
} from "../../components";
import { StyledStackThree } from "./style.js";
import { useHubSnackBar } from "../../hooks";
import { useNavigate } from "react-router-dom";

const SignInForm = ({ _setUser = () => {}, signUp = false }) => {
  const {
    mutate: postSignInData,
    isSuccess: isSuccessSignIn,
    isLoading: isLoadingSignIn,
    data,
  } = usePostSignIn();
  const {
    mutate: postSignUpData,
    isSuccess: isSuccessSignUp,
    isLoading: isLoadingSignUp,
  } = usePostSignUp();

  const { addSnackbarSuccess } = useHubSnackBar();
  const navigate = useNavigate();

  useEffect(() => {
    if (isSuccessSignIn || isSuccessSignUp) {
      addSnackbarSuccess({
        message: `Sign ${signUp ? "Up" : "In"} Successful`,
      });
      navigate("/models");
    }
  }, [isSuccessSignIn, isSuccessSignUp]);

  const onSubmit = (formData) => {
    (signUp ? postSignUpData : postSignInData)(formData);
  };

  const title = signUp ? "Create a Foundry Account" : "Sign In to Foundry";
  const subtitle = signUp
    ? "Lets get you started"
    : "Please enter your account details below";

  const isLoading = isLoadingSignIn || isLoadingSignUp;

  return (
    <HubHookFormWrapper onSubmit={onSubmit}>
      <StyledStackThree>
        <Box gap="10px" width="100%">
          <Typography variant="h3">{title}</Typography>
          <Typography variant="caption">{subtitle}</Typography>
        </Box>
        <Stack gap="15px" width="100%">
          {signUp ? (
            <>
              <HubHookFormInput
                name="name"
                label="Full Name"
                placeholder="Full Name"
                required
                disableHelperText
                smallLabel
                startAdornment={<HubIcon icon="User" />}
              />
              <UserEmailInput />
            </>
          ) : (
            <HubHookFormInput
              name="email"
              label="Email"
              placeholder="Email Address"
              startAdornment={<HubIcon icon="Mail" />}
              required
              disableHelperText
              isEmail
              smallLabel
            />
          )}
          <HubHookFormInput
            name="password"
            type="password"
            label="Password"
            placeholder="Password"
            startAdornment={<HubIcon icon="Lock" />}
            disableHelperText
            required
            smallLabel
          />
        </Stack>
        <Stack gap="10px" width="100%">
          <HubButton
            loading={isLoading}
            variant="contained"
            type="submit"
            label="Sign In"
            removePreventDefault
          />
        </Stack>
        <Divider orientation="horizontal" flexItem />
        {signUp ? (
          <Typography variant="body1">
            Already have an account?{" "}
            <HubButton
              disabled={isLoading}
              variant="text"
              page="/signin"
              sx={{ padding: 0 }}
              label="Sign In"
            />
          </Typography>
        ) : (
          <Typography variant="body1">
            Dont have an account?{" "}
            <HubButton
              disabled={isLoading}
              variant="text"
              page="/signup"
              sx={{ padding: 0 }}
              label="Sign Up"
            />
          </Typography>
        )}
      </StyledStackThree>
    </HubHookFormWrapper>
  );
};

const mapStateToProps = () => ({});
const mapDispatchToProps = {
  _setUser: setUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(SignInForm);

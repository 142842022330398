import React from "react";

import { HubDialogWrapper } from "../HubDialogWrapper";
import CreateLaborContent from "./CreateLaborContent";

export const CreateLaborDialog = ({ workOrder, setWorkOrder = () => {} }) => {
  return (
    <HubDialogWrapper
      maxWidth="sm"
      name="create-labor-ticket"
      customOnClose={() => setWorkOrder(null)}
    >
      <CreateLaborContent workOrder={workOrder} setWorkOrder={setWorkOrder} />
    </HubDialogWrapper>
  );
};

export default CreateLaborDialog;

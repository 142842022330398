import { styled } from "@mui/material/styles";
import { HubHookFormDatePicker, HubHookFormSelect } from "../../components";

export const StyledHubHookFormDatePicker = styled(HubHookFormDatePicker)(
  ({ theme }) => ({
    maxWidth: 120,
    "& .MuiInputBase-root": {
      padding: "5px 10px",
      borderRadius: 6,
    },
    fieldset: {
      //   borderWidth: 0,
    },
  })
);

export const StyledHubHookFormSelect = styled(HubHookFormSelect)(
  ({ theme }) => ({
    width: 100,
    padding: "5px 10px",
    borderRadius: 6,
    svg: {
      width: 12,
      height: 12,
      margin: "5px 5px 0px 0px",
    },
  })
);

export const StyledDisabledDatePicker = styled(HubHookFormDatePicker)(
  ({ theme }) => ({
    // width: "150px",
    "& .MuiInputBase-root": {
      cursor: "not-allowed",
      backgroundColor: theme.palette.hubColors.greyLighter,
      input: {
        cursor: "not-allowed",
        backgroundColor: theme.palette.hubColors.greyLighter,
        "-webkitTextFillColor": theme.palette.hubColors.greyDark,
      },
    },
  })
);

import React, { useEffect } from "react";
import { TableBody } from "@mui/material";

import { JOB_STATUSES } from "../../constants";
import OrderLineItemRow from "./OrderLineItemRow";
import { useFormContext } from "react-hook-form";

const OrderLineItemsBody = ({ items = [], part = {} }) => {
  const { setValue, register, watch } = useFormContext();

  useEffect(() => {
    register("deletedWorkOrders");
    setValue("deletedWorkOrders", []);
  }, [register]);

  const deletedValues = watch("deletedWorkOrders") || [];
  const updatedItems = watch("updatedWorkOrders") || [];
  const itemPayload = { part, deletedValues, updatedItems };

  return (
    <TableBody>
      {items?.map((item, idx) => {
        const disabled = item?.state != JOB_STATUSES.NOT_STARTED && item?.state;
        const payload = { item, idx, disabled, ...itemPayload };
        return <OrderLineItemRow key={JSON.stringify(item)} {...payload} />;
      })}
    </TableBody>
  );
};

export default OrderLineItemsBody;

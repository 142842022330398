import React from "react";
import { HubTableTemplate } from "../../components";
import { useNavigate } from "react-router-dom";

const sortable = true;
const items = [
  {
    id: "partNumber",
    label: "Part Number",
    sortable,
  },
  {
    id: "partName",
    label: "Part Name",
    sortable,
  },
  {
    id: "lastSentPrice",
    label: "Price",
    sortable,
  },
  {
    id: "materialName",
    label: "Material",
  },
  {
    id: "treeName",
    label: "Tree",
  },
  {
    id: "weight",
    label: "Weight",
  },
  {
    id: "partsPerTree",
    label: "PPT",
  },
];

const keys = [
  {
    value: "partNumber",
  },
  {
    value: "partName",
  },
  {
    value: "lastSentPrice",
    isCurrency: true,
    defaultValue: 0,
  },
  {
    value: "material.name",
  },
  {
    value: "tree.name",
  },
  {
    value: "weight",
  },
  {
    value: "partsPerTree",
  },
];

export const PartsTable = ({ data }) => {
  const navigate = useNavigate();

  const onRowClick = (e, item) => {
    if (item?.id) {
      e.preventDefault();
      e.stopPropagation();
      navigate(`/parts/${item.id}`);
    }
  };
  const headerProps = { items };
  const bodyProps = { items: data?.rows, keys, onRowClick };
  const props = { headerProps, bodyProps, meta: data?.meta };

  return <HubTableTemplate id="LaborTable" {...props} />;
};

export default PartsTable;

import React from "react";
import { Divider, Stack, Typography } from "@mui/material";
import { connect } from "react-redux";

import { FOUNDRIES } from "../../constants/industry";
import {
  HubHookFormSelect,
  HubHookFormInput,
  HubButton,
  HubHookFormSwitch,
} from "../../components";
import {
  getCustomerData,
  getCustomerDataAddresses,
} from "../../redux/customerData";
import { SHIPPING } from "../../constants";

const getAddressLabel = (add) => {
  const addressString = `${add?.street}, ${add?.city} ${add?.state}, ${add?.country} ${add?.zip}`;

  const type =
    add.billing && add.shipping
      ? "Main"
      : add.billing
      ? "Billing"
      : add.shipping
      ? "Shipping"
      : "General";

  return (
    <Stack>
      <Stack direction="row" gap="5px" alignItems="center">
        <Typography variant="boldCaption">{type}</Typography>
        {add?.deleted && (
          <Typography variant="boldCaptionMain">Archived</Typography>
        )}
      </Stack>
      <Typography variant="caption">{addressString}</Typography>
    </Stack>
  );
};

const PoDetails = ({ customer, isLoading = false, addresses = [] }) => {
  const location = FOUNDRIES?.find((f) => customer?.location === f?.label)?.id;

  const addressOptions = addresses
    ?.filter((a) => a?.id)
    ?.map((add) => ({
      id: add.id,
      label: getAddressLabel(add),
    }));

  return (
    <Stack gap="15px">
      <Typography sx={{ fontWeight: 600 }}>Order Details</Typography>
      <HubHookFormInput
        name="company"
        label="Company"
        placeholder="ex. Winco"
        defaultValue={customer?.customer}
        disableHelperText
        disabled
        required
      />
      <Stack
        gap="10px"
        sx={{ flexDirection: { sm: "column", md: "row" } }}
        alignItems="center"
      >
        <HubHookFormInput
          name="poNumber"
          label="Customer PO#"
          placeholder="Order #"
          disableHelperText
          required
        />
        <HubHookFormSelect
          name="location"
          label="Foundry"
          defaultValue={location}
          options={FOUNDRIES}
          disableHelperText
          required
        />
        <HubHookFormSwitch
          name="overShipping"
          label="Over Shipping"
          disableHelperText
          isCheckbox
        />
      </Stack>
      <Divider
        orientation="horizontal"
        flexItem
        sx={{ border: "1px dashed", borderColor: "hubColors.greyLight" }}
      />
      <Stack gap="15px">
        <Typography variant="body2">Shipping</Typography>
        <Stack gap="10px" sx={{ flexDirection: { sm: "column", md: "row" } }}>
          <HubHookFormInput
            name="freight"
            label="Freight"
            placeholder="X"
            disableHelperText
            required
          />
          <HubHookFormSelect
            name="shipVia"
            label="Ship Via"
            options={SHIPPING}
            disableHelperText
            required
          />
        </Stack>
        <HubHookFormSelect
          name="shipTo"
          label="Ship To"
          options={addressOptions}
          disableHelperText
          required
        />
      </Stack>
      <HubButton
        variant="contained"
        size="small"
        label="Create Purchase Order"
        disabled={isLoading}
        type="submit"
      />
    </Stack>
  );
};

const mapStateToProps = (state) => ({
  customer: getCustomerData(state),
  addresses: getCustomerDataAddresses(state),
});

export default connect(mapStateToProps)(PoDetails);

import React from "react";
import { Stack, Table, TableContainer } from "@mui/material";
import PostFoundryTableHeader from "./PostFoundryTableHeader";
import PostFoundryTableBody from "./PostFoundryTableBody";

export const PostFoundryTable = ({ ...rest }) => {
  return (
    <Stack
      id="PostFoundryTable"
      border="1px solid"
      borderColor="hubColors.greyLight"
    >
      <PostFoundryTableHeader />
      <PostFoundryTableBody {...rest} />
    </Stack>
  );
};

export default PostFoundryTable;

import React from "react";
import { Stack } from "@mui/material";

import { OrderLineItemsTableV2 } from "../../tables";

export const OrderList = ({ setLine = () => {} }) => {
  return (
    <Stack gap="15px" width="100%" padding="15px" overflow="hidden">
      <OrderLineItemsTableV2 setLine={setLine} />
    </Stack>
  );
};
export default OrderList;

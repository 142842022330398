import React, { useEffect } from "react";
import { Stack, Typography } from "@mui/material";
import dayjs from "dayjs";

import {
  HubCancelSubmitGroup,
  HubDialogContent,
  HubHookFormWrapper,
} from "../../components";
import AddWorkOrderForm from "./AddWorkOrderForm";
import { useGetOrderLineItemDetails, usePostWorkOrderBatchesForLine } from "../../hooks";

export const AddWorkOrderContent = ({ lineId }) => {
  const { data, isLoading } = useGetOrderLineItemDetails(lineId);
  const { mutate: postWorkOrderBatchesForLine, isSuccess } = usePostWorkOrderBatchesForLine();

  useEffect(() => {
    isSuccess && window.location.reload();;
  }, [isSuccess]);

  const onSubmit = (formData) => {
    const payload = {
      lineId,
      quantity: Number(formData.quantity),
      batches: Number(formData.batches),
      repeatOption: Number(formData.repeatOption),
      repeatStartDate: dayjs(formData.repeatStartDate).utc().toISOString(),
      repeatWeekdays: formData?.repeatWeekdays,
      repeatEveryIteration: formData?.repeatEveryIteration,
      repeatEvery: formData.repeatEvery,
    };
    postWorkOrderBatchesForLine(payload)
  };

  return (
    <HubDialogContent>
      <HubHookFormWrapper onSubmit={onSubmit}>
        <Stack gap="25px">
          <Stack alignItems="start">
            <Typography variant="h6">
              <strong>{data?.lineNumber}</strong> : Add Work Orders
            </Typography>
            <Stack>
              <Typography variant="caption" noWrap>
                Add Work Orders to Existing Purchase Order Line Items for:
              </Typography>
              <Typography variant="boldCaptionMain" noWrap>
                {data?.partName}
              </Typography>
            </Stack>
          </Stack>

          <AddWorkOrderForm
            totalDays={data?.totalDays}
            customerDueDate={data?.dueDate}
          />
          <HubCancelSubmitGroup
            disabled={isLoading || !data?.id}
            isLoading={isLoading}
            label="Create Work Orders"
            disableOnInvalid
          />
        </Stack>
      </HubHookFormWrapper>
    </HubDialogContent>
  );
};

export default AddWorkOrderContent;

import React from "react";
import { HubHookFormSelect } from "../../components";
import { Chip, Stack, Typography } from "@mui/material";
import { PART_STATUSES } from "../../constants";

const sxChip = {
  fontSize: "10px !important",
  fontWeight: 400,
  height: 20,
  span: { padding: 0 },
  padding: "5px",
};

const OrderPartSelect = ({ ...rest }) => {
  return (
    <HubHookFormSelect
      RenderComponent={({ label, status }) => {
        const partStatus = PART_STATUSES.find((p) => p.id === (status || 2));
        return (
          <Stack direction="row" gap="10px" alignItems="center">
            <Typography
              sx={{
                fontSize: "12px !important",
                fontWeight: 500,
              }}
            >
              {label}
            </Typography>
            <Chip sx={sxChip} {...partStatus} variant="colorTag" />
          </Stack>
        );
      }}
      sx={{
        "& .MuiSelect-select": {
          "& .MuiTypography-root": {
            fontWeight: 400,
          },
        },
        "& .MuiChip-root": {
          display: "none",
        },
      }}
      {...rest}
    />
  );
};

export default OrderPartSelect;

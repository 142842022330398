import Cookies from "js-cookie";
import { createSelector } from "reselect";

const LOAD_USER = "LOAD_USER";
const SET_VIEW_MODE = "SET_VIEW_MODE";

const darkMode = localStorage && localStorage.getItem("Dark-Mode");
const initialState = {
  user: null,
  darkMode: darkMode === "true",
};

export const setLocalMode = (mode) => {
  localStorage && localStorage.setItem("Dark-Mode", mode);
};

export const logout = () => (dispatch) => {
  Cookies.remove("authToken");
  dispatch({ type: "FLUSH" });
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case "FLUSH": {
      return initialState;
    }

    case LOAD_USER: {
      const newState = {
        ...state,
        user: action?.data,
      };
      return newState;
    }

    case SET_VIEW_MODE: {
      const newState = {
        ...state,
        darkMode: action?.payload,
      };
      setLocalMode(action?.payload);
      return newState;
    }

    default:
      return state;
  }
}

export function setViewMode(payload) {
  return {
    type: SET_VIEW_MODE,
    payload,
  };
}

export function setUser(data) {
  return {
    type: LOAD_USER,
    data,
  };
}

export const getAuthState = createSelector(
  (state) => state,
  (state) => state?.auth
);

export const getAuthUser = createSelector(
  getAuthState,
  (authState) => authState.user
);
export const getUserRole = createSelector(getAuthUser, (user) => user?.role);
export const getUserLocation = createSelector(
  getAuthUser,
  (user) => user?.location
);

export const getViewMode = createSelector(
  getAuthState,
  (authState) => authState.darkMode
);

export const getQueryParams = ({ prefix = "", defaultOrderBy = "name", query }) => {
  const search = query || new URLSearchParams(window.location.search);

  const orderBy = search.get(`${prefix}orderBy`) || defaultOrderBy;
  const sortOrder = search.get(`${prefix}sortOrder`) || "asc";
  const limit = search.get(`${prefix}limit`) || 100;
  const offset = search.get(`${prefix}offset`) || 0;

  const queryProps = {
    limit,
    offset,
    orderBy,
    sortOrder,
  };

  return queryProps;
};

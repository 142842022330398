import React, { useState, useRef, useEffect } from "react";
import { Stack } from "@mui/material";

import { HubHookFormWrapper, HubIcon } from "../../";
import { useGetSearchData } from "../../../hooks";
import SearchResults from "./SearchResults";
import { StyledHubHookFormInput } from "./styles";

const SearchNavBar = () => {
  const [value, setValue] = useState();
  const stackRef = useRef();

  const { data } = useGetSearchData(value);

  const handleClickOutside = (event) => {
    if (stackRef.current && !stackRef.current.contains(event.target)) {
      setValue();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleChange = (e) => setValue(e?.target?.value);
  const onClose = () => setValue("");

  return (
    <Stack ref={stackRef} sx={{ flexGrow: 1, width: "auto" }}>
      <HubHookFormWrapper sx={{ position: "relative" }} onSubmit={() => {}}>
        <StyledHubHookFormInput
          name="searchQuery"
          placeholder="Search..."
          startAdornment={<HubIcon icon="Search" />}
          defaultValue={value}
          value={value}
          onChange={handleChange}
          disableHelperText
        />
        {value && <SearchResults results={data} onClose={onClose} />}
      </HubHookFormWrapper>
    </Stack>
  );
};

export default SearchNavBar;

import React from "react";
import { StyledCard } from "./styles";
import { HubCircularProgress } from "../HubCircularProgress";

export const HubCardStack = ({ isLoading, children, ...rest }) => {
  return (
    <StyledCard padding="25px" gap="5px" borderRadius="10px" {...rest}>
      {isLoading ? <HubCircularProgress centerScreen /> : children}
    </StyledCard>
  );
};

import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";

export const StyledStack = styled(Stack)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "50px repeat(4, 1fr) 25px",
  alignItems: "center",
  borderBottom: "1px solid",
  borderColor: theme.palette.hubColors.greyLight,
  padding: "8px",
  gap: "15px",
  backgroundColor: theme.palette.hubColors.white,
}));

import React from "react";
import { Stack, Typography, Box } from "@mui/material";

import { EmptyWorkOrder } from "../../svgs";

export const Empty = () => {
  return (
    <Stack gap="10px" justifyContent="center" alignItems="center" m="auto">
      <Box component="img" src={EmptyWorkOrder} width="200px" height="200px" />
      <Stack justifyContent="center" alignItems="center">
        <Typography variant="boldCaption">No Results</Typography>
        <Typography variant="caption">
          Could not find work orders for search
        </Typography>
      </Stack>
    </Stack>
  );
};

export default Empty;

import { FormControl } from "@mui/material";
import { styled } from "@mui/material/styles";

export const inputLabelStyles = {
  fontSize: "16px",
  color: "vpColors.black",
  position: "relative",
  fontWeight: 500,
  left: "-14px",
  top: "-10px",
  "&.Mui-focused": {
    color: "vpColors.black",
  },
};

export const StyledFormControl = styled(FormControl)(({ hidden }) => ({
  ...(hidden && {
    display: "none",
  }),
  "& .MuiInputBase-root": {
    height: "35.81px",
    padding: "3px 65px 3px 9px !important"
  },
}));

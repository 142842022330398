import React from "react";
import { Stack, Typography } from "@mui/material";

import {
  HubHookFormButtonGroup,
  HubHookFormInput,
  HubHookFormLikertInput,
  HubHookFormSelect,
  InputLabel,
} from "../../components";
import { useFormContext } from "react-hook-form";

const RepeatOptions = [
  {
    id: 1,
    label: "Week",
  },
  {
    id: 2,
    label: "Month",
  },
  {
    id: 3,
    label: "Year",
  },
];

const weekOptions = ["S", "M", "T", "W", "T", "F", "S"];
const AddWorkOrderRepeatCustom = () => {
  const { watch } = useFormContext();
  const repeatOption = watch("repeatOption");
  const showCustom = repeatOption === 4;

  if (!showCustom) return <></>;
  return (
    <Stack gap="15px">
      <HubHookFormButtonGroup
        name="repeatWeekdays"
        label="Repeat On"
        required
        options={weekOptions.map((label, i) => ({ id: i, label }))}
        multiselect
      />
      <Stack direction="row" gap="10px" alignItems="center">
        <InputLabel label="Repeat Every" required disableMargin />
        <HubHookFormInput
          name="repeatEveryIteration"
          required
          isNumber
          defaultValue={1}
          fullWidth={false}
          sx={{ width: "100px" }}
        />
        <HubHookFormSelect
          name="repeatEvery"
          options={RepeatOptions}
          fullWidth={false}
          sx={{ width: "100px" }}
          required
        />
      </Stack>
    </Stack>
  );
};

export default AddWorkOrderRepeatCustom;

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import {
  useGetWorkOrderData,
  usePutAdminCloseWorkOrder,
  usePutAdminUpdateWorkOrder,
} from "../../hooks";
import { ConfirmWorkOrderStation } from "../../dialogs";
import { HubHookFormWrapper, PageContent } from "../../components";

import WorkOrderMain from "./WorkOrderMain";
import WorkOrderBar from "./WorkOrderBar";
import WorkOrderNav from "./WorkOrderNav";

import { StyleDisplayStack } from "./styles";
import { tabs } from "./helper";
import { WorkOrder } from "../../containers";

const formSx = { height: "100%", overflow: "hidden" };

export const WorkOrderDetails = () => {
  const { id } = useParams();

  const { data, isLoading, refetch } = useGetWorkOrderData(id);
  const { mutate: putAdminUpdateWorkOrder, isSuccess: isUpdateSuccess } =
    usePutAdminUpdateWorkOrder();
  const { mutate: putAdminCloseWorkOrder, isSuccess: isCloseSuccess } =
    usePutAdminCloseWorkOrder();

  const [payload, setPayload] = useState();
  const [isClosing, setIsClosing] = useState();
  const [value, setValue] = useState(1);

  const isSuccess = isUpdateSuccess || isCloseSuccess;

  useEffect(() => {
    refetch();
  }, [id]);

  useEffect(() => {
    onClose();
  }, [isSuccess, isUpdateSuccess, isCloseSuccess]);

  const onFormSubmit = (formData) => {
    const updatePayload = {
      status: formData?.status,
    };
    const option = data?.routingOptions?.find((r) => r.id === formData.routing);
    updatePayload.route = option;

    if (option.id !== data?.currentRoute) updatePayload.changedRoute = true;
    setPayload(updatePayload);
  };

  const onClose = () => setPayload(null);
  const handleCloseWorkOrder = () => {
    setIsClosing(true);
    setPayload(true);
  };

  const onConfirm = () => {
    if (isClosing) {
      putAdminCloseWorkOrder(id);
      return;
    }

    const updatePayload = { id, status: payload?.status };
    if (payload?.changedRoute) updatePayload.routeId = payload?.route?.id;

    putAdminUpdateWorkOrder(updatePayload);
  };

  const DispalyTab = tabs.find((t) => t.id === value)?.TabElement || <></>;

  return (
    <PageContent gap="15px" isLoading={isLoading} disablePadding key={id}>
      {data && (
        <HubHookFormWrapper onSubmit={onFormSubmit} sx={formSx}>
          <WorkOrderBar
            workOrder={data?.workOrder}
            onCloseWorkOrder={handleCloseWorkOrder}
            status={data?.status}
            state={data?.state}
          />
          <StyleDisplayStack>
            <WorkOrderNav {...{ value, setValue }} />
            <DispalyTab id={id} />
            <WorkOrderMain />
          </StyleDisplayStack>
        </HubHookFormWrapper>
      )}
      <WorkOrder id={id} sx={{ position: "absolute", left: "-9999px" }} />
      <ConfirmWorkOrderStation
        isOpen={!!payload}
        isClosing={isClosing}
        onClose={onClose}
        onSubmit={onConfirm}
        payload={payload}
      />
    </PageContent>
  );
};

export default WorkOrderDetails;

import React from "react";
import { TableCell, TableRow } from "@mui/material";
import { connect } from "react-redux";

import { DIP_STATIONS, DIP_INGREDIENTS as DIPS } from "../../constants";
import {
  HubButton,
  HubHookFormInput,
  HubHookFormSelect,
  HubHookFormSwitch,
  HubHookFormTimePicker,
  HubMaterialHookSelect,
} from "../../components";
import { StyledStack } from "./styles";

const TableRowSx = {
  justifyItems: "center",
  ".DipRowButton": {
    display: "none",
  },
  "&:hover": {
    ".DipRowButton": {
      display: "inline",
    },
  },
};

const DipsTableRow = ({ idx = 0, dip = {}, remove = () => {}, ...rest }) => {
  const preset = `dip.dips[${idx}]`;

  return (
    <StyledStack sx={TableRowSx} {...rest}>
      <HubHookFormSwitch
        name={`${preset}.prewet`}
        key={`${preset}.prewet`}
        isCheckbox
      />
      <HubMaterialHookSelect
        defaultValue={dip?.materialId || DIPS[0].id}
        name={`${preset}.materialId`}
        key={`${preset}.materialId`}
        hideLabel
        required
      />
      <HubHookFormTimePicker
        name={`${preset}.cycleTime`}
        key={`${preset}.cycleTime`}
        defaultValue={dip?.cycleTime}
        required
      />
      <HubHookFormTimePicker
        name={`${preset}.leadTime`}
        key={`${preset}.leadTime`}
        defaultValue={dip?.leadTime || 1442}
        onlyDays
        required
      />
      <HubHookFormSelect
        name={`${preset}.stationId`}
        key={`${preset}.stationId`}
        defaultValue={dip?.stationId}
        options={DIP_STATIONS}
        required
      />
      <HubButton
        className="DipRowButton"
        icon="Trash2"
        variant="text"
        iconProps={{ size: 14 }}
        sx={{ color: "hubColors.redMain" }}
        onClick={() => remove(idx)}
      />
    </StyledStack>
  );
};

export default DipsTableRow;

import React from "react";

import { HubTableHeader } from "../../components";

const sortable = true;

const headCells = [
  {
    id: "active",
    label: "Status",
  },
  {
    id: "name",
    label: "Customer",
    sortable,
  },
  {
    id: "contact",
    label: "Primary Contact",
  },
  {
    id: "address",
    label: "Main Address",
  },
  {
    id: "phone",
    label: "Phone Number",
  },
  // {
  //   id: "industry",
  //   label: "Industry",
  // },
  // {
  //   id: "priority",
  //   label: "Priority",
  //   sortable,
  // },
  {
    id: "revenue",
    label: "Total Revenue",
    sortable,
  },
  {
    id: "location",
    label: "Foundry",
    sortable,
  },
];

export const CompaniesHeader = () => {
  return (
    <HubTableHeader
      items={headCells}
      includeCheckbox={false}
      sx={{ backgroundColor: "hubColors.greyLighter" }}
    />
  );
};

export default CompaniesHeader;

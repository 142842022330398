import React from "react";
import { HubFilterMenu } from "../../components";

const types = [
  {
    id: "MRO",
    label: "MRO",
  },
  {
    id: "RECIPE",
    label: "RECIPE",
  },
  {
    id: "RAW",
    label: "RAW MATERIAL",
  },
  {
    id: "SAND",
    label: "SAND",
  },
  {
    id: "CASTING",
    label: "CASTING",
  },
  {
    id: "CUP",
    label: "CUP",
  },
];

const MaterialFilters = ({ ...rest }) => {
  return (
    <HubFilterMenu
      id="materialtype"
      options={types}
      menuLabel="Filter Materials by Type"
      label="Material Type"
      useDefault={false}
      {...rest}
    />
  );
};

export default MaterialFilters
import { styled } from "@mui/material/styles";
import { Stack } from "@mui/material";
import { HubButton } from "../../inputs";
import HubContextMenuItem from "../../HubContextMenu/HubContextMenuItem";
import { HubHookFormInput } from "../../HubHookFormInput";

export const StylesHubButton = styled(HubButton)(({ theme }) => ({
  padding: "2px",
  svg: {
    width: "15px",
    height: "15px",
  },
}));

export const StyledHubButton = styled(HubButton)(({ theme }) => ({
  padding: "5px 12px",
  fontSize: 11,
  // color: theme.palette.hubColors.fontWhite,
  width: "77px",
  borderRadius: "3px",
  textWrap: "nowrap",
}));

export const StyledTopNavBar = styled(Stack)(({ theme }) => ({
  flexDirection: "row",
  gap: "45px",
  width: "100%",
  padding: "10px 20px",
  boxSizing: "border-box",
  position: "relative",
  justifyContent: "space-between",
  alignItems: "center",
  borderBottom: "1px solid",
  borderColor: theme.palette.hubColors.greyLight,
  backgroundColor: theme.palette.hubColors.white,
}));

export const ParentStack = styled(Stack)({
  flexDirection: "row",
  alignItems: "center",
});

//MuiButtonBase-root
export const StyledMenu = styled(Stack)(({ theme }) => ({
  position: "absolute",
  marginTop: "10px",
  zIndex: 100,
  backgroundColor: theme.palette.hubColors.white,
  border: "1px solid",
  borderColor: theme.palette.hubColors.greyLight,
  boxShadow: "0px 2px 15px rgba(0,0,0,0.1) !important",
  borderRadius: "10px",
  minHeight: "250px",
  maxHeight: "500px",
  minWidth: "270px",
  width: "100%",
  overflowY: "auto",
  "&::-webkit-scrollbar": { display: "none !important" },
}));

export const StyledMenuItem = styled(HubContextMenuItem, {
  shouldForwardProp: (prop) => prop !== "color",
})(({ theme, color }) => ({
  "&.MuiTypography-root": {
    color:
      theme.palette.hubColors[color] ||
      color ||
      theme.palette.hubColors.darkGray1,
    fontSize: "10px important",
  },
  svg: {
    color:
      theme.palette.hubColors[color] ||
      color ||
      theme.palette.hubColors.darkGray1,
    width: "15px",
    height: "15px",
  },
}));

export const StyledHubHookFormInput = styled(HubHookFormInput)({
  height: "29.25px",
  borderRadius: "29px",
})

import { styled } from "@mui/material/styles";
import FormControl from "@mui/material/FormControl";
import { HubIcon } from "../HubIcon";

export const StyledFormControl = styled(FormControl)(({ hidden }) => ({
  ...(hidden && {
    display: "none",
  }),
}));

export const StyledHubIcon = styled(HubIcon)(({ theme }) => ({
  color: `${theme.palette.hubColors.grey} !important`,
}));

import React from "react";
import { Stack } from "@mui/material";

import { HubHookFormInput, HubHookFormSelect } from "../../components";
import AddWorkOrderRepeat from "./AddWorkOrderRepeat";
import AddWorkOrderRepeatCustom from "./AddWorkOrderRepeatCustom";
import AddWorkOrderEndDate from "./AddWorkOrderEndDate";
import { StyledDisabledDatePicker } from "./styles";
import dayjs from "dayjs";

const RepeatOptions = [
  {
    id: 1,
    label: "Daily",
  },
  {
    id: 2,
    label: "Weekly",
  },
  {
    id: 3,
    label: "Monthly",
  },
  {
    id: 4,
    label: "Custom",
  },
];
const AddWorkOrderForm = ({ totalDays, customerDueDate }) => {
  const dueDate = dayjs(customerDueDate).utc().format("MM/DD/YYYY");

  return (
    <Stack gap="15px">
      <Stack direction="row" gap="5px">
        <HubHookFormInput
          name="quantity"
          label="Batch Quantity"
          required
          isNumber
          defaultValue={100}
        />
        <HubHookFormInput
          name="batches"
          label="# of Batches"
          required
          isNumber
          defaultValue={1}
        />
      </Stack>
      <AddWorkOrderRepeat />
      <AddWorkOrderRepeatCustom />
      <Stack direction="row" gap="5px">
        <StyledDisabledDatePicker
          name="customerDueDate"
          label="Customer Due Date"
          defaultValue={dueDate}
          required
          disabled
        />
        {totalDays && <AddWorkOrderEndDate totalDays={totalDays} />}
      </Stack>
    </Stack>
  );
};

export default AddWorkOrderForm;

import { EmployeesContainer, TreesContainer } from "../../containers";
import { CompaniesPage } from "../Companies";
import { OrdersPage } from "../Orders";
import { PartsPage } from "../PartsPage";
import { WorkOrdersPage } from "../WorkOrdersPage";

const includeLocationParam = true;

export const tabs = [
  {
    id: "1",
    label: "Customers",
    icon: "User",
    TabElement: CompaniesPage,
    defaultSearchParams: { active: true },
    includeLocationParam,
  },
  {
    id: "2",
    label: "Parts",
    icon: "Settings",
    TabElement: PartsPage,
  },
  {
    id: "3",
    label: "Orders",
    icon: "Archive",
    TabElement: OrdersPage,
  },
  {
    id: "4",
    label: "Work Orders",
    icon: "FileText",
    TabElement: WorkOrdersPage,
    includeLocationParam,
  },
  {
    id: "5",
    label: "Materials",
    icon: "Codesandbox",
    TabElement: TreesContainer,
  },
  {
    id: "6",
    label: "Employees",
    icon: "User",
    TabElement: EmployeesContainer,
    defaultSearchParams: { status: "A" },
    includeLocationParam,
  },
];

import React from "react";
import { Stack, Typography } from "@mui/material";

import { SHIPPING } from "../../constants";

import WorkOrderLabel from "./WorkOrderLabel";
import { StyledBaseBox } from "./styles";

const border = "1px solid black";

const sx = {
  variant: "boldCaption",
  textAlign: "center",
  boxSizing: "borderBox",
  height: "18px",
  borderTop: border,
  borderLeft: border,
};

const gridSx = {
  display: "grid",
  gridTemplateColumns: "repeat(3, 1fr)",
  mt: "auto",
  borderRight: border,
  borderBottom: border,
};

const fieldHeight = "24px";

export const WorkOrderBoxingInfo = ({ ...rest }) => (
  <Stack sx={gridSx} {...rest}>
    <Typography {...sx} variant="boldCaptionMain" gridColumn="span 3">
      Boxing Info
    </Typography>

    <Typography {...sx}>Box Type</Typography>
    <Typography {...sx}>Qty/Box</Typography>
    <Typography {...sx}>Initials</Typography>

    <Typography {...sx} height={fieldHeight} />
    <Typography {...sx} height={fieldHeight} />
    <Typography {...sx} height={fieldHeight} />
  </Stack>
);

export const WorkOrderShippingInfo = ({ ...rest }) => (
  <Stack sx={gridSx} {...rest}>
    <Typography {...sx} variant="boldCaptionMain" gridColumn="span 3">
      Shipping Info
    </Typography>

    <Typography {...sx}>Date</Typography>
    <Typography {...sx}>Qty</Typography>
    <Typography {...sx}>Weight (lbs)</Typography>

    <Typography {...sx} height={fieldHeight} />
    <Typography {...sx} height={fieldHeight} />
    <Typography {...sx} height={fieldHeight} />

    <Typography {...sx} height={fieldHeight} />
    <Typography {...sx} height={fieldHeight} />
    <Typography {...sx} height={fieldHeight} />
  </Stack>
);

const WorkOrderShipping = ({ data }) => {
  const shipTo = SHIPPING[data?.shipVia].label;
  const shipSx = { valueBold: true, c1: "auto" };

  return (
    <StyledBaseBox columns={2} disableBorder={true} mt="auto">
      <WorkOrderBoxingInfo />
      <WorkOrderShippingInfo />

      <Stack
        gridColumn="span 2"
        direction="row"
        mt="10px"
        justifyContent="space-between"
      >
        <WorkOrderLabel label="SHIP TO" value={data?.address} {...shipSx} />
        <WorkOrderLabel label="SHIP VIA" value={shipTo} {...shipSx} />
        <WorkOrderLabel label="Freight" value={data?.freight} {...shipSx} />
      </Stack>
    </StyledBaseBox>
  );
};

export default WorkOrderShipping;

import React from "react";
import { Stack, Typography } from "@mui/material";
import { useFormContext } from "react-hook-form";

import {
  HubHookFormInput,
  HubHookFormSwitch,
  HubHookFormTimePicker,
} from "../../../components";
import { StyledHubCardStack } from "../styles";

const gap = "10px";
const columnGap = "10px";

const PartGeneric = ({ name = "Generic", includeCyle = true }) => {
  const { getValues } = useFormContext();
  const getName = name?.toLowerCase();
  const details = getValues(getName);

  return (
    <StyledHubCardStack gap="25px">
      <Stack gap={gap}>
        <Typography variant="boldCaptionMain">{name} Details</Typography>
        <Stack gap="25px">
          <Stack direction="row" gap={columnGap}>
            <HubHookFormTimePicker
              name={`${getName}.leadTime`}
              label="Lead Time"
              placeholder="Ex. 12"
              defaultValue={details?.leadTime}
              onlyDays
              required
            />
            {includeCyle && (
              <HubHookFormTimePicker
                name={`${getName}.cycleTime`}
                label="Cycle Time"
                placeholder="Ex. 0.24"
                defaultValue={details?.cycleTime}
                required
              />
            )}
          </Stack>
        </Stack>
      </Stack>
      <Stack gap={gap}>
        <Typography variant="boldCaptionMain">{name} Notes</Typography>
        <HubHookFormInput
          name={`${getName}.notes`}
          label="Special Instructions"
          defaultValue={details?.notes}
          placeholder="Notes..."
          rows={5}
          multiline
        />
        <HubHookFormInput
          name={`${getName}.id`}
          defaultValue={details?.id}
          hidden
        />
      </Stack>
    </StyledHubCardStack>
  );
};

export default PartGeneric;

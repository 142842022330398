import React from "react";
import { Stack } from "@mui/material";

import { HubButton } from "../inputs";
import { useDialogContext } from "../../dialogs";
import { useFormContext } from "react-hook-form";

export const HubCancelSubmitGroup = ({
  disabled = false,
  isLoading = false,
  label = "Submit",
  fullWidth = false,
  overrideCancel,
  disableOnInvalid = false,
}) => {
  const sx = fullWidth
    ? { flexGrow: 1, padding: "5px" }
    : { minWidth: "120px", padding: "5px" };

  const { onClose = () => {} } = useDialogContext();
  const { formState } = useFormContext();

  const handleClose = () => {
    overrideCancel ? overrideCancel() : onClose();
  };

  return (
    <Stack direction="row" justifyContent="center" gap="10px">
      <HubButton
        variant="contained"
        color="grey"
        label="Cancel"
        size="small"
        onClick={handleClose}
        sx={sx}
        disabled={disabled}
      />
      <HubButton
        id="ModalDisplaySubmit"
        variant="contained"
        type="submit"
        size="small"
        label={label}
        sx={sx}
        disabled={disabled || (disableOnInvalid && !formState.isValid)}
        isLoading={isLoading}
      />
    </Stack>
  );
};

export default HubCancelSubmitGroup;

import React from "react";
// import { useNavigate } from "react-router-dom";
import { Typography } from "@mui/material";

import { HubCardStack, HubTableTemplate } from "../../components";
import { StyledSubStack } from "./styles";

const items = [
  {
    id: "employee",
    label: "Employee",
  },
  {
    id: "completed",
    label: "Completed",
  },
  {
    id: "scrapped",
    label: "Scrapped",
  },
  {
    id: "startDate",
    label: "Start Date",
  },
  {
    id: "endDate",
    label: "End Date",
  },
  {
    id: "notes",
    label: "Notes",
  },
];

const keys = [
  {
    value: "employee.name",
  },
  {
    value: "completed",
    defaultValue: 0,
  },
  {
    value: "scrapped",
    defaultValue: 0,
  },
  {
    value: "startDate",
    isDate: true,
  },
  {
    value: "endDate",
    isDate: true,
  },
  {
    value: "notes",
  },
];

const WorkOrderRoutingLabor = ({ item }) => {
//   const navigate = useNavigate();

  const headerProps = { items };
  const bodyProps = { items: item?.labor, keys };
  const props = { headerProps, bodyProps };

  return (
    <StyledSubStack>
      <HubCardStack>
        <Typography variant="boldCaption">Work Order Routing Labor</Typography>
        <HubTableTemplate id="WorkOrderRoutingLabor" {...props} />
      </HubCardStack>
    </StyledSubStack>
  );
};

export default WorkOrderRoutingLabor;

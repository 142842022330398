import { styled } from "@mui/material/styles";
import { Stack } from "@mui/material";
import { HubTableTemplate } from "../../components";

export const StyledSubStack = styled(Stack)(({ theme }) => ({
  padding: "10px",
  backgroundColor: theme.palette.hubColors.greyLightest,
//   margin: "-1px",
}));

export const StyledStack = styled(Stack)(({ theme }) => ({
  justifyContent: "center",
  alignItems: "start",
//   padding: "5px",
  gap: "15px",
  borderRadius: "0px",
  border: "1px solid",
  borderColor: theme.palette.hubColors.greyLight,
  backgroundColor: theme.palette.hubColors.white,
}));

export const StyledHubTableTemplate = styled(HubTableTemplate)({
  borderLeft: 0,
  borderRight: 0,
  "& .MuiTable-root": {
    "& .MuiTableCell-root:last-child": {
      borderRight: 0,
    },
    "& .MuiTableRow-root:last-child": {
      borderBottom: 0,
    },
  },
});

import React, { useEffect, useMemo, useState } from "react";
import { Typography, Select, MenuItem, Stack, Chip } from "@mui/material";
import { StyledStack } from "./styles";
import { HubButton } from "../inputs";
import { useLocation, useNavigate } from "react-router-dom";

export const HubPagination = ({
  count,
  limit = 100,
  offset = 0,
  pages,
  prefix = "",
  onChange = () => {},
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [changedOffset, setChangedOffset] = useState(false);

  const query = new URLSearchParams(location.search);
  const currentPage = useMemo(() => offset / limit + 1, [offset, limit]);
  const limitLabel = `${prefix}limit`;
  const offsetLabel = `${prefix}offset`;

  const handleLimitChange = (event) => {
    query.set(limitLabel, event.target.value);

    navigate({
      pathname: location.pathname,
      search: query.toString(),
    });

    onChange();
  };

  const handlePageChange = (page) => {
    query.set(offsetLabel, Math.max(currentPage + page - 1, 0) * limit);
    changedOffset && setChangedOffset(true);

    navigate({
      pathname: location.pathname,
      search: query.toString(),
    });

    onChange();
  };

  useEffect(() => {
    const oldOffset = currentPage * limit;
    if (oldOffset > count && changedOffset) {
      handlePageChange(pages - currentPage);
    }
  }, [limit, pages]);

  const Items = [10, 25, 50, 75, 100];

  return (
    <StyledStack>
      <Stack flexDirection="row" alignItems="center" gap="10px">
        <Typography>Showing</Typography>
        <Select value={limit} onChange={handleLimitChange}>
          {Items.map((i, idx) => (
            <MenuItem value={i} key={idx}>
              {i}
            </MenuItem>
          ))}
        </Select>
        <Typography>of {count}</Typography>
      </Stack>

      <Stack flexDirection="row" alignItems="center" gap="10px">
        <HubButton
          icon="ChevronLeft"
          disabled={currentPage === 1}
          onClick={() => handlePageChange(-1)}
        />
        <Typography>Page</Typography>
        <Chip variant="colorTag" label={currentPage} />
        <Typography>of {pages}</Typography>
        <HubButton
          icon="ChevronRight"
          disabled={currentPage === pages}
          onClick={() => handlePageChange(1)}
        />
      </Stack>
    </StyledStack>
  );
};

export default HubPagination;

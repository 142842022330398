import React, { useState } from "react";
import { Stack } from "@mui/material";

import { HubHookFormInput, HubButton, InputLabel } from "../";
import { StyledHubButton } from "./styles";

export const HubHookFormNumberInput = ({
  label = "",
  required = false,
  defaultValue = 0,
  ...rest
}) => {
  const [value, setValue] = useState(defaultValue);

  const handleClick = (add) => {
    const newValue = value + add;
    setValue(newValue);
  };

  const onChange = (e, newValue) => setValue(parseInt(newValue));

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      gap="10px"
      alignItems="center"
      width="100%"
    >
      <InputLabel label={label} required={required} disableMargin />
      <Stack
        direction="row"
        gap="5px"
        justifyContent="center"
        alignItems="center"
      >
        <StyledHubButton
          label="-"
          variant="outlined"
          onClick={() => handleClick(-1)}
        />
        <HubHookFormInput
          {...rest}
          required={required}
          defaultValue={value}
          onChange={onChange}
          isNumber
        />
        <StyledHubButton
          label="+"
          variant="contained"
          onClick={() => handleClick(1)}
        />
      </Stack>
    </Stack>
  );
};

export default HubHookFormNumberInput;

import React, { useEffect } from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

import FormHelperText from "@mui/material/FormHelperText";
import { DatePicker, DateTimePicker, TimePicker } from "@mui/x-date-pickers";
import { useController, useFormContext } from "react-hook-form";

import { InputLabel } from "../InputLabel";
import { StyledFormControl } from "./styles";

dayjs.extend(utc);

export const HubHookFormDatePicker = ({
  name = "",
  defaultValue = null,
  required = false,
  rules = {},
  disabled = false,
  label = "",
  disableHelperText = true,
  helperText = "",
  monthFirst = true,
  smallLabel = true,
  hidden = false,
  includeTime = false,
  includeDate = true,
  placeholder = "MM/DD/YYYY",
  formProps = {},
  onChange = () => {},
  slots = {},
  slotProps = {},
  ...rest
}) => {
  const id = `hub-hook-date-picker-${name}`;
  const { control, setValue } = useFormContext();

  const finalFormat = `${includeDate ? "L" : ""}${
    includeDate && includeTime ? " " : ""
  }${includeTime ? "LT" : ""}`;

  const {
    field: { onChange: onControllerChange, name: inputName, value, ref },
    fieldState: { error },
  } = useController({
    name,
    control,
    rules: { required, ...rules },
    defaultValue,
    shouldUnregister: true,
  });

  useEffect(() => {
    if (defaultValue) {
      setValue(name, defaultValue);
    }
  }, [defaultValue, setValue, name, includeTime]);

  const errorMessage = error?.message;
  const helperTextMessage = disableHelperText
    ? null
    : errorMessage || helperText || <span>&nbsp;</span>;

  const handleChange = (value) => {
    const final = value?.format(finalFormat)?.toString();
    onControllerChange(value);
    onChange(final);
  };

  const inputValue = typeof value === "string" ? dayjs(value).utc() : null;

  const finalProps = {
    value: inputValue,
    name: inputName,
    inputRef: ref,
    onChange: handleChange,
    format: `${includeDate ? "L" : ""}${includeDate && includeTime ? " " : ""}${
      includeTime ? "LT" : ""
    }`,
    slots: { ...slots },
    slotProps: {
      textField: { placeholder, error, ...slotProps?.textField },
      ...slotProps,
    },
    disabled,
  };

  return (
    <StyledFormControl hidden={hidden} fullWidth {...formProps}>
      <InputLabel
        label={label}
        required={required}
        id={id}
        smallLabel={smallLabel}
      />
      {(includeTime && includeDate) || (!includeTime && !includeDate) ? (
        <DateTimePicker {...finalProps} {...rest} />
      ) : includeTime ? (
        <TimePicker {...finalProps} {...rest} />
      ) : (
        <DatePicker {...finalProps} {...rest} />
      )}
      {helperTextMessage && (
        <FormHelperText error={!!error}>{helperTextMessage}</FormHelperText>
      )}
    </StyledFormControl>
  );
};

HubHookFormDatePicker.propTypes = {
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.any,
  required: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  rules: PropTypes.object,
  disabled: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  disableHelperText: PropTypes.bool,
  helperText: PropTypes.string,
  monthFirst: PropTypes.bool,
  smallLabel: PropTypes.bool,
  hidden: PropTypes.bool,
  includeTime: PropTypes.bool, // propTypes for the new prop
};

export default HubHookFormDatePicker;

import React from "react";
import { Stack } from "@mui/material";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import DipsTableRow from "./DipsTableRow";

const DipsTableBody = ({ fields = [], remove = () => {}, move = () => {} }) => {
  const onDragEnd = (result) => {
    if (!result.destination) return;
    const start = result.source.index;
    const end = result.destination.index;
    move(start, end);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="dip-dips">
        {(provided) => (
          <Stack {...provided.droppableProps} ref={provided.innerRef}>
            {fields?.map((dip, idx) => {
              const key = dip.id;
              const props = { idx, dip, remove };
              return (
                <Draggable key={key} draggableId={key} index={idx}>
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <DipsTableRow {...props} />
                    </div>
                  )}
                </Draggable>
              );
            })}
            {provided.placeholder}
          </Stack>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default DipsTableBody;

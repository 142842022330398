import { TableBody } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";

import { JOB_STATUSES } from "../../constants";
import { addItemHelper } from "./helper";
import OrderLineItemRowV2 from "./OrderLineItemRowV2";
import OrderLineItemNewRowV2 from "./OrderLineItemNewRowV2";

export const OrderLineItemsBodyV2 = ({
  items = [],
  parts = [],
  setLine = () => {},
}) => {
  const [newRows, setNewRows] = useState([]);

  const { setValue, register, watch } = useFormContext();

  useEffect(() => {
    register("deletedLineItems");
    setValue("deteledLineItems", []);
  }, [register]);

  useEffect(() => {
    if (!newRows.length) {
      const rows = addItemHelper([]);
      setNewRows(rows);
    }
  }, [newRows]);

  const addNewRow = () => {
    const rows = addItemHelper(newRows);
    setNewRows(rows);
  };

  const removeNewRow = (id) => {
    // const updatedItems = newRows.filter(({ idx }) => id !== idx);
    // setNewRows(updatedItems);
    // console.log({ formRows });
  };

  const newPayload = { addNewRow, removeNewRow, parts };

  const deletedValues = watch("deletedLineItems") || [];
  const updatedItems = watch("updatedLineItems") || [];
  const itemPayload = { parts, deletedValues, updatedItems, setLine };

  return (
    <TableBody>
      {items?.map((item, idx) => {
        const disabled = item?.state != JOB_STATUSES.NOT_STARTED && item?.state;
        const complete = item?.state === JOB_STATUSES.COMPLETE;
        const payload = { item, idx, disabled, complete, ...itemPayload };
        return <OrderLineItemRowV2 key={JSON.stringify(item)} {...payload} />;
      })}
      {newRows.map((item) => {
        const payload = { item, idx: item.idx, ...newPayload };
        return (
          <OrderLineItemNewRowV2 key={JSON.stringify(item)} {...payload} />
        );
      })}
    </TableBody>
  );
};

export default OrderLineItemsBodyV2;

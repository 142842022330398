import { styled } from "@mui/material/styles";
import FormControlLabel from "@mui/material/FormControlLabel";

export const StyledFormControlLabel = styled(FormControlLabel)({
  justifyContent: "start",
  margin: 0,
  gap: "7px",
  "& .MuiCheckbox-root": {
    padding: 0,
  },
  "& .MuiFormControlLabel-label": {
    fontSize: 14,
    lineHeight: 1,
  },
});

import React from "react";
import { Stack, Typography } from "@mui/material";
import { useFormContext } from "react-hook-form";

import {
  HubHookFormInput,
  HubHookFormSelect,
  HubHookFormTimePicker,
} from "../../../components";

import { StyledHubCardStack } from "../styles";

const gap = "10px";
const columnGap = "10px";
const injections = [{ id: 0, label: "Top Injection" }];

export const PartWaxDetails = () => {
  const { getValues } = useFormContext();
  const details = getValues("wax");

  return (
    <StyledHubCardStack>
      <Stack gap={gap}>
        <Typography variant="boldCaptionMain">Wax Details</Typography>
        <Stack gap="25px">
          <Stack direction="row" gap={columnGap}>
            <HubHookFormSelect
              name="wax.injectType"
              label="Injection Type"
              defaultValue={details?.injectType}
              options={injections}
            />
            <HubHookFormInput
              name="wax.moldNumber"
              label="Mold #"
              defaultValue={details?.moldNumber}
              placeholder="Ex. 12"
            />
            <HubHookFormInput
              name="wax.heatCode"
              label="Heat Code"
              defaultValue={details?.heatCode}
              placeholder="Ex. 12"
            />
          </Stack>
          <Stack direction="row" gap={columnGap}>
            <HubHookFormTimePicker
              name="wax.leadTime"
              label="Lead Time"
              defaultValue={details?.leadTime}
              placeholder="Ex. 12"
              onlyDays
              required
            />
            <HubHookFormTimePicker
              name="wax.cycleTime"
              label="Cycle time"
              defaultValue={details?.cycleTime}
              placeholder="Ex. 12"
              required
            />
            {/* <HubHookFormInput
              name="wax.waxPresses"
              label="Wax Presses"
              placeholder="Ex. 12"
              isNumber
            /> */}
          </Stack>
          <HubHookFormInput
            name="wax.notes"
            label="Special Instructions"
            defaultValue={details?.notes}
            placeholder="Wax notes..."
            rows={5}
            multiline
          />
          <HubHookFormInput name="wax.id" defaultValue={details?.id} hidden />
        </Stack>
      </Stack>
      <Stack gap={gap}>
        <Typography variant="boldCaptionMain">Wax Setup</Typography>
        <Stack gap="25px">
          <Stack direction="row" gap={columnGap}>
            <HubHookFormInput
              name="wax.cupSize"
              label="Cup"
              defaultValue={details?.cupSize}
              placeholder="Ex. 12"
              endAdornment="oz"
              isNumber
            />
            <HubHookFormTimePicker
              name="wax.setupTimePerTree"
              label="Setup time"
              defaultValue={details?.setupTimePerTree}
              placeholder="Ex. 12"
            />
          </Stack>
        </Stack>
      </Stack>
    </StyledHubCardStack>
  );
};

export default PartWaxDetails;

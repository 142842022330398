import React from "react";
import { Stack, Typography } from "@mui/material";
import { useFieldArray, useFormContext } from "react-hook-form";

import { HubButton, HubHookFormInput } from "../../../components";
import { DipsTable } from "../../../tables";

import { StyledHubCardStack } from "../styles";

const gap = "10px";
const columnGap = "10px";

export const PartDipDetails = () => {
  const { getValues } = useFormContext();
  const details = getValues("dip");

  const dipFieldOptions = useFieldArray({
    name: "dip.dips",
  });

  const { append } = dipFieldOptions;

  const handleAdd = () => {
    append({
      prewet: false,
      leadTime: 1440,
      cycleTime: 0,
      materialId: null,
      stationId: null,
    });
  };

  return (
    <StyledHubCardStack>
      <Stack gap={gap}>
        <Typography variant="boldCaptionMain">Dip Recipe</Typography>
        <DipsTable {...dipFieldOptions} />
        <HubButton
          label="Add Dip"
          variant="text"
          sx={{ fontSize: 10, ml: "auto", mb: -5 }}
          onClick={handleAdd}
        />
      </Stack>
      <Stack gap={gap}>
        <Typography variant="boldCaptionMain">Dewax</Typography>
        <Stack direction="row" gap={columnGap}>
          <HubHookFormInput
            name="dip.treesPerLoad"
            label="Trees / Load"
            defaultValue={details.treesPerLoad}
            placeholder="Ex. 12"
            isNumber
          />
          <HubHookFormInput
            name="dip.dewaxedShellWeight"
            label="Dewaxed Shell Weight"
            defaultValue={details.dewaxedShellWeight}
            placeholder="Ex. 12"
            isNumber
          />
        </Stack>
      </Stack>
      <Stack gap={gap}>
        <Typography variant="boldCaptionMain">Dip Notes</Typography>
        <HubHookFormInput
          name="dip.notes"
          label="Special Instructions"
          defaultValue={details.notes}
          placeholder="Dip notes..."
          rows={5}
          multiline
        />
        <HubHookFormInput name="dip.id" defaultValue={details?.id} hidden />
      </Stack>
    </StyledHubCardStack>
  );
};

export default PartDipDetails;

import React, { useState } from "react";
import { connect } from "react-redux";

import { getUserLocation, getUserRole } from "../../redux";
import { WaxTable } from "../../tables";
import DisplaysMenu from "./DisplaysMenu";

import { StyledContentStack, StyledDisplayStack } from "./styles";
import { ROLES } from "../../constants";
import { PageContent } from "../../components";
import DisplayBar from "./DisplayBar";

const mapStateToProps = (state) => ({
  role: getUserRole(state),
  defaultLocation: getUserLocation(state),
});

export const DisplaysPage = connect(mapStateToProps)(({ role, defaultLocation }) => {
  const defaultNav = ROLES.Generic !== role;
  const [nav, setNav] = useState(defaultNav);
  const props = { nav, setNav, role, defaultLocation };

  console.log({ defaultLocation });

  return (
    <PageContent disablePadding>
      {defaultNav && <DisplayBar {...props} />}
      <StyledContentStack barHidden={!defaultNav}>
        <DisplaysMenu {...props} />
        <StyledDisplayStack barHidden={!defaultNav}>
          <WaxTable {...props} />
        </StyledDisplayStack>
      </StyledContentStack>
    </PageContent>
  );
});

export default DisplaysPage;

import React from "react";
import { Stack, Typography } from "@mui/material";

import WorkOrderLabel from "./WorkOrderLabel";
import { StyledBaseBox, StyledWaxStack } from "./styles";
import { HubIcon } from "../../components";

const border = "1px solid black";

const sx = {
  variant: "caption",
  boxSizing: "borderBox",
  height: "18px",
  px: "5px",
  borderTop: border,
  borderLeft: border,
  fontWeight: 500,
};

const gridSx = {
  display: "grid",
  gridTemplateColumns: "auto 1fr 1fr auto",
  mt: "auto",
  borderRight: border,
  borderBottom: border,
};

const DipTable = ({ dips = [], base = 1, ...rest }) => {
  let length = dips?.length > 5 ? 5 : 5 - dips?.length;
  return (
    <Stack {...gridSx} {...rest}>
      <Typography {...sx}>#</Typography>
      <Typography {...sx}>Dip</Typography>
      <Typography {...sx}>Station</Typography>
      <Typography {...sx}>Prewet</Typography>
      {dips?.map(({ dip, station, prewet = true }, i) => (
        <>
          <Typography {...sx}>{i + base}</Typography>
          <Typography {...sx}>{dip}</Typography>
          <Typography {...sx}>{station}</Typography>
          <Typography {...sx}>{prewet ? "✔" : ""}</Typography>
        </>
      ))}
      {Array.from({ length: length * 4 }, (_, index) => index).map(() => (
        <Typography {...sx} />
      ))}
    </Stack>
  );
};

const noteSx = { valueBold: true, c1: "auto" };
const WorkOrderDip = ({ data }) => {
  let dip = data?.part?.dipInstructions;
  let dips = dip?.dips?.map((d) => ({
    dip: d?.material?.name,
    station: d?.station?.label,
    prewet: d?.prewet,
  }));
  let dewax = data?.part?.autoclaveInstructions;

  return (
    <StyledBaseBox columns={2} rowGap="5px !important">
      <Typography variant="boldCaptionMain">Shell Department</Typography>
      <WorkOrderLabel label="# OF DIPS" value={dip?.dips?.length} c1="auto" />
      <WorkOrderLabel label="DIP INST." value={dip?.notes} {...noteSx} />
      <WorkOrderLabel label="DEWAX INST." value={dewax?.notes} {...noteSx} />

      {dips && (
        <>
          <DipTable dips={dips.slice(0, 5)} />
          <DipTable dips={dips.slice(5, 10)} base={6} />
        </>
      )}
    </StyledBaseBox>
  );
};

export default WorkOrderDip;

import dayjs from "dayjs";
import weekday from "dayjs/plugin/weekday";

dayjs.extend(weekday);

const periods = ["day", "week", "month", "year"];

export const generateItems = (data, totalDays = 0) => {
  let iterations = data.batches;
  let startDate = dayjs(data.repeatStartDate, "MM/DD/YYYY").utc();
  let projectedDue = onUpdateDateHelper(
    startDate.format("MM/DD/YYYY"),
    totalDays
  );

  const getRepeatWeekdays = (currentStartDate) => {
    let currentDate = dayjs(currentStartDate).utc();
    for (const day of data.repeatWeekdays) {
      if (iterations <= 0) break;

      const newDate = currentDate.weekday(day).format("MM/DD/YYYY");
      const { dueDate } = onUpdateDateHelper(newDate, true, totalDays);

      projectedDue = dueDate;
      iterations -= 1;
    }
  };

  while (iterations > 0) {
    if (data.repeatOption === 4) {
      getRepeatWeekdays(startDate);
      startDate = startDate
        .startOf("week")
        .add(data.repeatEveryIteration, periods[data.repeatEvery]);
    } else {
      if (iterations)
        startDate = startDate.add(1, periods[data.repeatOption - 1]);

      const newDate = startDate.format("MM/DD/YYYY");
      const { dueDate } = onUpdateDateHelper(newDate, true, totalDays);

      projectedDue = dueDate;
      iterations -= 1;
    }
  }

  return projectedDue;
};

const onUpdateDateHelper = (value, isStart = false, daysToAdd = 0) => {
  let currentDate = new Date(value);

  const startAdd = isStart ? 1 : -1;
  let inDays = daysToAdd * startAdd;
  let it = Math.abs(inDays);

  while (it >= 0) {
    let date = new Date(currentDate.setDate(currentDate.getDate() + startAdd));
    while (date.getDay() % 6 === 0) {
      currentDate.setDate(currentDate.getDate() + startAdd);
      date = new Date(currentDate);
    }

    currentDate = new Date(date);
    it--;
  }

  const second = dayjs(currentDate).utc().format("MM/DD/YYYY");

  const data = {
    startDate: isStart ? value : second,
    dueDate: isStart ? second : value,
  };

  return data;
};

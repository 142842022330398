import React from "react";
import { Box, Divider, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { StyledMenu } from "./styles";
import { HubIcon } from "../../HubIcon";
import HubContextMenuItem from "../../HubContextMenu/HubContextMenuItem";
import SearchResultsEmpty from "./SearchResultsEmpty";

const TitleResult = ({ icon, title }) => (
  <Stack direction="row" gap="10px" p="12px" alignItems="center">
    {icon && <HubIcon icon={icon} size={12} />}
    <Typography
      variant="boldCaption"
      sx={{
        fontWeight: 500,
        textTransform: "none",
      }}
    >
      {title}
    </Typography>
  </Stack>
);

const SearchResults = ({ results = [], onClose = () => {} }) => {
  const navigate = useNavigate();
  const onClick = (e, item) => {
    e.preventDefault();
    e.stopPropagation();
    if (item?.path) {
      navigate(`${item?.path}/${item?.id}`);
      onClose();
    }
  };

  const hasResults = !!results?.length;
  return (
    <StyledMenu>
      {hasResults ? (
        results?.map((r) => (
          <>
            <TitleResult {...r} />
            {r?.results?.map((item) => (
              <HubContextMenuItem
                item={{ ...item, icon: "ArrowRight" }}
                onSelect={onClick}
              />
            ))}
            <Divider orientation="horizontal" flexItem />
          </>
        ))
      ) : (
        <SearchResultsEmpty />
      )}
    </StyledMenu>
  );
};

export default SearchResults;

import React from "react";
import { Typography } from "@mui/material";

import { useGetOrderLineWorkOrders } from "../../hooks";
import { StyledHubTableTemplate, StyledStack, StyledSubStack } from "./styles";

const items = [
  {
    id: "wo",
    label: "WO #",
  },
  {
    id: "lot",
    label: "Lot #"
  },
  {
    id: "qty",
    label: "Quantity",
  },
  {
    id: "status",
    label: "Status",
  },
  {
    id: "startDate",
    label: "Start Date",
  },
  {
    id: "endDate",
    label: "Due Date",
  },
];

const keys = [
    {
      variant: "blueBold",
      value: "woNumber",
      navigate: true,
      route: "works",
      id: "woNumber",
    },
    {
      value: "lotNumber",
    },
    {
      value: "quantity",
    },
    {
      value: "statusLabel",
    },
    {
      value: "startDate",
      isDate: true,
    },
    {
      value: "endDate",
      isDate: true,
    },
  ];

export const LineItemsTable = ({ open, item }) => {
  if (!item.id) return <></>;
  const { data } = useGetOrderLineWorkOrders(item.id, open);

  const headerProps = { items };
  const bodyProps = { items: data?.rows, keys };
  const props = { headerProps, bodyProps, meta: data?.meta };

  return (
    <StyledSubStack>
      <StyledStack>
        <Typography variant="boldCaption" margin="5px">
          Work Orders
        </Typography>
        <StyledHubTableTemplate id="LineItemsTable" {...props} />
      </StyledStack>
    </StyledSubStack>
  );
};

export default LineItemsTable;

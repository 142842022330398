import React, { useEffect, useMemo } from "react";
import { useController, useFormContext } from "react-hook-form";
import PropTypes from "prop-types";
import InputAdornment from "@mui/material/InputAdornment";
import CircularProgress from "@mui/material/CircularProgress";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import { Typography } from "@mui/material";

import { InputLabel } from "../InputLabel";
import { StyledFormControl, StyledHubIcon } from "./styles";

// for the case where option?.value is false
const getOptionValue = (opt) => {
  return opt?.id !== undefined ? opt?.id : opt;
};

const DefaultIcon = ({ ...rest }) => (
  <StyledHubIcon
    icon="ChevronDown"
    sx={{
      marginRight: "10px",
      marginTop: "3px",
      cursor: "pointer",
    }}
    {...rest}
  />
);

export const HubHookFormSelect = ({
  name = "",
  required = false,
  rules = {},
  disabled = false,
  onChange = () => {},
  isLoading = false,
  placeholder = "",
  label = "",
  helperText = "",
  options = [],
  disableHelperText = true,
  smallLabel = true,
  RenderComponent = null,
  selectDefault = false,
  infoProps = {},
  defaultValue = "",
  formControlProps = {},
  useDefault = true,
  hidden = false,
  fullWidth = true,
  allowNull = false,
  ...rest
}) => {
  const id = `vp-hook-select-${name}`;
  const defaultSelectValue = useMemo(
    () =>
      defaultValue !== "" && defaultValue !== undefined
        ? defaultValue
        : typeof options[0] === "string"
        ? options[0]
        : options[0]?.id,
    [options, defaultValue]
  );

  const vpInputProps = {
    ...(isLoading
      ? {
          startAdornment: (
            <InputAdornment position="start">
              <CircularProgress size={24} />
            </InputAdornment>
          ),
        }
      : {}),
  };

  // prevents issues where defaultSelectValue is null
  const fallbackDefaultValue = selectDefault
    ? getOptionValue(options?.[0]) || ""
    : "";
  const defaultInputValue = !useDefault
    ? ""
    : defaultSelectValue !== undefined
    ? defaultSelectValue
    : fallbackDefaultValue;

  const { setValue, register } = useFormContext();
  const {
    field: {
      onChange: onControllerChange,
      onBlur,
      value,
      name: inputName,
      ref,
    },
    fieldState: { error },
  } = useController({
    name,
    rules: { required, ...rules },
    defaultValue: useDefault ? defaultInputValue : undefined,
    shouldUnregister: true,
  });

  useEffect(() => {
    setValue(inputName, defaultInputValue); // eslint-disable-next-line
  }, [defaultInputValue, useDefault]);

  // helper text spacing will always be there so we don't "push down" elements
  const errorMessage = error?.message;
  const defaultHelperText = disableHelperText ? "" : <span>&nbsp;</span>;
  const helperTextMessage = errorMessage || helperText || defaultHelperText;

  const onSelectChange = (e) => {
    onControllerChange(e);
    onChange(e);
  };

  return (
    <StyledFormControl
      hidden={hidden}
      fullWidth={fullWidth}
      key={options.join("-")}
      variant="outlined"
      {...formControlProps}
    >
      <InputLabel
        label={label}
        required={required}
        id={id}
        smallLabel={smallLabel}
      />
      <Select
        id={id}
        onChange={onSelectChange}
        onBlur={onBlur}
        value={value}
        inputRef={ref}
        name={inputName}
        disabled={disabled}
        size="small"
        error={!!error}
        placeholder={placeholder}
        IconComponent={DefaultIcon}
        MenuProps={{
          sx: {
            "& .MuiMenu-paper": {
              maxHeight: "400px",
            },
          },
        }}
        {...(useDefault ? { defaultValue: defaultSelectValue } : {})}
        {...vpInputProps}
        {...rest}
      >
        {allowNull && (
          <MenuItem key="null-option" value="">
            <Typography sx={{ fontSize: "1em", color: "hubColors.white" }}>
              None
            </Typography>
          </MenuItem>
        )}
        {RenderComponent
          ? options.map((opt) => (
              <MenuItem key={getOptionValue(opt)} value={getOptionValue(opt)}>
                <RenderComponent {...opt} />
              </MenuItem>
            ))
          : options.map((opt) => (
              <MenuItem key={getOptionValue(opt)} value={getOptionValue(opt)}>
                <Typography sx={{ fontSize: "1em" }}>
                  {opt.label || opt}
                </Typography>
              </MenuItem>
            ))}
      </Select>
      {helperTextMessage && (
        <FormHelperText error={!!error} id={`${id}-helper-text`}>
          {helperTextMessage}
        </FormHelperText>
      )}
      <input
        {...register(name)}
        type="input"
        style={{ display: "none" }}
        {...(useDefault ? { defaultValue: defaultSelectValue } : {})}
      />
    </StyledFormControl>
  );
};

HubHookFormSelect.propTypes = {
  name: PropTypes.string.isRequired,
  defaultSelectValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
  required: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  rules: PropTypes.object,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  isLoading: PropTypes.bool,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  helperText: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.string])
  ),
  disableHelperText: PropTypes.bool,
  smallLabel: PropTypes.bool,
  RenderComponent: PropTypes.node,
  selectDefault: PropTypes.bool,
  infoProps: PropTypes.object,
};

export default HubHookFormSelect;

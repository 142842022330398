import React from "react";
import { Stack, Typography } from "@mui/material";
import { useParams } from "react-router-dom";

import { HubCardStack } from "../../components";
import { WorkOrderRoutingTable } from "../../tables";
import { useGetWorkOrderData } from "../../hooks";

export const WorkOrderProd = () => {
  const { id } = useParams();
  const { data } = useGetWorkOrderData(id);

  return (
    <Stack
      padding="15px"
      overflow="auto"
      sx={{
        "&::-webkit-scrollbar": { display: "none !important" },
      }}
    >
      <HubCardStack padding="20px" gap="15px">
        <Typography variant="boldCaption">Work Order Routing</Typography>
        <WorkOrderRoutingTable
          routes={data?.routing}
          current={data?.currentRoute}
        />
      </HubCardStack>
    </Stack>
  );
};

export default WorkOrderProd;

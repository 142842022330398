import React from "react";

import { HubContextMenu } from "../../components";
import { usePutUpdateCustomerActive } from "../../hooks";

export const CompaniesContextMenu = ({
  active,
  id,
  setDeletingId = () => {},
  ...rest
}) => {
  const { mutate: putUpdateCustomerActive } = usePutUpdateCustomerActive();

  const MENU_ITEMS = [
    {
      id: "active",
      label: `Set Customer ${active ? "Inactive" : "Active"}`,
      icon: "User",
    },
    {
      id: "delete",
      label: "Delete",
      icon: "Trash2",
      color: "redError",
    },
  ];

  const onSelect = (item) => {
    switch (item?.id) {
      case "active":
        const data = { active: !active };
        const payload = { id, data };
        putUpdateCustomerActive(payload);
        break;
      case "delete":
        setDeletingId(id);
        break;
      default:
        break;
    }
  };

  return (
    <HubContextMenu
      id={"companies"}
      loading={false}
      menuItems={MENU_ITEMS}
      onSelect={onSelect}
      IconButtonProps={{ sx: { padding: 0 } }}
      {...rest}
    />
  );
};

export default CompaniesContextMenu;

import React from "react";
import { Stack } from "@mui/material";

import { useGetAllMaterialData } from "../../hooks";
import { HubTableTemplate } from "../../components";

const sortable = true;
const items = [
  {
    id: "name",
    label: "Name",
    sortable,
  },
  {
    id: "weight",
    label: "Weight",
    sortable,
  },
  {
    id: "price",
    label: "Price",
  },
  {
    id: "category",
    label: "Category",
  },
];

const keys = [
  {
    value: "name",
  },
  {
    value: "weight",
  },
  {
    value: "price",
  },
  {
    value: "category",
    isChip: true,
  },
];

export const MaterialsTable = () => {
  const { data, isLoading } = useGetAllMaterialData();

  const prefix = "material";
  const meta = {
    ...(data?.meta ? data?.meta : {}),
    prefix,
  };

  const headerProps = { items, prefix };
  const bodyProps = { items: data?.rows, keys };
  const props = { headerProps, bodyProps, meta, isLoading };

  return (
    <Stack gap="10px" overflow="hidden">
      <HubTableTemplate id="TreesTable" {...props} />
    </Stack>
  );
};

export default MaterialsTable;

import qs from "qs-lite";
import { useQuery } from "react-query";
import api from "../../utils/api";

const EMPLOYEE = "/employee";
const getAllEmployeeData = ({ queryKey }) => {
  const queryString = qs.stringify(queryKey[1]);
  return api.get(`${EMPLOYEE}?${queryString}`);
};

export const useGetAllEmployeeData = ({ defaultLocation = 1 }) => {
  const query = new URLSearchParams(window.location.search);

  const location = query.get("location") || defaultLocation;
  const status = query.get("status") || "All";

  const orderBy = query.get("orderBy") || "name";
  const sortOrder = query.get("sortOrder") || "asc";
  const limit = query.get("limit") || 100;
  const offset = query.get("offset") || 0;

  const queryProps = {
    limit,
    offset,
    orderBy,
    sortOrder,
    ...(!location || location === "All" ? {} : { location }),
    ...(status === "All" ? {} : { status }),
  };

  return useQuery([EMPLOYEE, queryProps], getAllEmployeeData);
};

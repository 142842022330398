import React from "react";

import { HubContextMenu } from "../../components";
import { useHubSnackBar } from "../../hooks";

const MENU_ITEMS = [
  {
    id: "addWorkOrders",
    label: "Add Work Orders",
    icon: "Plus",
  },
  {
    id: "delete",
    label: "Delete",
    icon: "Trash2",
    color: "redError",
  },
];

export const LineItemContextMenu = ({
  id,
  _removeLineItem = () => {},
  setLine = () => {},
  lineId,
  removeItem,
  disabled,
  ...rest
}) => {
  const { addSnackbarError } = useHubSnackBar();

  const FILTERED = !lineId
    ? MENU_ITEMS.filter((f) => f.id !== "addWorkOrders")
    : MENU_ITEMS;

  const onSelect = (item) => {
    switch (item?.id) {
      case "delete":
        if (disabled) {
          addSnackbarError({
            message: "This line item cannot be deleted as it's in production",
          });
        } else if (removeItem) {
          removeItem(id);
        } else {
          _removeLineItem(id);
        }
        break;
      case "addWorkOrders":
        setLine(lineId || true);
      default:
        break;
    }
  };

  return (
    <HubContextMenu
      id={"contacts"}
      loading={false}
      menuItems={FILTERED}
      onSelect={onSelect}
      IconButtonProps={{ sx: { padding: "5px" } }}
      HubIconProps={{ size: 14 }}
      {...rest}
    />
  );
};

export default LineItemContextMenu;

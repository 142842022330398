import React from "react";
import { Stack } from "@mui/material";

import { DialogButton, HubHookFormWrapper } from "../../components";
import MaterialFilters from "./MaterialFilters";

export const MaterialsBar = ({ disabled = false }) => {
  return (
    <HubHookFormWrapper onSubmit={() => {}} sx={{ width: "100%" }}>
      <Stack justifyContent="space-between" direction="row" width="100%">
        <Stack direction="row" gap="10px">
          <MaterialFilters />
        </Stack>
        <Stack direction="row" gap="10px">
          <DialogButton
            name="create-material"
            label="Create Material"
            variant="contained"
            size="xsmall"
            disabled={disabled}
          />
        </Stack>
      </Stack>
    </HubHookFormWrapper>
  );
};

export default MaterialsBar;

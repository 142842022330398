import React from "react";
import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";
import { connect } from "react-redux";

import { theme } from "./theme";
import { getViewMode } from "./redux";

const IpcThemeProvider = ({ children, mode }) => {
  const modeTheme = theme(mode);
  return (
    <ThemeProvider theme={modeTheme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
};

const mapStateToProps = (state) => ({
  mode: getViewMode(state),
});
export default connect(mapStateToProps)(IpcThemeProvider);

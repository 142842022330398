import React from "react";
import { Stack } from "@mui/material";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useFieldArray, useFormContext } from "react-hook-form";

import PostFoundryTableRow from "./PostFoundryTableRow";

const PostFoundryTableBody = ({ fields, remove, move }) => {
  // const { watch } = useFormContext();
  // const { fields, remove, move } = useFieldArray({
  //   name: "post.routes",
  // });

  // const data = watch("post.routes");

  const onDragEnd = (result) => {
    if (!result.destination) return;
    const start = result.source.index;
    const end = result.destination.index;
    move(start, end);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="post-foundry-routes">
        {(provided) => (
          <Stack {...provided.droppableProps} ref={provided.innerRef}>
            {fields?.map((post, idx) => {
              const key = post.id;
              const props = { idx, post, remove };
              return (
                <Draggable key={key} draggableId={key} index={idx}>
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <PostFoundryTableRow {...props} />
                    </div>
                  )}
                </Draggable>
              );
            })}
            {provided.placeholder}
          </Stack>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default PostFoundryTableBody;

import React from "react";
import { Stack, Typography } from "@mui/material";

import { HubCardStack, HubCircularProgress } from "../../components";
import { useGetAllEmployeeData } from "../../hooks";

// Helper function to chunk the array into subarrays of the specified size
const chunkArray = (array, size) => {
  const chunks = [];
  for (let i = 0; i < array.length; i += size) {
    chunks.push(array.slice(i, i + size));
  }
  return chunks;
};

const EmployeeCard = ({ name, id }) => {
  return (
    <Stack
      sx={{
        "*": { textAlign: "center", lineHeight: 1 },
        height: "calc(1in - 0.5px)",
        border: "0.25px solid",
        borderColor: "hubColors.black",
        justifyContent: "center",
      }}
    >
      <Typography variant="boldCaption">{name}</Typography>
      <Typography variant="barcode" fontSize="40px">
        *{id}*
      </Typography>
      <Typography variant="caption">{id}</Typography>
    </Stack>
  );
};

export const EmployeeSheet = ({ defaultLocation = 1 }) => {
  const { data, isError } = useGetAllEmployeeData({
    defaultLocation,
  });

  if (isError) return <></>;

  return (
    <Stack
      sx={{
        overflowX: "hidden",
        overflowY: "auto",
        "::-webkit-scrollbar": {
          display: "none",
        },
      }}
    >
      <Stack gap="10px" className="print-only" display="none">
        {/* Break data?.rows into chunks of 33 */}
        {chunkArray(data?.rows || [], 33)
          ?.filter((chunk) => chunk?.length)
          .map((chunk, index) => (
            <HubCardStack
              key={index}
              width="8.5in"
              height="100%"
              minHeight="11in"
              borderRadius={0}
              m="auto"
              padding="0px"
              gap="0px"
              display="grid"
              gridTemplateColumns="repeat(3, 1fr)"
              overflow="hidden"
              sx={{
                pageBreakAfter: "always !important", // Ensure a page break after each stack
                pageBreakInside: "avoid !important", // Avoid breaking inside the stack
              }}
            >
              {chunk.map((e, i) => (
                <EmployeeCard
                  key={`${index}-${i}`}
                  name={e.employee.name}
                  id={e.employeeId}
                />
              ))}
            </HubCardStack>
          ))}
      </Stack>
    </Stack>
  );
};

export default EmployeeSheet;

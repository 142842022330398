import React from 'react'
import { PageContent } from '../../components'

export const PartCustomers = () => {
  return (
    <PageContent smallPadding>
        
    </PageContent>
  )
}

export default PartCustomers
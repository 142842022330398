import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { TableContainer, Table } from "@mui/material";

import { useGetCustomerParts, useGetOrderEditData } from "../../hooks";

import OrderTableSkeleton from "./OrderTableSkeleton";
import OrderLineItemsHeaderV2 from "./OrderLineItemsHeaderV2";
import OrderLineItemsBodyV2 from "./OrderLineItemsBodyV2";

export const OrderLineItemsTableV2 = ({ setLine = () => {} }) => {
  const { id } = useParams();
  const { data: order, isLoading: orderLoading } = useGetOrderEditData(id);
  const {
    data: parts,
    refetch,
    isLoading: partLoading,
  } = useGetCustomerParts(order?.customer?.id);

  const isLoading = orderLoading || partLoading;

  useEffect(() => {
    refetch();
  }, [order?.customer?.id]);

  const payload = {
    items: order?.lineItems,
    parts,
    setLine,
  };

  return isLoading ? (
    <OrderTableSkeleton />
  ) : (
    <TableContainer sx={{ backgroundColor: "hubColors.white" }}>
      <Table id="CustomerOrdersTable">
        <OrderLineItemsHeaderV2 />
        <OrderLineItemsBodyV2 {...payload} />
      </Table>
    </TableContainer>
  );
};

export default OrderLineItemsTableV2;

export const handleFullscreen = (divRef) => {
  try {
    if (divRef && divRef.current) {
      if (divRef.current.requestFullscreen) {
        divRef.current.requestFullscreen();
      } else if (divRef.current.mozRequestFullScreen) {
        // Firefox
        divRef.current.mozRequestFullScreen();
      } else if (divRef.current.webkitRequestFullscreen) {
        // Chrome, Safari and Opera
        divRef.current.webkitRequestFullscreen();
      } else if (divRef.current.msRequestFullscreen) {
        // IE/Edge
        divRef.current.msRequestFullscreen();
      }
    }
  } catch (e) {
    console.log("Failed to go full screen");
  }
};

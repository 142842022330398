import React from "react";
import { Box } from "@mui/material";
import { useFormContext } from "react-hook-form";

import { PARTNERS, ROUTING_OPTIONS } from "../../constants";
import {
  HubButton,
  HubHookFormInput,
  HubHookFormSelect,
  HubHookFormSwitch,
  HubHookFormTimePicker,
} from "../../components";
import { StyledStack } from "./styles";

const TableRowSx = {
  justifyItems: "center",
  ".DipRowButton": {
    display: "none",
  },
  "&:hover": {
    ".DipRowButton": {
      display: "inline",
    },
  },
};

const PostFoundryTableRow = ({
  idx = 0,
  post = {},
  remove = () => {},
  ...rest
}) => {
  const { watch } = useFormContext();
  const preset = `post.routes[${idx}]`;

  const isOutside = watch(`${preset}.isOutside`);
  const OPTIONS = ROUTING_OPTIONS?.filter(
    (p) => p.id > 7 && (isOutside ? p.isOutside : !p.isOutside)
  );

  return (
    <StyledStack sx={TableRowSx} {...rest}>
      <HubHookFormSwitch
        name={`${preset}.isOutside`}
        key={`${preset}.isOutside`}
        defaultValue={post?.isOutside}
        isCheckbox
      />
      <HubHookFormSelect
        name={`${preset}.processId`}
        key={`${preset}.processId`}
        defaultValue={post?.processId}
        options={OPTIONS}
        required
      />
      <HubHookFormSelect
        name={`${preset}.partnerId`}
        key={`${preset}.partnerId`}
        defaultValue={post?.partnerId}
        options={PARTNERS}
        required={isOutside}
        hidden={!isOutside}
      />
      {!isOutside && <div />}
      <HubHookFormTimePicker
        name={`${preset}.leadTime`}
        key={`${preset}.leadTime`}
        defaultValue={post?.leadTime}
        placeholder="124"
        onlyDays
        required
      />
      <HubHookFormTimePicker
        name={`${preset}.cycleTime`}
        key={`${preset}.cycleTime`}
        defaultValue={post?.cycleTime}
        placeholder="0.12"
        required
      />
      <HubHookFormInput
        name={`${preset}.notes`}
        key={`${preset}.notes`}
        defaultValue={post?.notes}
      />

      <HubButton
        className="DipRowButton"
        icon="Trash2"
        variant="text"
        iconProps={{ size: 14 }}
        sx={{ color: "hubColors.redMain" }}
        onClick={() => remove(idx)}
      />
    </StyledStack>
  );
};

export default PostFoundryTableRow;

import React from "react";

import { HubActionDialog } from "../HubActionDialog";
import { WORK_STATUS } from "../../constants";

import { StyledSpan } from "./style";

export const ConfirmWorkOrderStation = ({
  isOpen = false,
  isClosing = false,
  onClose = () => {},
  onSubmit = () => {},
  payload = {},
  ...rest
}) => {
  const status = WORK_STATUS?.find(({ id }) => id === payload?.status);

  let description = (
    <StyledSpan>
      You are about to update the <strong>status</strong> of the work order to{" "}
      <strong>{status?.label}</strong>. This will{" "}
      <strong>{status?.id > 1 ? "hide" : "allow"}</strong> the work order{" "}
      {status?.id > 1 ? "from appearing" : "to appear"} in labor displays.
    </StyledSpan>
  );

  if (payload?.changedRoute && !isClosing)
    description = (
      <StyledSpan>
        You are about to update the current work order routing to{" "}
        <strong style={{ textWrap: "nowrap" }}>{payload?.route?.label}</strong>,
        this will complete the routings for any incomplete steps till the new
        routing, <strong>NOT</strong> including the new routing.
      </StyledSpan>
    );

  if (isClosing)
    description = (
      <StyledSpan>
        You are about to <strong>close</strong> the work order, this will mark{" "}
        <strong>ALL</strong> routings as complete and <strong>ALL</strong>{" "}
        available quantity of parts as <strong>completed</strong>.
      </StyledSpan>
    );

  return (
    <HubActionDialog
      onClose={onClose}
      open={isOpen}
      title={isClosing ? "Admin Work Order Close" : "Admin Work Order Update!"}
      description={description}
      handleSubmit={onSubmit}
      submitLabel={isClosing ? "Confirm Close" : "Confirm Update"}
      action="warning"
      {...rest}
    />
  );
};

export default ConfirmWorkOrderStation;

import React from "react";
import { Box, Stack } from "@mui/material";
import { connect } from "react-redux";

import {
  getCustomerDataAddresses,
  getCustomerId,
} from "../../redux/customerData";
import { HubHookFormWrapper, HubToolbar } from "../../components";
import { CustomerDetailsForm } from "../../containers";
import { StyledDrawer } from "./styles";
import { usePutUpdateCustomerData } from "../../hooks";

const ContactDrawer = ({
  open = false,
  onClose = () => {},
  addresses = [],
  id,
}) => {
  const { mutate: putUpdateCustomerData, isLoading } =
    usePutUpdateCustomerData();

  const handleSubmit = (formData) => {
    const formAddresses = addresses.map((add) => ({
      id: add?.id,
      billing: !!add.billing,
      shipping: !!add.shipping,
      name: add.name,
      street: add.street,
      unit: add.unit,
      city: add.city,
      state: add.state,
      country: add.country,
      zip: add.zip,
    }));

    const locations = formData.locations?.map((l) => l.id);
    const payload = {
      id,
      active: formData.active,
      industry: formData.industry,
      locations,
      name: formData.customerName,
      addresses: formAddresses,
    };
    putUpdateCustomerData(payload);
  };

  return (
    <StyledDrawer anchor="left" open={open} onClose={onClose}>
      <Stack overflow="hidden">
        <HubToolbar
          label="Edit Customer"
          subtitle="Update customer contact details"
          variant="transparent"
          onClose={onClose}
        />
        <Box
          sx={{
            height: "100%",
            overflowY: "auto",
            padding: "0 0 25px 0",
            "&::-webkit-scrollbar": { display: "none !important" },
          }}
        >
          <HubHookFormWrapper onSubmit={handleSubmit}>
            <CustomerDetailsForm isLoading={isLoading} />
          </HubHookFormWrapper>
        </Box>
      </Stack>
    </StyledDrawer>
  );
};

const mapStateToProps = (state) => ({
  id: getCustomerId(state),
  addresses: getCustomerDataAddresses(state),
});

export default connect(mapStateToProps)(ContactDrawer);

import React from "react";

import { SideNavBar } from "../../components";
import { tabs } from "./helper";

const ModelsNav = ({ value = 1, setValue = () => {} }) => {
  const props = { tabs, value, setValue };
  return <SideNavBar {...props} />;
};


export default ModelsNav
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  Collapse,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  Box,
} from "@mui/material";

import { getDollarValue } from "../../helpers/currency";
import { getDateData } from "../../helpers/dateHelper";

import { HubTableHeader } from "../../components";
import { StyledStack, StyledSubStack } from "./styles";
import { useGetOrderJobs, useGetTravelerData } from "../../hooks";
import { TravelerHtml } from "../../templates";
import CustomerOrderLineItemsTable from "./CustomerOrderLineItemsTable";

const headCells = [
  {
    id: "part",
    label: "Part",
  },
  {
    id: "wo",
    label: "WO #",
  },
  {
    id: "qty",
    label: "Quantity",
  },
  {
    id: "price",
    label: "Price",
  },
  {
    id: "margin",
    label: "Margin",
  },
  {
    id: "status",
    label: "Status",
  },
  {
    id: "startDate",
    label: "Start Date",
  },
  {
    id: "endDate",
    label: "Due Date",
  },
];

const JobsTable = ({ jobs = [] }) => {
  const navigate = useNavigate();
  const [selectedJobId, setSelectedJobId] = useState(null);
  const { data: traveler, refetch } = useGetTravelerData(selectedJobId);

  const handlePrint = (data) => {
    var pri = document.getElementById("WorkOrderPrint").contentWindow;

    const content = TravelerHtml(data);
    pri.document.open();
    pri.document.write(content);
    pri.document.close();
    pri.focus();
    pri.print();
  };

  const onJobClick = (e, id) => {
    e.preventDefault();
    e.stopPropagation();

    // setSelectedJobId(id);
    navigate(`/works/${id}`);
  };

  const onPartClick = (e, id) => {
    e.preventDefault();
    e.stopPropagation();

    navigate(`/parts/${id}`);
  };

  useEffect(() => {
    selectedJobId && refetch();
  }, [selectedJobId]);

  useEffect(() => {
    setSelectedJobId(null);
    traveler && handlePrint(traveler);
  }, [traveler]);

  return (
    <TableContainer sx={{ height: "min-content" }}>
      <Box sx={{ display: "none" }}>
        <iframe
          id="WorkOrderPrint"
          style={{ height: 0, width: 0, position: "absolute" }}
        />
      </Box>
      <Table>
        <HubTableHeader
          items={headCells}
          includeCheckbox={false}
          includeEnd={false}
          sx={{ backgroundColor: "hubColors.greyLighter" }}
        />
        <TableBody>
          {jobs?.map((job, index) => (
            <TableRow key={index}>
              <TableCell
                variant="blueBold"
                onClick={(e) => onPartClick(e, job.partId)}
              >
                {job.partName}
              </TableCell>
              <TableCell
                variant="blueBold"
                onClick={(e) => onJobClick(e, job.woNumber)}
              >
                {job.woNumber}
              </TableCell>
              <TableCell>{job.quantity}</TableCell>
              <TableCell>{getDollarValue(parseFloat(job.price))}</TableCell>
              <TableCell>
                {Math.round(parseFloat(job.margin) * 10000) / 100} %
              </TableCell>
              <TableCell>
                {job?.department?.label} ({job.status})
              </TableCell>
              <TableCell>
                {job.startDate &&
                  getDateData({
                    startDate: job.startDate,
                    includeYear: true,
                  })}
              </TableCell>
              <TableCell>
                {job.endDate &&
                  getDateData({
                    startDate: job.endDate,
                    includeYear: true,
                  })}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const CustomerOrdersJobs = ({ id = "", isOpen = false }) => {
  const sx = isOpen
    ? { ":hover": { backgroundColor: "transparent !important" } }
    : { border: "none" };

  return (
    <TableRow sx={sx}>
      <TableCell colSpan={9} sx={{ padding: "0px !important" }}>
        <Collapse in={isOpen}>
          <StyledSubStack>
            <StyledStack>
              <Typography variant="boldCaption" margin="5px">
                Line Items
              </Typography>
              {isOpen && <CustomerOrderLineItemsTable id={id} />}
            </StyledStack>
          </StyledSubStack>
        </Collapse>
      </TableCell>
    </TableRow>
  );
};

export default CustomerOrdersJobs;

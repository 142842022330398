import React from "react";
import { connect } from "react-redux";

import { HubContextMenu } from "../../components";
import { setIsOpenDialog } from "../../redux/dialogs";
import {
  getCustomerDataOrders,
  getCustomerDataParts,
} from "../../redux/customerData";
import { useNavigate } from "react-router-dom";

const MENU_ITEMS = [
  {
    id: "edit",
    label: "Edit Purchase Order",
    icon: "Settings",
  },
  {
    id: "delete",
    label: "Delete",
    icon: "Trash2",
    color: "redError",
  },
];

const name = "purchase-order-slide";

const CustomerOrderContextMenu = ({
  id,
  orders = [],
  parts = [],
  setDeletingId = () => {},
  _setIsOpenDialog = () => {},
  ...rest
}) => {
  const navigate = useNavigate();

  const onSelect = (item) => {
    switch (item?.id) {
      case "edit":
        navigate(`/orders/${id}`);
        break;
      case "delete":
        setDeletingId(id);
        break;
      default:
        break;
    }
  };

  return (
    <HubContextMenu
      id={"orders"}
      loading={false}
      menuItems={MENU_ITEMS}
      onSelect={onSelect}
      IconButtonProps={{
        sx: {
          paddingTop: "0px !important",
          paddingBottom: "0px !important",
        },
      }}
      {...rest}
    />
  );
};

const mapStateToProps = (state) => ({
  orders: getCustomerDataOrders(state),
  parts: getCustomerDataParts(state),
});

const mapDispatchToProps = {
  _setIsOpenDialog: setIsOpenDialog,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomerOrderContextMenu);

import React from "react";

import { useGetDisplayDashboard } from "../../hooks";
import { HubTableTemplate } from "../../components";
import { condition } from "./helper";

const sortable = true;
const items = [
  {
    id: "plannedStartDate",
    label: "Start Date",
    sortable,
  },
  {
    id: "part",
    label: "Part",
  },
  {
    id: "workOrder",
    label: "WO #",
    sortable,
  },
  {
    id: "qty",
    label: "Qty",
  },
  {
    id: "remaining",
    label: "Remaining",
  },
  {
    id: "complete",
    label: "Approved",
  },
  {
    id: "station",
    label: "Station",
  },
];

const keys = [
  {
    value: "startDate",
    isDate: true,
  },
  {
    value: "partName",
  },
  {
    variant: "blueBold",
    value: "workOrder",
    onClick: () => {},
  },
  {
    value: "quantity",
  },
  {
    value: "remaining",
    defaultValue: 0,
  },
  {
    value: "completed",
    defaultValue: 0,
  },
  {
    value: "station",
  },
];

export const LaborWorkOrdersTable = ({
  data = {},
  setWorkOrder = () => {},
}) => {
  const onClick = (item) => {
    setWorkOrder(item?.workOrder);
  };

  const modifiedKeys = keys.map((key) =>
    key.value === "workOrder" ? { ...key, onClick } : key
  );

  const headerProps = { items, includeCheckbox: false, includeEnd: false };
  const bodyProps = { items: data?.rows, keys: modifiedKeys, condition };
  const props = { headerProps, bodyProps, meta: data?.meta };

  return <HubTableTemplate id="LaborTable" {...props} />;
};

export default LaborWorkOrdersTable;

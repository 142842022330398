import { styled } from "@mui/material/styles";
import { Stack } from "@mui/material";
import { HubCardStack } from "../../components";

export const StyledPageStack = styled(Stack)(({ theme }) => ({
  flexDirection: "row",
  height: "calc(100vh - 94px)",
  width: "100vw",
  boxSizing: "border-box",
  overflow: "auto",
}));

export const StyledSubStack = styled(Stack)(({ theme }) => ({
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
}));

export const StyledTitleStack = styled(Stack)(({ theme }) => ({
  flexDirection: "row",
  gap: "25px",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "10px",
  borderBottom: "1px solid",
  borderColor: theme.palette.hubColors.greyLight,
  backgroundColor: theme.palette.hubColors.white,
}));

export const StyledHubCardStack = styled(HubCardStack)(({ theme }) => ({
  gap: "15px",
  maxWidth: "400px",
  width: "100%",
  height: "100%",
  borderRadius: 0,
  border: "none",
  borderLeft: "1px solid",
  borderColor: theme.palette.hubColors.greyLight,
  marginLeft: "auto",
}));

import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import { connect } from "react-redux";

import {
  getCustomerDataParts,
  getCustomerOrderCount,
} from "../../redux/customerData";

import { CustomerOrdersTable } from "../../tables";
import { CreatePurchaseOrder, RepeatWorkOrderDialog } from "../../dialogs";
import { DialogButton, HubButton } from "../../components";
import { EmptyOrders } from "../../svgs";

import CustomerOrdersBar from "./CustomerOrdersBar";

const name = "purchase-order-create";

export const CustomerOrdersUnwrapped = ({ count = 0, parts = [] }) => {
  const hasParts = !!parts?.length;
  const createProps = !hasParts
    ? {
        disabled: true,
        hoverTitle: "Customer Has No Parts",
        hoverSubtitle: "Cannot create order for customer without parts",
      }
    : {};

  return (
    <>
      {count ? (
        <Stack gap="25px">
          <CustomerOrdersBar createProps={createProps} name={name} />
          <CustomerOrdersTable />
        </Stack>
      ) : (
        <Stack gap="25px" margin="auto" alignItems="center">
          <Box component="img" src={EmptyOrders} sx={{ height: "150px" }} />
          <Stack gap="10px" alignItems="center" textAlign="center">
            <Typography variant="boldCaption">No Orders Found</Typography>
            <Typography variant="caption" width="250px">
              Click "Create Order" button to get started in adding this
              customer's new order
            </Typography>
            <Stack direction="row" gap="10px">
              <HubButton
                variant="outlined"
                size="small"
                color="neutral"
                label="Learn More"
              />
              <DialogButton
                name={name}
                label="Create Order"
                variant="contained"
                size="small"
                {...createProps}
              />
            </Stack>
          </Stack>
        </Stack>
      )}
      <CreatePurchaseOrder />
      <RepeatWorkOrderDialog />
    </>
  );
};

const mapStateToProps = (state) => ({
  count: getCustomerOrderCount(state),
  parts: getCustomerDataParts(state),
});

export const CustomerOrders = connect(mapStateToProps)(CustomerOrdersUnwrapped);

import React from "react";
import { TableContainer, Table, Typography } from "@mui/material";

import { useGetOrderLineWorkOrders } from "../../hooks";
import { HubPagination } from "../../components";

import OrderTableSkeleton from "./OrderTableSkeleton";
import OrderLineItemsHeader from "./OrderLineItemsHeader";
import OrderLineItemsBody from "./OrderLineItemsBody";
import { StyledChildStack, StyledStack } from "./styles";

export const OrderLineItemsTable = ({ open, item = {} }) => {
  const { data, isLoading } = useGetOrderLineWorkOrders(item.id, open);

  const payload = {
    items: data?.rows,
    part: item?.part,
  };

  return (
    <StyledChildStack>
      <StyledStack>
        <Typography variant="boldCaption" margin="5px">
          Work Orders
        </Typography>
        {isLoading ? (
          <OrderTableSkeleton />
        ) : (
          <>
            <TableContainer sx={{ backgroundColor: "hubColors.white" }}>
              <Table id="CustomerOrdersTable">
                <OrderLineItemsHeader />
                <OrderLineItemsBody {...payload} />
              </Table>
            </TableContainer>
            <HubPagination {...data?.meta} />
          </>
        )}
      </StyledStack>
    </StyledChildStack>
  );
};

export default OrderLineItemsTable;

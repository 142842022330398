import React, { useEffect, useState } from "react";
import { TableContainer, Table } from "@mui/material";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";

import {
  getOrders,
  getOrdersEndDate,
  getOrdersPeriod,
  getOrdersStartDate,
  getUserLocation,
  setJobOrders,
} from "../../redux";
import { useGetAllOrderData } from "../../hooks";

import { CustomerOrdersHeader } from "../CustomerOrdersTable/CustomerOrdersHeader";
import { CustomerOrdersBody } from "../CustomerOrdersTable/CustomerOrdersBody";

import PurchaseOrdersPagination from "./PurchaseOrdersPagination";
import { HubCircularProgress } from "../../components";

const PurchaseOrders = ({
  defaultLocation,
  per,
  start,
  end,
  orders,
  _setJobOrders = () => {},
}) => {
  const { search } = useLocation();
  const [hasFetched, setHasFetched] = useState(false);

  const {
    data,
    isSuccess,
    isLoading = true,
  } = useGetAllOrderData({
    defaultLocation,
    per,
    start,
    end,
  });

  useEffect(() => {
    if (isSuccess) {
      _setJobOrders(data);
      setHasFetched(true);
    }
  }, [data, isSuccess, search?.toString()]);

  return isLoading && !hasFetched ? (
    <HubCircularProgress centerScreen />
  ) : (
    <>
      <TableContainer>
        <Table id="PurchaseOrdersTable">
          <CustomerOrdersHeader includeCustomer sortable includeCheckbox />
          <CustomerOrdersBody orders={orders} includeCustomer />
        </Table>
      </TableContainer>
      <PurchaseOrdersPagination />
    </>
  );
};

const mapStateToProps = (state) => ({
  orders: getOrders(state),
  defaultLocation: getUserLocation(state),
  per: getOrdersPeriod(state),
  start: getOrdersStartDate(state),
  end: getOrdersEndDate(state),
});

const mapDispatchToProps = {
  _setJobOrders: setJobOrders,
};

export const PurchaseOrdersTable = connect(
  mapStateToProps,
  mapDispatchToProps
)(PurchaseOrders);

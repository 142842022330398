export const condition = (item) => {
  if (new Date(item.startDate) < new Date()) {
    return {
      sx: {
        "&.MuiTableRow-root": {
          "& .MuiTableCell-root:first-of-type": {
            backgroundColor: "rgba(239, 55, 55, 0.9)",
            color: "hubColors.fontWhite",
          },
        },
        "&.MuiTableRow-root:hover": {
          "& .MuiTableCell-root:first-of-type": {
            backgroundColor: "rgba(239, 55, 55, 1) !important",
            color: "hubColors.fontWhite",
          },
        },
      },
    };
  }
};

export const DIP_INGREDIENTS = [
  {
    id: 71,
    label: "FS 50/100",
  },
  {
    id: 72,
    label: "FS 30/50",
  },
];

export const DIP_STATIONS = [
  { id: 7, label: "Dipping | Robot Primary" },
  { id: 8, label: "Dipping | Robot Secondary" },
  { id: 9, label: "Dipping | Hand Dipping" },
];

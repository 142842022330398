import React, { useState } from "react";
import { TableRow, TableCell } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { getDateData } from "../../helpers/dateHelper";
import { getDollarValue } from "../../helpers/currency";
import { FOUNDRIES } from "../../constants/industry";

import CustomerOrdersJobs from "./CustomerOrdersJobs";
import CustomerOrderContextMenu from "./CustomerOrderContextMenu";
import { HubIcon } from "../../components";

const CustomerOrderRow = ({
  order,
  includeCustomer = false,
  setDeletingId = () => {},
}) => {
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const foundry = FOUNDRIES?.find((f) => f?.id === order?.location)?.label;

  const onClick = () => setOpen(!open);

  const handleCustomerClick = (e) => {
    e.preventDefault();
    e.stopPropagation();

    navigate(`/customers/${order?.customer?.id}`);
  };

  const handlePOClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    navigate(`/orders/${order?.id}`);
  };

  return (
    <>
      <TableRow key={order.id} onClick={(e) => onClick(e, order.id)}>
        <TableCell variant="small">
          <HubIcon icon={open ? "ChevronDown" : "ChevronRight"} size="12px" />
        </TableCell>
        {includeCustomer && (
          <TableCell variant="blueBold">
            <span onClick={handleCustomerClick}>{order?.customer?.name}</span>
          </TableCell>
        )}
        <TableCell variant="blueBold">
          <span onClick={handlePOClick}>{order.poNumber}</span>
        </TableCell>
        <TableCell variant="blueBold">{order.parts}</TableCell>
        {/* <TableCell variant="blueBold">{order.workOrders}</TableCell> */}
        {/* <TableCell>
          {order.lotNumbers[0]}
          {order.lotNumbers.length > 1
            ? `, +${order.lotNumbers.length - 1}`
            : ""}
        </TableCell> */}
        <TableCell>{order.status}</TableCell>
        <TableCell>{foundry}</TableCell>
        <TableCell>{getDollarValue(order.revenue)}</TableCell>
        <TableCell>
          {getDateData({ startDate: order.createdAt, includeYear: true })}
        </TableCell>
        {/* <TableCell padding="checkbox">
          <CustomerOrderContextMenu
            id={order.id}
            setDeletingId={setDeletingId}
          />
        </TableCell> */}
      </TableRow>
      <CustomerOrdersJobs id={order.id} isOpen={open} />
    </>
  );
};

export default CustomerOrderRow;

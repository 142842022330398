import React from "react";
import { Skeleton, Stack } from "@mui/material";

export const OrderTableSkeleton = () => {
  const skeletonRows = 10;

  const sx = {
    backgroundColor: "rgba(0, 0, 0, 0.05)",
    "&:after": {
      background:
        "linear-gradient(90deg, transparent, rgba(0, 0, 0, 0.15), transparent)",
    },
  };
  return (
    <Stack gap="7px" height="100%">
      <Skeleton variant="rounded" height={35} sx={sx} />
      <Stack gap="5px">
        {Array.from(new Array(skeletonRows)).map((_, rowIndex) => (
          <Skeleton variant="rounded" height={55} sx={sx} />
        ))}
      </Stack>
    </Stack>
  );
};

export default OrderTableSkeleton;

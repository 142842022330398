import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";

export const handleDownloadPdf = async ({ id, name = "downlaod" }) => {
  try {
    if (!id) return;

    const element = document.getElementById(id);

    if (!element) throw new Error(`No element with id ${id} found`);

    console.log(id, " saving as pdf...");

    const canvas = await html2canvas(element, { scale: 2 });
    const imageData = canvas.toDataURL("image/png");
    const pdf = new jsPDF("portrait", "pt", [612, 792]); // US Letter size in points (8.5x11 inches)

    const pdfWidth = 612; // 8.5 inches in points
    const pdfHeight = 792; // 11 inches in points

    const canvasWidth = canvas.width;
    const canvasHeight = canvas.height;
    const scaleFactor = Math.min(
      pdfWidth / canvasWidth,
      pdfHeight / canvasHeight
    );

    const scaledWidth = canvasWidth * scaleFactor;
    const scaledHeight = canvasHeight * scaleFactor;

    const xOffset = (pdfWidth - scaledWidth) / 2;
    const yOffset = (pdfHeight - scaledHeight) / 2;

    pdf.addImage(imageData, "PNG", xOffset, yOffset, scaledWidth, scaledHeight);
    pdf.save(`${name}.pdf`);
  } catch (e) {
    console.log("Failed to download file", e, "\nfor element ", id);
  }
};

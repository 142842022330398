import React from "react";
import { Box, Stack } from "@mui/material";

import { HubHookFormInput, HubHookFormNumberInput } from "../../components";
import { StyledHubHookFormInput } from "./styles";

const LaborFields = ({
  showHeat = false,
  scrapped = 0,
  completed = 0,
  heatCode = "",
}) => {
  return (
    <Stack gap="5px" width="100%">
      <HubHookFormNumberInput
        name="scrapped"
        label="Scrapped"
        defaultValue={scrapped}
        required
      />
      <HubHookFormNumberInput
        name="completed"
        label="Completed"
        defaultValue={completed}
        required
      />
      {showHeat && (
        <Box
          sx={{
            "& .MuiFormControl-root": {
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              "& .MuiTypography-root:first-child": {
                margin: 0,
              },
            },
          }}
        >
          <StyledHubHookFormInput
            name="heatCode"
            label="Heat Code"
            defaultValue={heatCode}
            required
          />
        </Box>
      )}
      {/* <HubHookFormInput name="notes" label="Notes" rows={5} /> */}
    </Stack>
  );
};

export default LaborFields;

import { FOUNDRIES } from "../../constants";

export const formOrderMapper = (fd = {}, customer = {}) => {
  const location =
    fd?.location || FOUNDRIES?.find((f) => customer?.location === f?.label)?.id;

  const payload = {
    companyId: customer?.id,
    location,
    poNumber: fd?.poNumber,
    overShippingAllowed: fd?.overShipping,
    shipVia: fd?.shipVia,
    shipTo: fd?.shipTo,
    freight: fd?.freight,
  };

  return payload;
};

export const orderMapper = ({
  items,
  customer,
  details,
  isUpdating,
  shipTo,
}) => {
  const location =
    details?.location ||
    FOUNDRIES?.find((f) => customer?.location === f?.label)?.id;

  const itemPayload = items?.map((i) => {
    const margin = i?.price
      ? (i?.price * i?.part?.margin) / i?.part?.lastSentPrice
      : i?.part?.margin;

    return {
      lineItem: i?.line,
      partId: i?.part?.id,
      price: i?.price || i?.part?.lastSentPrice,
      margin,
      woNumber:
        i?.overrideWo || `W${(i?.workOrder).toString().padStart(5, "0")}`,
      lotNum: i?.lotNumber,
      qtyReq: i?.quantity,
      trees: i?.trees,
      patReq: i?.patReq,
      startDate: i?.startDate && new Date(i?.startDate).toISOString(),
      dueDate: i?.dueDate && new Date(i?.dueDate).toISOString(),
    };
  });

  const payload = {
    companyId: customer?.id,
    customerPO: details?.poNumber,
    location: location,
    overShippingAllowed: details?.overShipping,
    shipVia: details?.shipVia,
    shipTo,
    freight: details?.freight,
    items: itemPayload,
    ...(isUpdating ? { id: details.id } : {}),
  };

  return payload;
};

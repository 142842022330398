import React from "react";
import { Chip, Stack, Typography } from "@mui/material";

import { FOUNDRY_NAMES } from "../../constants";
import { useGetAllCustomers } from "../../hooks";
import { StyledHubHookFormSelect } from "./styles";

const sxChip = {
  fontSize: "10px !important",
  fontWeight: 400,
  height: 20,
  span: { padding: 0 },
  padding: "5px",
};

const HubHookCustomersSelect = ({ required = true }) => {
  const { data, isLoading } = useGetAllCustomers();

  return (
    <StyledHubHookFormSelect
      name="customerId"
      label="Customer"
      options={data || []}
      required={required}
      isLoading={isLoading}
      useDefault={false}
      RenderComponent={({ label, locations = [] }) => (
        <Stack direction="row" gap="10px" alignItems="center">
          <Typography
            sx={{
              fontSize: "12px !important",
              fontWeight: 500,
            }}
          >
            {label}
          </Typography>
          <Stack direction="row" gap="3px">
            {locations?.map((name) => (
              <Chip sx={sxChip} label={name} />
            ))}
          </Stack>
        </Stack>
      )}
    />
  );
};

export default HubHookCustomersSelect;

import React, { useEffect } from "react";
import { useController, useFormContext } from "react-hook-form";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import FormHelperText from "@mui/material/FormHelperText";
import InputAdornment from "@mui/material/InputAdornment";
import CircularProgress from "@mui/material/CircularProgress";
import OutlinedInput from "@mui/material/OutlinedInput";

import { StyledFormControl, StyledInput } from "./styles";
import { useDebounce } from "../../hooks";
import { EMAIL_REGEX } from "../../utils/validations";
import { InputLabel } from "../InputLabel";
import { getCurrencySymbol } from "../../helpers";

const getInputAdornment = (adornment, position) =>
  adornment ? (
    <InputAdornment position={position}>
      {typeof adornment === "string" ? (
        <Typography variant="inputAdornment">{adornment}</Typography>
      ) : (
        adornment
      )}
    </InputAdornment>
  ) : null;

export const HubHookFormInput = ({
  name = "",
  defaultValue = "",
  required = false,
  rules = {},
  disabled = false,
  maxLength = 300,
  onChange = null,
  onChangeDebounce = null,
  onChangeDebounceTimeout = 0,
  isLoading = false,
  placeholder = "",
  label = "",
  disableHelperText = true,
  helperText = "",
  startAdornment = null,
  endAdornment = null,
  smallLabel = true,
  hidden = false,
  isUppercase = false,
  isStandardInput = false,
  boldValue = false,
  fallbackDefaultValue = "",
  includeDisplayInput = false,
  isEmail = false,
  isNumber = false,
  isNumerical = false,
  isDecimal = false,
  isCurrency = false,
  currency = "USD",
  rows = 1,
  clearOnChange = false,
  onBlur = () => {},
  fullWidth = true,
  ...rest
}) => {
  const id = `hub-hook-input-${name}`;
  const displayInputName = `${name}-display`;

  const currencyAdornment = isCurrency ? getCurrencySymbol(currency) : null;
  const inputStartAdornment = getInputAdornment(
    startAdornment || currencyAdornment,
    "start"
  );

  const inputEndAdornment = getInputAdornment(endAdornment, "end");
  const hubInputProps = {
    ...(isLoading
      ? {
          endAdornment: (
            <InputAdornment position="end">
              <CircularProgress size={24} />
            </InputAdornment>
          ),
        }
      : {}),
  };
  const hubOnChangeDebounce = useDebounce((val) => {
    onChangeDebounce && onChangeDebounce(val);
  }, onChangeDebounceTimeout);

  const localRules = {
    ...(isEmail
      ? {
          pattern: {
            value: EMAIL_REGEX,
            message: "Please enter a valid email address",
          },
        }
      : {}),
    ...(isNumber || isNumerical
      ? {
          pattern: {
            value: /^\d*$/,
            message:
              "Please enter a valid number consisting of only digits (0-9)",
          },
        }
      : {}),
    ...(isCurrency || isDecimal
      ? {
          pattern: {
            value: /^(?:\d+|\d*\.\d+)$/,
            message: "Please enter a valid amount (integer or decimal)",
          },
        }
      : {}),
  };

  const getFormattedValue = (valueToFormat) => {
    if (
      valueToFormat === undefined ||
      valueToFormat === null ||
      typeof valueToFormat !== "string"
    ) {
      return valueToFormat;
    }
    if (isEmail) {
      return valueToFormat?.toLowerCase();
    }
    if (isUppercase) {
      return valueToFormat?.toUpperCase();
    }
    if (isNumerical) {
      return valueToFormat.replace(/[^0-9.]/g, "");
    }
    if (isNumber || isDecimal || isCurrency) {
      const newValue = valueToFormat.replace(/[^0-9.]/g, "");
      return (isNumber ? parseInt : parseFloat)(newValue);
    }

    return valueToFormat;
  };

  const fallbackValue = isNumber || isDecimal || isCurrency ? 0 : "";
  const defaultInputValue = getFormattedValue(defaultValue) || fallbackValue;

  const { setValue, register } = useFormContext();
  const {
    field: { onChange: onControllerChange, value, name: inputName, ref },
    fieldState: { error },
  } = useController({
    name: includeDisplayInput ? displayInputName : name,
    rules: { required, ...localRules, ...rules },
    defaultValue: defaultInputValue,
    shouldUnregister: true,
  });

  useEffect(() => {
    if (includeDisplayInput) {
      setValue(name, value);
    }
  }, [value]);

  useEffect(() => {
    if (includeDisplayInput) {
      setValue(displayInputName, defaultInputValue);
    }
    setValue(name, defaultInputValue);
  }, [defaultInputValue]);

  const errorMessage = error?.message;
  const helperTextMessage = disableHelperText
    ? null
    : errorMessage || helperText || null;

  // Modify this handler to clear the input when `clearOnChange` is true
  const onTextFieldChange = (e) => {
    const newValue = e.target.value;
    onControllerChange(e);
    onChange && onChange(e, newValue);
    hubOnChangeDebounce && hubOnChangeDebounce(newValue);
  };

  const onTextFieldBlur = (e) => {
    const newValue = e.target.value;
    onBlur(e, newValue);
    if (clearOnChange) {
      setValue(inputName, "");
    }
  };
  const inputValue = getFormattedValue(value);

  const InputElement = isStandardInput ? StyledInput : OutlinedInput;
  const inputStyleProps = boldValue ? { boldValue } : {};

  return (
    <StyledFormControl
      hidden={hidden}
      fullWidth={fullWidth}
      variant={isStandardInput ? "standard" : "outlined"}
    >
      <InputLabel
        label={label}
        required={required}
        id={id}
        smallLabel={smallLabel}
      />
      <InputElement
        id={id}
        onChange={onTextFieldChange}
        onBlur={onTextFieldBlur}
        value={inputValue}
        inputRef={ref}
        name={inputName}
        disabled={disabled}
        size="small"
        error={!!error}
        inputProps={{
          maxLength,
          ...(isCurrency || isDecimal ? { type: "number", step: 0.01 } : {}),
          ...(isNumber ? { type: "number" } : {}),
        }}
        placeholder={placeholder}
        startAdornment={inputStartAdornment}
        endAdornment={inputEndAdornment}
        rows={isCurrency || isNumber || isDecimal ? 1 : rows > 5 ? 5 : rows}
        multiline={rows > 1 && (isCurrency || isNumber || isDecimal)}
        {...inputStyleProps}
        {...hubInputProps}
        {...rest}
      />
      {helperTextMessage && (
        <FormHelperText error={!!error} id={`${id}-helper-text`}>
          {helperTextMessage}
        </FormHelperText>
      )}
      {includeDisplayInput && (
        <input {...register(name)} type="hidden" defaultValue={defaultValue} />
      )}
    </StyledFormControl>
  );
};

HubHookFormInput.propTypes = {
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  required: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  rules: PropTypes.object,
  disabled: PropTypes.bool,
  maxLength: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isEmail: PropTypes.bool,
  isNumber: PropTypes.bool,
  onChange: PropTypes.func,
  onChangeDebounce: PropTypes.func,
  onChangeDebounceTimeout: PropTypes.number,
  isLoading: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  placeholder: PropTypes.string,
  disableHelperText: PropTypes.bool,
  helperText: PropTypes.string,
  startAdornment: PropTypes.node,
  endAdornment: PropTypes.node,
  smallLabel: PropTypes.bool,
  hidden: PropTypes.bool,
  isUppercase: PropTypes.bool,
  isStandardInput: PropTypes.bool,
  boldValue: PropTypes.bool,
  fallbackDefaultValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  includeDisplayInput: PropTypes.bool,
};

export default HubHookFormInput;

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

export const condition = ({
  isCurrent = false,
  plannedStartDate = "",
  startDate = "",
  plannedEndDate = "",
  endDate = "",
}) => {
  const startLogic =
    dayjs(plannedStartDate).utc() <
    (startDate ? dayjs(startDate) : dayjs()).utc();

  const endLogic =
    dayjs(plannedEndDate).utc() < (endDate ? dayjs(endDate) : dayjs()).utc();

  const color = "rgba(239, 55, 55,";
  const isActive = (startDate && !endDate) || isCurrent;
  const addRed = (startLogic && !endDate) || (startLogic && endLogic);

  return {
    sx: {
      "&.MuiTableRow-root": {
        ...(isActive ? { backgroundColor: "hubColors.mainFocusLighter2" } : {}),
        ...(addRed
          ? {
              borderColor: `${color} 0.2) !important`,
              "& .MuiTableCell-root": {
                backgroundColor: `${color} ${isActive ? "0.15" : "0.075"})`,
                borderColor: `${color} 0.2) !important`,
              },
            }
          : {}),
      },
    },
  };
};

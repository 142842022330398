import React from "react";
import { connect } from "react-redux";

import { flushPurchaseOrder, getCustomerData } from "../../redux";

import { HubDialogWrapper } from "../HubDialogWrapper";
import CreatePurchaseOrderContent from "./CreatePurchaseOrderContent";

const name = "purchase-order-create";

const CreatePurchase = ({ customer, _flushPurchaseOrder = () => {} }) => {
  const content = { customer, _flushPurchaseOrder };
  return (
    <HubDialogWrapper maxWidth="sm" name={name}>
      <CreatePurchaseOrderContent {...content} />
    </HubDialogWrapper>
  );
};

const mapStateToProps = (state) => ({
  customer: getCustomerData(state),
});

const mapDispatchToProps = {
  _flushPurchaseOrder: flushPurchaseOrder,
};

export const CreatePurchaseOrder = connect(
  mapStateToProps,
  mapDispatchToProps
)(CreatePurchase);

import React, { useState } from "react";
import { Stack } from "@mui/material";

import { PageContent } from "../../components";
import OrganizationNav from "./OrganizationNav";

export const OrganizationSettings = () => {
  const [value, setValue] = useState(1);
  return (
    <PageContent disablePadding>
      <Stack direction="row" height="100%" overflow="hidden">
        <OrganizationNav {...{ value, setValue }} />
      </Stack>
    </PageContent>
  );
};

export default OrganizationSettings;

import React from "react";
import { Stack } from "@mui/material";

import { HubHookFormWrapper } from "../../components";
import ContactsLocationFilter from "../Companies/ContactsLocationFilter";

export const WorkOrdersBar = () => {
  return (
    <HubHookFormWrapper onSubmit={() => {}} sx={{ width: "100%" }}>
      <Stack justifyContent="space-between" direction="row" width="100%">
        <Stack direction="row" gap="10px">
          <ContactsLocationFilter multiSelect={false} />
        </Stack>
        <Stack direction="row" gap="10px"></Stack>
      </Stack>
    </HubHookFormWrapper>
  );
};

export default WorkOrdersBar;
